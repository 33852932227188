import React, { useMemo } from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import {Link, useHistory} from 'react-router-dom';
import {convertTimeToReadable} from "../../../../variables/convertTime";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function SiteTable({data, title, clientId, date}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    let history = useHistory();

    const tableHead = useMemo(() => ["Name", "Total Hours", "Estimated Hours", "Billable Charge", "hours deviated (-/+)", ""], []);
    const tableContainerRef = React.useRef(null);

    tableHead.push("");

    React.useEffect(() => {
        if (data?.length > 0) {
            new List(tableContainerRef.current, {
                valueNames: tableHead.slice(0, tableHead.length - 1),
                listClass: "list"
            });
        }
    }, [tableHead, data]);

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={title ? title : "Site Breakdown"}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                ></CardHeader>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "forename" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{
                                        root:
                                            classes.tableCellRoot +
                                            " " +
                                            classes.tableCellRootBodyHead,
                                    }}
                                               component="th"
                                               variant="head"
                                               scope="row">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box fontSize=".875rem" component="span">
                                                <Link to={`/admin/authorization/${clientId}/${date}/site/${prop.siteId}`}>{prop.siteName}</Link>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{convertTimeToReadable(prop.totalHours)}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{convertTimeToReadable(prop.estimatedHours)}</TableCell>

                                    <TableCell classes={{ root: classes.tableCellRoot }}>£{prop.totalCharge.toFixed(2)}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{convertTimeToReadable(prop.totalHours - prop.estimatedHours)}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }} align="right">
                                        <TableDropdown id={key} actions={[
                                            { text: "View", handle: () => history.push(`/admin/authorization/${clientId}/${date}/site/${prop.siteId}`)}
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
        </>
    );
}
