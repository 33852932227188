import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/components/cards/sortable/card-light-table-sortable";
import componentStylesDialog from "../../assets/theme/components/dialog";
import componentStylesButtons from "../../assets/theme/components/button";
import Slide from "@material-ui/core/Slide";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function SimpleDialog({title, message, successCallback, cancelCallback, show}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    const callback = (success) => {
        setIsOpen(false);
        if (success) successCallback();
        else cancelCallback();
    }

    useEffect(() => {
        setIsOpen(show);
    }, [show])

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => callback()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={classes.dialogHeader}>
                <Typography variant="h5" component="h5" className={classes.dialogTitle}>{title}</Typography>
                <IconButton onClick={() => callback()}>
                    <Clear/>
                </IconButton>
            </div>
            <DialogContent>
                <Typography variant="body2" component="p">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => callback(true)}
                    classes={{root: classes.buttonContainedWarning}}
                    variant="contained"
                >
                    Yes
                </Button>
                <Button
                    component={Box}
                    onClick={() => callback()}
                    color="primary"
                    marginLeft="auto!important"
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}
