import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import OrganisationsTable from "./Components/OrganisationsTable";
import { GET_ALL_ORGANISATION } from "./Scheme"
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

const Organisations = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { loading, data } = useQuery(GET_ALL_ORGANISATION);

    return (
        <>
            <AlternativeHeader section="Organisation" showNew showNewAction={() => history.push("/super-admin/organisations/add")} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{root: classes.containerRoot}}
            >
                {!loading && data.getAllOrganisation.length > 0 && <OrganisationsTable data={data.getAllOrganisation} props={props} deleteCallback={i => console.log(i)}/>}
            </Container>
        </>
    );
};

export default Organisations;
