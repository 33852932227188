import React, { useState } from "react";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import FilledInput from "@material-ui/core/FilledInput";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import componentStylesForms from "../../../../../assets/theme/components/forms";

const useStyles = makeStyles(componentStyles);
const useStylesForms = makeStyles(componentStylesForms);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddJobTask({data, showModal, closeEvent, rooms}) {
    const classes = {
        ...useStyles(),
        ...useStylesForms()
    };

    const [description, setDescription] = useState('');
    const [taskType, setTaskType] = useState('text');
    const [taskPriority, setTaskPriority] = useState('none');
    const [startup, setStartup] = useState(false);
    const [roomId, setRoomId] = useState("");

    const closeEventHandler = () => {
        if (!description) return; // todo show error.

        let dataResult = {
            description,
            type: taskType,
            priority: taskPriority,
            startupTask: startup
        };

        if (roomId && roomId.length > 0) dataResult = { ...dataResult, roomId };

        closeEvent(dataResult);
    };

    React.useEffect(() => {
        if (data) {
            setDescription(data.description);
            setTaskType(data.type);
            setTaskPriority(data.priority);
            setStartup(data.startupTask);
            setRoomId(data.roomId);
        }
    }, [data]);

    return (
        <>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => closeEvent()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Box
                        component="h2"
                        marginBottom="25px"
                        marginTop="0"
                    >
                        Add Task
                    </Box>
                    <FormGroup>
                        <FormLabel>Task</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                defaultValue={taskType}
                                IconComponent={KeyboardArrowDown}
                                onChange={(e) => setTaskType(e.target.value)}>
                                <MenuItem value="check">Checkbox</MenuItem>
                                <MenuItem value="signature">Signature</MenuItem>
                                <MenuItem value="text">Textbox</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Priority</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                defaultValue={taskPriority}
                                IconComponent={KeyboardArrowDown}
                                onChange={(e) => setTaskPriority(e.target.value)}>
                                <MenuItem value="none">None</MenuItem>
                                <MenuItem value="low">Low</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="high">High</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Description</FormLabel>
                        <FilledInput
                            placeholder="e.g., clean cupboards"
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormGroup>

                    {rooms && rooms.length > 0 &&
                        <FormGroup>
                            <FormLabel>Room</FormLabel>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    defaultValue={roomId}
                                    IconComponent={KeyboardArrowDown}
                                    onChange={(e) => setRoomId(e.target.value)}>
                                    <MenuItem key={"none"} value="">None</MenuItem>
                                    {rooms.filter(r => r._id).map(r => <MenuItem key={r._id} value={r._id}>{r.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    }

                    <FormGroup>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                color="primary"
                                onChange={(e) => setStartup(e.target.checked)}
                                checked={startup} />}
                            label="Show on startup"
                            labelPlacement="end"
                            classes={{
                                root: classes.formControlCheckboxLabelRoot,
                                label: classes.formControlCheckboxLabelLabel,
                            }}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" justifyContent="space-between">
                        <Box>
                            <Button color="primary" variant="contained" onClick={closeEventHandler}>
                                Add task
                            </Button>
                        </Box>
                        <Button color="primary" onClick={() => closeEvent()}>
                            Close
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}
