import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Note from "@material-ui/icons/Note";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../../../../assets/theme/components/cards/sortable/card-light-table-sortable";
import componentStylesDialog from "../../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../../assets/theme/components/button";
import Box from "@material-ui/core/Box";
import { useEffect, useState } from "react";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function JobRoomConfiguration({rooms, types, updateCallback}) {
    const [configRooms, setConfigRooms] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);

    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    useEffect(() => {
        if (configRooms.length === 0) setConfigRooms(rooms)
    }, [rooms, configRooms.length]);
    useEffect(() => {
        if (roomTypes.length === 0) setRoomTypes(types)
    }, [types, roomTypes.length])

    const updateRoom = (prop, value, index) => {
        const rs = configRooms.map(x => x);

        rs[index][prop] = value;

        setConfigRooms(rs);
        updateCallback(rs);
    };

    const addEmptyRoom = () => {
        setConfigRooms([...configRooms, {
            type: roomTypes[0],
            unit: "metre"
        }]);
    };

    const renderDefault = (isBlank = true) => <>
        <Button
            variant="contained"
            classes={{
                root: classes.buttonContainedSuccess,
            }}
            fullWidth
            onClick={() => addEmptyRoom()}
            style={{ marginTop: "10px" }}
        >
            {isBlank ? "Start Creating Rooms" : "Add Room"}
        </Button>
        {isBlank && <Box
            component="p"
            marginBottom="0"
            fontWeight="300"
            lineHeight="1.5"
            fontSize="0.8rem"
            marginTop="10px"
            paddingTop={'10px'}
            textAlign={"center"}
        >
            Rooms allow you to provide more detail on jobs, and split tasks up. Once created, they cannot be deleted.
        </Box>}
    </>;

    const renderRooms = (room, index) => <>
        <Accordion classes={{root: classes.accordion}} key={index}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{room.name && room.name.length > 0 ? room.name : "Unnamed Room"}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection: "column"}}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="Name e.g., Front Bathroom"
                                value={room.name}
                                startAdornment={<InputAdornment position="start"><Note/></InputAdornment>}
                                onChange={(e) => {updateRoom("name", e.target.value, index)}}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    defaultValue={room.type}
                                    IconComponent={KeyboardArrowDown}
                                    onChange={(e) => {updateRoom("type", e.target.value, index)}}>
                                    <MenuItem key={"none"} value={null}>None</MenuItem>
                                    {roomTypes.map(r => (<MenuItem key={r} value={r}>{r}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <OutlinedInput
                                fullWidth
                                type="number"
                                placeholder="Total Area e.g., 10"
                                value={room.area}
                                startAdornment={<InputAdornment position="start"><Note/></InputAdornment>}
                                onChange={(e) => {updateRoom("area", Number(e.target.value || 0), index)}}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    defaultValue={'metre'}
                                    IconComponent={KeyboardArrowDown}
                                    onChange={(e) => {updateRoom("unit", e.target.value, index)}}>
                                    <MenuItem key={"metre"} value="metre">Metre</MenuItem>
                                    <MenuItem key={"foot"} value="foot">Foot</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                                <OutlinedInput
                                    fullWidth
                                    type="text"
                                    placeholder="Notes"
                                    multiline
                                    value={room.notes}
                                    startAdornment={<InputAdornment position="start"><Note /></InputAdornment>}
                                    onChange={(e) => {updateRoom("notes", e.target.value, index)}}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;

    return configRooms.length > 0
        ?
        <>
            {configRooms.map((v, i) => renderRooms(v, i))}
            {renderDefault(false)}
        </>
        : renderDefault();
}
