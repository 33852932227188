import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import {useLazyQuery, useQuery} from '@apollo/client';
import {GET_SCHEDULE_CUSTOMERS_WITH_AGG} from "./Scheme";
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ScheduleTable from './Components/ScheduleTable';
import StaffTable from "./Components/StaffTable";
import JobStaffChart from "./Components/JobStaffChart";
import {KpiCard} from "./Components/KpiCard";
import LineTable from "./Components/LineTable";
import CompareTable from "./Components/CompareTable";
import {GET_SIMPLE_JOB_BY_ID} from "../Management/Jobs/Scheme";
import {AdjustDialog} from "./Components/AdjustDialog";

const useStyles = makeStyles(componentStyles);

const AuthorizationSchedule = (props) => {
    const classes = useStyles();

    const id = props.match.params.clientId;
    const scheduleId = props.match.params.scheduleId;
    const date = moment(props.match.params.date);
    const week = props.match.params.date;

    const { loading, data } = useQuery(GET_SCHEDULE_CUSTOMERS_WITH_AGG, { variables: { week: week, clientId: id, scheduleId: scheduleId, startWeek: date.startOf("isoWeek").toDate(), endWeek: date.endOf('isoWeek').toDate() } });
    const [getJobById] = useLazyQuery(GET_SIMPLE_JOB_BY_ID);
    const [charge, setCharge] = useState({});
    const [jobCharge, setJobCharge] = useState({});
    const [staff, setStaff] = useState([]);

    // The State
    const [aggregation, setAggregation] = useState({});
    // @ts-ignore
    // eslint-disable-next-line
    const [schedulesDetail, setSchedulesDetail] = useState({})
    const [schedules, setSchedules] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [adjustmentOpen, setAdjustmentOpen] = useState(false);

    useEffect(() => {
        if (loading) return;

        if (data?.getActiveMasters) setSchedules(data.getActiveMasters);

        if (data?.getAggregationsByClient) {
            const agg = data.getAggregationsByClient.weeks[0].clients[0].groupedSchedules.filter(x => x.scheduleId === scheduleId)[0]; // todo check for null anywhere in here!

            setStaff(data.getAggregationsByClient.weeks[0].clients[0].staff);
            setAggregation(agg);

            const scheduleDetailsAgg = data.getSchedulesDetail;
            setSchedulesDetail(scheduleDetailsAgg[0]);
            if (scheduleDetailsAgg && scheduleDetailsAgg.length > 0)
                setTasks(scheduleDetailsAgg?.map(x => x.taskEvents).flat(1).map(x => ({ ...x, staff: x.staffId})));

            if (scheduleDetailsAgg[0].jobId) {
                getJobById({variables: { id: scheduleDetailsAgg[0].jobId }}).then(job => {
                    setCharge(job?.data?.getAllCharge[0]);
                    setJobCharge(job?.data?.getSimpleJob?.chargeLine);
                });
            }
        }
        // eslint-disable-next-line
    }, [data, loading, scheduleId]);

    return (
        <>
            <AlternativeHeader
                section={`${aggregation.name} Details`}
                customButton="Adjust Figures"
                customButtonAction={() => setAdjustmentOpen(true)}
                showBack />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item sm={12} md={4}>
                        <KpiCard
                            title={"Schedule Hours"}
                            projected={aggregation.estimatedHours}
                            total={schedulesDetail?.staffEvents?.map(x => x.totalTime).reduce((a, b) => a + b, 0) / 60 || 0}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={4}>
                        <KpiCard
                            title={"Tasks"}
                            toFixed={0}
                            projected={aggregation?.estimatedTasks || 0}
                            total={tasks?.filter(x => x.taskName !== "Travel Time")?.length || 0}
                            suffix={"tasks"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={4}>
                        <KpiCard
                            title={"Travel"}
                            toFixed={2}
                            projected={aggregation?.estimatedTravel / 60 || 0}
                            total={(tasks?.filter(x => x.taskName === "Travel Time")?.map(a => Number(a.taskValue)).reduce((a, b) => a + b, 0) || 0) / 60}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    {aggregation && charge && (
                    <Grid item sm={12}>
                        <CompareTable
                            jobData={{
                                travel: aggregation?.estimatedTravel / 60 || 0,
                                hours: jobCharge?.quantity || 0,
                                cost: charge?.cost || 0,
                                charge: charge?.charge || 0,
                                staffCount: staff.length || 0,
                            }}
                            estimationData={{
                                travel: aggregation?.estimatedTravel / 60 * staff.length || 0,
                                hours: aggregation?.estimatedHours || 0,
                                cost: charge?.cost || 0,
                                charge: charge?.charge || 0,
                                staffCount: staff.length || 0,
                            }}
                            actualData={{
                                travel: ((tasks?.filter(x => x.taskName === "Travel Time")?.map(a => Number(a.taskValue)).reduce((a, b) => a + b, 0) || 0) / 60)  * staff.length,
                                hours: schedulesDetail?.staffEvents?.map(x => x.totalTime).reduce((a, b) => a + b, 0) / 60 || 0,
                                cost: charge?.cost || 0,
                                charge: charge?.charge || 0,
                                staffCount: staff.length || 0,
                            }}
                        />
                    </Grid>)}
                    <Grid item sm={12}>
                        {aggregation?.staff && aggregation.staff.length > 0 && <StaffTable title={"Staff Breakdown"} data={aggregation.staff} quotedHours={aggregation.estimatedHours / aggregation.staff.length} showSalary />}
                    </Grid>

                    <Grid item md={4} sm={12}>
                        {tasks?.length >= 1 && <LineTable
                            showCount={false}
                            useObject
                            data={tasks.map(x => ({staff: staff.find(y => y.staffId === x.staffId)?.staffName || x.staffId, name: x.taskName || "", value: x.taskValue || ""}))}
                            title={"Staff Task Events"}
                        />}
                    </Grid>

                    <Grid item md={8} sm={12}>
                        <LineTable showCount={false} showHead useObject data={schedulesDetail?.staffEvents?.map(x => ({
                            staff: staff.find(y => y.staffId === x.staffId)?.staffName || x.staffId,
                            date: moment(x.checkInDateTime).format("DD-MM-YYYY"),
                            checkIn: moment(x.checkInDateTime).format("HH:mm:ss"),
                            checkOut: moment(x.checkOutDateTime).format("HH:mm:ss"),
                            time: `${x.totalTime} minutes`,
                            travel: `${tasks.filter(y => y.staffId === x.staffId && y.taskName === "Travel Time").map(a => Number(a.taskValue)).reduce((a,b) => a + b, 0)} minutes`,
                            }))} title={"Staff Check Ins"} />
                    </Grid>

                    <Grid item sm={12} md={6}>
                        <JobStaffChart data={aggregation} />
                    </Grid>

                    <Grid item md={6} sm={12}>
                        {schedules && schedules.length > 0 && <ScheduleTable title={"Related Schedules"} data={schedules} />}
                    </Grid>
                </Grid>
            </Container>

            {schedulesDetail?.staffEvents && <AdjustDialog
                open={adjustmentOpen}
                onSave={() => alert("You do not have permission.")}
                onClose={() => setAdjustmentOpen(false)}
                travelTime={((tasks?.filter(x => x.taskName === "Travel Time")?.map(a => Number(a.taskValue)).reduce((a, b) => a + b, 0) || 0) / 60)}
                workingTime={schedulesDetail?.staffEvents?.map(x => x.totalTime).reduce((a, b) => a + b, 0) / 60 || 0}
            />}
        </>
    );
};

export default AuthorizationSchedule;
