import React, {forwardRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import ClientTable from "./Components/ClientTable";
import { GET_ALL_CUSTOMERS_WITH_AGG } from "./Scheme"
import { Card, CardHeader } from '@material-ui/core';
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {KpiCard} from "./Components/KpiCard";

const useStyles = makeStyles(componentStyles);

const Authorization = (props) => {
    const selectedWeek = props.location.search?.split("week=")[1]?.split("&")[0];
    const selectedYear = props.location.search?.split("year=")[1]?.split("&")[0];
    const weekDate = !selectedWeek ? moment().startOf("isoWeek") : moment().year(selectedYear).week(selectedWeek);
    weekDate.locale("en-GB");
    const week = () => weekDate.format("YYYY-MM-DD");

    const title = `${weekDate.startOf('week').add(1, "day").format("Do")} to ${weekDate.endOf('week').add(1, "day").format("Do of MMMM")}`;

    const goToWeek = (date) => {
        const momentDate = moment(date);

        props.history.push(`/admin/authorization?year=${momentDate.year()}&week=${momentDate.add(0, "week").format("w")}`);
    };

    const classes = useStyles();
    const { loading, data } = useQuery(GET_ALL_CUSTOMERS_WITH_AGG, {
        variables: { week: week() }
    });

    const CustomInput = forwardRef(({ onClick }, ref) => (
        <Button onClick={onClick} ref={ref}>
            Change Date
        </Button>
    ));

    return (
        <>
            <AlternativeHeader section={`Customer Overview`} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading
                    && data?.getAggregations?.weeks[0]?.clients?.length > 0
                    ? <>
                    <Grid container>
                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Jobs"}
                            toFixed={0}
                            projected={data.getAggregations.weeks[0]?.groupedSchedules?.length || 0}
                            total={data.getAggregations.weeks[0]?.groupedSchedules?.filter(x => x.totalHours > 0).length || 0}
                            suffix={"jobs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Job Hours"}
                            toFixed={2}
                            projected={data.getAggregations.weeks[0]?.estimatedHours || 0}
                            total={data.getAggregations.weeks[0]?.totalHours || 0}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Tasks"}
                            toFixed={0}
                            projected={data.getAggregations.weeks[0]?.clients.map(x => x?.estimatedTasks || 0).reduce((a, b) => a + b) || 0}
                            total={data.getAggregations.weeks[0]?.clients.map(x => x?.totalTasks || 0).reduce((a, b) => a + b) || 0}
                            suffix={"tasks"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Travel"}
                            toFixed={2}
                            projected={data.getAggregations.weeks[0]?.estimatedTravel / 60 || 0}
                            total={(data.getAggregations.weeks[0].clients.map(x => x.totalTravel).reduce((a, b) => a + b, 0) || 0) / 60}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>
                    </Grid>
                    <ClientTable data={data.getAggregations.weeks[0].clients} title={title} week={weekDate} showWeekChange onWeekChange={d => goToWeek(d)} />
                    </>
                    : <Card classes={{ root: classes.cardRoot }}>
                        <CardHeader
                            className={classes.cardHeader}
                            title={title}
                            titleTypographyProps={{
                                component: Box,
                                marginBottom: "0 !important",
                                variant: "h3",
                            }}
                            action={
                                <DatePicker
                                    onChange={(date) => goToWeek(date)}
                                    openToDate={weekDate.startOf("week").add(-1, "week").add(1, "day").toDate() || new Date()}
                                    withPortal
                                    locale="en-GB"
                                    filterDate={(date) => date.getDay() === 1 && weekDate.isBefore(moment().add(1, "day"))}
                                    customInput={<CustomInput />}
                                />
                            }
                        ></CardHeader>
                        <CardContent>
                            <Box component={"p"} marginBottom={0}>
                                Details will appear as schedule data is aggregated from check-ins and events.
                            </Box>
                        </CardContent>
                    </Card>}
            </Container>
        </>
    );
};

export default Authorization;
