import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink, useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import Home from "@material-ui/icons/Home";
import ArrowBack from "@material-ui/icons/ArrowBack";

// core components
import componentStyles from "assets/theme/components/headers/alternative-header.js";
import {Button} from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const Header = ({ section, subsection, showSave, showNew, showNewAction, subsectionLink, customButton, customButtonAction, customButton2, customButtonAction2, showBack, customAction, disableCustomButton = false, disableCustomButton2 = false }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={7} lg={6}>
              {showBack && <ArrowBack fontSize={"large"} style={{fontSize: "22px", marginRight: "10px", cursor: "pointer"}} onClick={() => history.goBack()} />}
              <Typography
                variant="h2"
                component="h6"
                className={clsx(classes.displayInlineBlock, classes.mb0)}
              >
                {section}
              </Typography>
              {subsection && <Breadcrumbs
                separator="-"
                aria-label="breadcrumb"
                classes={{
                  root: classes.breadcrumbRoot,
                  li: classes.breadcrumbLi,
                  ol: classes.breadcrumbOl,
                  separator: classes.breadcrumbSeparator,
                }}
              >
                <RouterLink
                  color="inherit"
                  to={"/admin/dashboard"}
                >
                  <Box
                    component={Home}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    top="1px"
                    position="relative"
                    style={{ verticalAlign: "top" }}
                  />
                </RouterLink>
                <RouterLink
                  color="inherit"
                  to={subsectionLink}
                >
                  {subsection}
                </RouterLink>
                <Typography
                  component="span"
                  className={classes.breadcrumbActive}
                >
                  {section}
                </Typography>
              </Breadcrumbs>}
            </Grid>
            <Grid item xs={5} lg={6} component={Box} textAlign="right">
              {customAction && customAction}
              {showSave && <Button type="submit" variant="outlined" size="small" className={classes.buttonRoot}>Save</Button>}
              {showNew && <Button onClick={showNewAction} variant="outlined" size="small" className={classes.buttonRoot}>New</Button>}
              {customButton && <Button disabled={disableCustomButton} onClick={customButtonAction} variant="outlined" size="small" className={classes.buttonRoot}>{customButton}</Button>}
              {customButton2 && <Button disabled={disableCustomButton2} onClick={customButtonAction2} variant="outlined" size="small" className={classes.buttonRoot}>{customButton2}</Button>}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

Header.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string,
  subsectionLink: PropTypes.string
};

export default Header;
