import { gql } from "@apollo/client";

export const CREATE_CHARGE = gql`
    mutation($data: CreateChargeInput!) {
        createCharge(createCharge: $data) {
            _id
        }
    }
`;

export const UPDATE_CHARGE = gql`
    mutation($id: ObjectId!, $data: UpdateChargeInput!) {
        updateCharge(id: $id, updateCharge: $data) {
            _id
            shortDescription
            charge
            cost
            hours
            updatedAt
            arCode
            apCode
        }
    }
`;

export const GET_CHARGE_BY_ID = gql`
    query ($id: ObjectId!) {
        getCharge(id: $id) {
            _id
            shortDescription
            longDescription
            charge
            cost
            hours
            arCode
            apCode
        }
        
        getJobsByCharge(chargeId: $id) {
            _id
            name
            type
            createdAt
            updatedAt
        }
    }
`;

export const GET_ALL_CHARGE = gql`
    query {
        getAllCharge {
            _id
            shortDescription
            charge
            cost
            hours
            updatedAt
        }
    }
`;
