import React, { useEffect } from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import {Button, OutlinedInput, Select} from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Edit from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

const tableHead = ["Site", "Job", "Job Hrs", "Quantity", "Total Hrs", "Total Travel", "Total Cost", "Total Revenue", "Total Profit", ""];

export default function EstimationTable({ title, data, sites, jobs, deleteHandler, newHandler, updateRowHandler, editTitleHandler }) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };
    const tableContainerRef = React.useRef(null);
    useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: tableHead.slice(0, tableHead.length),
            listClass: "list"
        });
    }, []);

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    console.log("Jobs in the table");
    console.log(data);

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={<>{title}<Edit onClick={() => editTitleHandler()} style={{marginLeft: 12, width: 16, height: 16, marginTop: -4}} /></>}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                    action={
                        <Button onClick={() => newHandler()} variant="outlined" size="small" className={classes.buttonRoot} style={{margintop:"7px"}}>Add</Button>
                    }
                ></CardHeader>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "name" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot}}>
                                        <FormControl variant="outlined" fullWidth>
                                            <Select defaultValue={prop.siteId || 1} IconComponent={KeyboardArrowDown} className="form-control-sm" onChange={e => updateRowHandler(key, "siteId", e.target.value)}>
                                                <MenuItem value={null}>None</MenuItem>
                                                {sites && sites.map(s => <MenuItem key={s._id} value={s._id}>{s.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}>
                                        <FormControl variant="outlined" fullWidth>
                                            <Select defaultValue={prop.jobId || 1} IconComponent={KeyboardArrowDown} className="form-control-sm" onChange={e => updateRowHandler(key, "jobId", e.target.value)}>
                                                {jobs && jobs.map(s => <MenuItem key={s._id} value={s._id}>{s.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {prop.job && (
                                            <Box component={"div"} style={{textAlign:"center", fontSize: 12, marginTop: 5, position: "absolute", width: "calc(100% - 3.1rem)"}}>
                                                <Box component={"span"} style={{paddingRight: 5}}>
                                                    {prop.job.chargeLine.quantity} hr
                                                </Box>
                                                |
                                                <Box component={"span"} style={{paddingRight: 5, paddingLeft:5, color: "green"}}>
                                                    £{Number(prop.chargeLine.cost).toFixed(2)}
                                                </Box>
                                                |
                                                <Box component={"span"} style={{paddingLeft: 5, color: "red"}}>
                                                    £{Number(prop.chargeLine.charge).toFixed(2)}
                                                </Box>
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}>
                                        <OutlinedInput
                                            fullWidth
                                            className="form-control-sm"
                                            placeholder="1 hr"
                                            type="number"
                                            value={prop["hours"]}
                                            onChange={e => updateRowHandler(key, "hours", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}>
                                        <OutlinedInput
                                            fullWidth
                                            className="form-control-sm"
                                            placeholder="1"
                                            type="number"
                                            value={prop["quantity"] || 0}
                                            onChange={e => updateRowHandler(key, "quantity", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}><strong>{(Number(prop.hours || 0) * Number(prop.quantity || 0)).toFixed(2)}</strong></TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}><strong>{Number(prop.travel || 0).toFixed(2)}</strong></TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}><strong>£{Number(prop.cost || 0).toFixed(2)}</strong></TableCell>
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }}><strong>£{Number(prop.charge || 0).toFixed(2)}</strong></TableCell>
                                    {<TableCell classes={{ root: classes.tableCellPaddedRoot }}>
                                        {
                                            <strong>
                                                £{Number(prop.profit || 0).toFixed(2)} (<Box component="span" style={{color: prop.profitPercentage > 0 ? "green" : "red"}}>{prop.profitPercentage || 0}%</Box>)
                                            </strong>
                                        }
                                    </TableCell>}
                                    <TableCell classes={{ root: classes.tableCellPaddedRoot }} align="right">
                                        <TableDropdown id={key} actions={[
                                            { text: "Delete", handle: () => deleteHandler(key), style: {color: "red"} }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
        </>
    );
}
