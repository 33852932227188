import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesButtons from "../../../../../assets/theme/components/button";
import Tabled from "@material-ui/core/Table";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

export const Table = ({data, head}) => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
    };

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={"Logs"}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }} />
                <TableContainer>
                    <Box component={Tabled} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {head?.length > 1 && head.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{ "data-sort": prop.toLowerCase() }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {data?.length >= 1 && data.map((prop, key) => (
                                <TableRow key={key}>
                                    {head?.length > 1 && head.map((dp, k) => <TableCell key={k} classes={{ root: classes.tableCellRoot }}>{prop[dp]}</TableCell>)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
        </>
    );
}
