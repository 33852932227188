import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import ChargeTable from "./Components/ChargeTable";
import { GET_ALL_CHARGE } from "./Scheme"

const useStyles = makeStyles(componentStyles);

const Charges = (props) => {
    const classes = useStyles();
    const { loading, data } = useQuery(GET_ALL_CHARGE);

    return (
        <>
            <AlternativeHeader section="Charges" showNew showNewAction={() => props.history.push("/admin/charge/add")} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading && data.getAllCharge.length > 0 && <ChargeTable data={data.getAllCharge} props={props} />}
            </Container>
        </>
    );
};

export default Charges;
