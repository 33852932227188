import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default function ConfirmationBox({message, successCallback, cancelCallback, type, title}) {
    if (!type) type = "info";
    if (!title) title = "Info"

    return (
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            reverseButtons
            title={title}
            onConfirm={() => successCallback(null)}
            onCancel={() => cancelCallback(null)}
            confirmBtnBsStyle={type}
            confirmBtnText="Yes"
            cancelBtnBsStyle="info"
            cancelBtnText={"No"}
            btnSize=""
            showCancel
            confirmBtnStyle={{
                marginRight: undefined,
                borderColor: undefined,
                boxShadow: undefined,
            }}
        >
            {message}
        </ReactBSAlert>
    );
}
