import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Person from "@material-ui/icons/Person";
import Email from "@material-ui/icons/Email";
import Visibility from "@material-ui/icons/Visibility";
import Call from "@material-ui/icons/Call";
import { Button, Divider, FormControl } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import {
    CREATE_CUSTOMER, CREATE_CUSTOMER_SITE, GET_ALL_CUSTOMERS,
    UPDATE_CUSTOMER, UPDATE_CUSTOMER_SITE, GET_BASE_DATA, GET_CUSTOMER_BY_ID
} from "./Scheme";
import componentStylesButtons from "../../../../assets/theme/components/button";
import SiteTable from "./Components/SiteTable";
import AddSite from "./Components/AddSite";
import componentStylesForms from "assets/theme/components/forms.js";
import AddressForm from "../../../../components/AddressForm";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomFields, { CustomFieldsAggregator } from "../../../../components/Shared/CustomFields";
import Note from "@material-ui/icons/Note";
import { useHistory } from "react-router-dom";
import SuccessSnackbar from "../../../../components/Shared/SuccessSnackbar";

const useStylesForms = makeStyles(componentStylesForms);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const AddCustomer = (props) => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesForms()
    };

    const history = useHistory();

    const id = props.match.params.customerId;
    const updating = id !== "add";

    // Other State Variables
    const [showSiteModal, setShowSiteModal] = useState(false);
    const [siteModalData, setSiteModalData] = useState(null);
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [fields, setFields] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);

    // Form Variables
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [reference, setReference] = useState("");
    const [address, setAddress] = useState({});
    const [sites, setSites] = useState(null);
    const [jobs, setJobs] = React.useState([]);
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [notes, setNotes] = useState("");

    const [successText, setSuccessText] = useState("");

    const [createCustomer] = useMutation(CREATE_CUSTOMER, { refetchQueries: [{ query: GET_ALL_CUSTOMERS }, { query: GET_CUSTOMER_BY_ID }] });
    const [createCustomerSite] = useMutation(CREATE_CUSTOMER_SITE);
    const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
    const [updateCustomerSite] = useMutation(UPDATE_CUSTOMER_SITE);

    let customerQuery = updating ? GET_CUSTOMER_BY_ID : GET_BASE_DATA;

    const customerResult = useQuery(customerQuery, { variables: { id } });

    const handleShowSiteModal = (data) => {
        if (!id) {
            // If the id is null, don't let
            // the user create a site.
            setSuccessText("Please save the customer before creating a site.");
            return;
        }

        setSiteModalData(data ? data : null);
        setShowSiteModal(true);
    }

    const saveSite = (data) => {
        const site = {
            name: data.name,
            notes: data.notes,
            address: data.address,
            jobs: data.jobs.map(j => j._id || j)
        };

        delete site._id;
        delete site.address.__typename;

        if (data._id) {
            updateCustomerSite( { variables: { data: site, clientId: id, id: data._id } } )
                .then(x => {
                    setSites([ ...sites.filter(s => s._id !== data._id), {...x, ...site } ]);
                    setSuccessText("Site updated!");
                    setSiteModalData(null);
                });
        } else {
            createCustomerSite( { variables: { data: site, clientId: id } } )
                .then(x => {
                    setSites([ ...sites, { ...x, ...site } ]);
                    setSuccessText("Site created!");
                    setSiteModalData(null);
                });
        }
    };

    const handleForm = (e) => {
        e.preventDefault();

        const data = {
            name,
            email,
            phone,
            notes,
            reference,
            jobs: selectedJobs.map(j => j._id),
            address: { ...address, countryName: "UK" },
            customFields: fieldValues.map(x => ({...x, __typename: undefined}))
        };

        delete data.address.__typename;

        if (!updating) {
            createCustomer({ variables: { data } })
                .then(x => {
                    props.history.push(`/admin/customer/${x.data.createClient._id}`);
                    setSuccessText("Customer created!");
                });
        } else {
            updateCustomer({ variables: { data, id } })
                .then(() => setSuccessText("Customer saved!"))
                .catch(() => {/* todo */});
        }
    };

    useEffect(() => {
        if (!customerResult.loading && customerResult?.data?.getAllJob) setJobs(customerResult.data.getAllJob);

        if (customerResult?.data?.getUserOrganisation?.fields) setFields(customerResult?.data?.getUserOrganisation?.fields);

        if (updating && customerResult?.data?.getClient && customerResult?.data?.getAllJob && customerResult?.data?.getUserOrganisation?.fields) {
            const result = customerResult?.data?.getClient;
            const clientJobs = customerResult?.data?.getAllJob.filter(x => result.jobs.includes(x._id));

            setName(result.name);

            setEmail(result.email);
            setPhone(result.phone);

            setNotes(result.notes);

            setSelectedJobs(clientJobs || []);

            setReference(result.reference);
            setSites(result.sites);

            setAddress(result?.address || {});

            // Add existing custom fields (and add any that
            // don't exist) or show all options.
            setFieldValues(CustomFieldsAggregator(result?.customFields, customerResult?.data?.getUserOrganisation?.fields));
        }
    }, [customerResult, updating]);

    return (
        <>
            <form onSubmit={handleForm}>
            <AlternativeHeader section="Add Customer" subsection="Customers" subsectionLink={`/admin/customers`} showSave customButton="Create Estimation" customButtonAction={() => history.push(`/admin/estimations/add/${id}`)} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Basics"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                required
                                                placeholder="Name"
                                                value={name}
                                                startAdornment={<InputAdornment position="start"><Person /></InputAdornment>}
                                                onChange={x => setName(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Divider classes={{ root: classes.divider }} style={{marginBottom: '25px'}} />
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <OutlinedInput
                                                fullWidth
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}
                                                onChange={x => setEmail(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <OutlinedInput
                                            fullWidth
                                            type="text"
                                            placeholder="Phone number"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Call />
                                                </InputAdornment>
                                            }
                                            value={phone}
                                            onChange={x => setPhone(x.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="Reference"
                                                startAdornment={<InputAdornment position="start"><Visibility /></InputAdornment>}
                                                value={reference}
                                                onChange={x => setReference(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Divider classes={{ root: classes.divider }} style={{marginBottom: '25px'}} />
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                multiline
                                                placeholder="Notes e.g., keycode is 1234"
                                                value={notes}
                                                startAdornment={<InputAdornment position="start"><Note /></InputAdornment>}
                                                onChange={x => setNotes(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Custom Fields"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <CustomFields
                                            initialValue={fieldValues}
                                            valueCallback={values => setFieldValues(values)}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {(!sites || sites.length === 0) && (
                            <Card classes={{root: classes.cardRoot}}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Sites"
                                    titleTypographyProps={{
                                        component: Box,
                                        marginBottom: "0 !important",
                                        variant: "h3",
                                    }}
                                />
                                <CardContent>
                                    <Grid container alignContent={"center"} alignItems={"center"}>
                                        <Grid item xs={12} lg={12}>
                                            <Button
                                                variant="contained"
                                                classes={{
                                                    root: classes.buttonContainedSuccess,
                                                }}
                                                fullWidth
                                                onClick={() => handleShowSiteModal()}
                                            >
                                                New Site
                                            </Button>
                                            <Box
                                                component="p"
                                                marginBottom="0"
                                                fontWeight="300"
                                                lineHeight="1.5"
                                                fontSize="0.8rem"
                                                marginTop="10px"
                                                paddingTop={'10px'}
                                                textAlign={"center"}
                                            >
                                                Sites are optional, use them if you need to divide customers with multiple locations.
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                        {sites && sites.length > 0 &&
                            <SiteTable
                                data={sites}
                                props={props}
                                editHandler={x => handleShowSiteModal(x)}
                                deleteHandler={() => setSuccessText("Unable to delete site, there are references that need to be removed first.")}
                                newSiteHandler={() => handleShowSiteModal(null)}/>}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Address"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <AddressForm
                                    address={address}
                                    setCallback={(v) => {
                                        setAddress(v)
                                    }}
                                    showTravel={true}
                                />
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Jobs"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Box
                                    component="p"
                                    marginBottom="20px"
                                    fontWeight="300"
                                    lineHeight="1.5"
                                    fontSize="0.8rem"
                                    marginTop="0"
                                    textAlign={"center"}
                                >
                                    Use jobs as a simple way to record and manage work.
                                </Box>
                                <FormControl sx={{ m: 1, width: 300 }} fullWidth style={{maxWidth: "100%"}}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={jobs}
                                        multiple
                                        value={selectedJobs}
                                        getOptionLabel={(option) => option.name}
                                        closeIcon={null}
                                        popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important" height="1.25rem!important" />}
                                        renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                                        onChange={(a, value) => { setSelectedJobs(value) }}
                                    />
                                </FormControl>

                                <Box
                                    component="p"
                                    marginBottom="0"
                                    fontWeight="300"
                                    lineHeight="1.5"
                                    fontSize="0.8rem"
                                    marginTop="10px"
                                    paddingTop={'10px'}
                                    textAlign={"center"}
                                >
                                    For customers with sites, these jobs will default onto the sites but can be removed.
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            </form>
            {/* Notification */}
            <SuccessSnackbar
                successText={successText}
                closeCallback={() => setSuccessText("")} />

            <AddSite
                showModal={showSiteModal || false}
                data={siteModalData}
                customerSelectedJobs={selectedJobs}
                availableJobs={jobs}
                closeEvent={() => setShowSiteModal(false)}
                saveEvent={(s) => {saveSite(s)}} />
        </>
    );
};

export default AddCustomer;
