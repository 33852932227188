import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from '../../../../assets/theme/views/admin/calendar';
import componentStylesButtons from '../../../../assets/theme/components/button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import List from 'list.js';
import componentStylesDialog from '../../../../assets/theme/components/dialog';

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesDialog = makeStyles(componentStylesDialog);

const tableHead = ["start", "end", "staff count", ""];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const ScheduleHistoryDialog = ({ open, baseData, onClose, data }) => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    const tableContainerRef = React.useRef(null);
    React.useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: ["start", "end", "staff count", ""],
            listClass: "list"
        });
    }, []);

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    const [isOpen, setIsOpen] = useState(false);

    const [, setClients] = useState([]);
    const [, setJobs] = useState([]);
    const [, setStaff] = useState([]);

    useEffect(() => {
        setClients(baseData.clients);
        setJobs(baseData.jobs);
        setStaff(baseData.staff);
    }, [baseData])

    useEffect(() => { setIsOpen(open); console.log(open); }, [open]);

    console.log(tableData);

    return (<Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent>
            <Box>
                <TableContainer ref={tableContainerRef}>
                    <Box component={"h2"}>History for {tableData[0]?.shortDescription}</Box>
                    <Box component={Table} alignItems="center" marginBottom="0!important" marginTop="20px">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "forename" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.dateTimeStart}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.dateTimeEnd}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.staffIds.length}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Box>
        </DialogContent>
        <DialogActions>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Button onClick={() => onClose()} color="primary">
                    Close
                </Button>
            </Box>
        </DialogActions>
    </Dialog>);
};
