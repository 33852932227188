const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  buttonRoot: {
    marginLeft: "10px",
    padding: ".6rem 1.25rem",
    marginRight: "0 !important",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:first-of-type": { marginRight: ".75rem" },
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  cardImageTopExtra: {
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px'
  },
  listItemRoot: {
    display:"flex",
    padding: "10px 0 0 0"
  },
  listItemTitlePrimaryRoot: {
    fontSize: "1.05rem",
    paddingLeft:"5px"
  },
  listItemTitleSecondaryRoot: {
    fontSize: "0.8rem",
    paddingLeft:"5px"
  }
});

export default componentStyles;
