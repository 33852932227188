import { gql } from "@apollo/client";

export const CREATE_SIMPLE_JOB = gql`
    mutation($data: CreateSimpleJobInput!) {
        createSimpleJob(createSimpleJobInput: $data) {
            _id
        }
    }
`;

export const CREATE_ADVANCED_JOB = gql`
    mutation($data: CreateAdvancedJobInput!) {
        createAdvancedJob(createAdvancedJobInput: $data) {
            _id
            rooms {
                unit
                area
                notes
                type
                name
                _id
            }
        }
    }
`;

export const UPDATE_ADVANCED_JOB = gql`
    mutation($data: UpdateAdvancedJobInput!, $id: ObjectId!) {
        updateAdvancedJob(id: $id, updateAdvancedJobInput: $data) {
            _id
            rooms {
                unit
                area
                notes
                type
                name
                _id
            }
        }
    }
`;

export const UPDATE_JOB = gql`
    mutation($id: ObjectId!, $data: UpdateSimpleJobInput!) {
        updateSimpleJob(id: $id, updateJob: $data) {
            _id
            name
            updatedAt
            type
            enableTravelTracking
            tasks {
                description
                priority
                roomId
                startupTask
                type
            }
        }
    }
`;

export const GET_SIMPLE_JOB_BY_ID = gql`
    query ($id: ObjectId!) {
        getSimpleJob(id: $id) {
            _id
            name
            enableTravelTracking
            type
            chargeLine {
                chargeId
                quantity
            }
            tasks {
                type
                description
                priority
                roomId
            }
            updatedAt
            createdAt
            notes
        }
        
        getUserOrganisation {
            propertyTypes
            roomTypes
        }

        getAllCharge {
            _id
            shortDescription
            charge
            cost
            hours
            updatedAt
        }
        
        getClientsByJob(jobId: $id) {
            _id
            name
            siteCount
        }
    }
`;

export const GET_BASE_DATA = gql`
    query {
        getUserOrganisation {
            propertyTypes
            roomTypes
        }

        getAllCharge {
            _id
            shortDescription
            charge
            cost
            hours
            updatedAt
        }
    }
`;

export const GET_ADVANCED_JOB_BY_ID = gql`
    query ($id: ObjectId!) {
        getAdvancedJob(id: $id) {
            _id
            name
            type
            enableTravelTracking
            chargeLine {
                chargeId
                quantity
            }
            tasks {
                type
                description
                priority
                roomId
            }
            updatedAt
            createdAt
            notes
            propertyType
            
            rooms {
                _id
                name
                type
                notes
                area
                unit
            }
        }
    }
`;

export const GET_ALL_JOB = gql`
    query {
        getAllJob {
            _id
            name
            type
            createdAt
            updatedAt
        }
    }
`;
