import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FilledInput from '@material-ui/core/FilledInput';
import Moment from 'moment/moment';
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from '../../../../assets/theme/views/admin/calendar';
import componentStylesButtons from '../../../../assets/theme/components/button';
import componentStylesForms from '../../../../assets/theme/components/forms';
import * as momenttz from "moment-timezone";
import moment from "moment";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesForms = makeStyles(componentStylesForms);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const recurrences = [{ name: "One off", value: "ONE_OFF" }, { name: "Daily", value: "DAILY" }, { name: "Weekly", value: "WEEKLY" }, { name: "Fortnightly", value: "FORTNIGHTLY"}];

export const ScheduleDialog = ({ open, scheduleDialog, onDelete, onSave, onClose, baseData, openEvents }) => {
    const [isOpen, setIsOpen] = useState(false);
    const classes = { ...useStyles(), ...useStylesButtons(), ...useStylesForms() };

    const [clients, setClients] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [staff, setStaff] = useState([]);

    const [showSites, setShowSites] = React.useState(false);
    const [startDate, setStartDate] = React.useState(moment().toDate()); // todo default to the selected date, right?
    const [endDate, setEndDate] = React.useState(new Date());
    const [radios, setRadios] = React.useState(null);
    const [siteId, setSiteId] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const [jobId, setJobId] = React.useState('');
    const [staffIds, setStaffIds] = React.useState([])
    const [shortDescription, setShortDescription] = React.useState("");
    const [isMasterSchedule, setIsMasterSchedule] = React.useState(true);
    // If the scheduleId is set, user is editing.
    const [scheduleId, setScheduleId] = React.useState(null);
    const [recurrence, setRecurrence] = React.useState(null);
    const [isHistoric, setIsHistoric] = React.useState(false);

    const editMaster = () => {
        setIsMasterSchedule(true);
        // setRecurrence(recurrence);
    }

    const setClient = x => {
        // When a client is selected, and the site
        // count is greater than one, show sites.
        setClientId(x._id);
        setShowSites(x.siteCount > 0);
    };

    useEffect(() => {
        setClients(baseData.clients);
        setJobs(baseData.jobs);
        setStaff(baseData.staff);

        console.log(baseData);
    }, [baseData])

    // Data
    useEffect(() => {
        setStartDate(scheduleDialog.startDate);
        setEndDate(scheduleDialog.endDate);
        setRadios(scheduleDialog.radios);
        setSiteId(scheduleDialog.siteId);
        setShowSites(!!scheduleDialog.siteId);
        setClientId(scheduleDialog.clientId);
        setJobId(scheduleDialog.jobId);
        setStaffIds(scheduleDialog.staffIds);
        setShortDescription(scheduleDialog.shortDescription);
        setIsMasterSchedule(scheduleDialog.isMasterSchedule);
        setScheduleId(scheduleDialog._id);
        setRecurrence(scheduleDialog.recurrence);
        setIsHistoric(moment(scheduleDialog.endDate).isBefore(moment()));
    }, [scheduleDialog]);

    useEffect(() => { setIsOpen(open); }, [open]);

    const transformStateToObject = () => {
        return {
            ...scheduleDialog,
            startDate: momenttz(startDate).tz("Europe/London").format(),
            endDate: momenttz(endDate).tz("Europe/London").format(),
            radios,
            siteId,
            clientId,
            jobId,
            staffIds,
            shortDescription,
            isMasterSchedule,
            recurrence
        };
    }

    return (<Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent>
            {!isMasterSchedule && (
                <Box component="p" marginBottom="15px" fontWeight="500" lineHeight="1.5" fontSize="0.8rem" style={{cursor: "pointer"}} textAlign={"right"} color={"#5e72e4"} onClick={() => editMaster()}>
                    Edit Master Schedule <ArrowRight />
                </Box>
            )}
            <Grid container>
                <Grid item md={6}>
                    <FormGroup>
                        <FormLabel>Start</FormLabel>
                        <FilledInput
                            placeholder="Start"
                            type="datetime-local"
                            value={startDate && Moment(startDate).isValid() ? Moment(startDate).format("YYYY-MM-DDTHH:mm") : startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </FormGroup>
                </Grid>
                <Grid item md={6}>
                    <FormGroup>
                        <FormLabel>End</FormLabel>
                        <FilledInput
                            placeholder="End"
                            type="datetime-local"
                            value={endDate && Moment(endDate).isValid() ? Moment(endDate).format("YYYY-MM-DDTHH:mm") : endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </FormGroup>
                </Grid>
                {isMasterSchedule && <Grid item md={12}>
                    <FormGroup>
                        <RadioGroup
                            aria-label="recurrence"
                            name="recurrence-selection"
                            defaultValue={recurrence}
                            value={recurrence}
                            onChange={(x, y) => setRecurrence(y)}
                            className={clsx(
                                classes.displayInlineBlock,
                                classes.inputSmall
                            )}
                        >
                            {recurrences?.map(r =>
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label={r.name}
                                    value={r.value}
                                    key={r.name}
                                    labelPlacement="end"
                                    classes={{
                                        root: clsx(classes.formControlCheckboxLabelRoot, classes.inlineRadio),
                                        label: classes.formControlCheckboxLabelLabel,
                                    }}
                                />
                            )}
                        </RadioGroup>
                    </FormGroup>
                </Grid>}
            </Grid>
            <FormGroup>
                <FormLabel>Short Description</FormLabel>
                <FilledInput
                    placeholder="Short Description"
                    type="text"
                    value={shortDescription}
                    disabled={!isMasterSchedule}
                    onChange={(e) => setShortDescription(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Client</FormLabel>
                <Autocomplete
                    id="combo-box-demo"
                    options={clients}
                    value={(clientId && clients.find(c => c._id === clientId)) || ''}
                    getOptionLabel={(option) => option.name || ''}
                    closeIcon={null}
                    disabled={!isMasterSchedule}
                    onChange={(x, y) => setClient(y)}
                    popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important" height="1.25rem!important" />}
                    renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                />
            </FormGroup>
            {showSites &&
                <FormGroup>
                    <FormLabel>Site</FormLabel>
                    <Autocomplete
                        id="combo-box-demo"
                        options={clientId && clients.find(c => c._id === clientId)?.sites}
                        value={siteId && clientId&& clients.find(c => c._id === clientId)?.sites.find(s => s._id === siteId)}
                        getOptionLabel={(option) => option.name || ''}
                        closeIcon={null}
                        onChange={(x, y) => setSiteId(y._id)}
                        popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important"
                                        height="1.25rem!important"/>}
                        renderInput={(params) => (<TextField {...params} variant="outlined"/>)}
                    />
                </FormGroup>
            }
            {isMasterSchedule && <FormGroup>
                <FormLabel>Color</FormLabel>
                <Box>
                    {[
                        { color: "bg-info", colorClass: classes.buttonContainedInfo },
                        { color: "bg-warning", colorClass: classes.buttonContainedWarning },
                        { color: "bg-danger", colorClass: classes.buttonContainedError },
                        { color: "bg-success", colorClass: classes.buttonContainedSuccess },
                        { color: "bg-default", colorClass: classes.buttonContainedDefault },
                        { color: "bg-primary", colorClass: classes.buttonContainedPrimary }
                    ].map(rc => (
                        <Box display="inline-block" marginRight=".5rem" key={rc.colorClass}>
                            <IconButton classes={{root: clsx(rc.colorClass, classes.radioButton)}} onClick={() => setRadios(rc.color)}>
                                {radios === rc.color && (
                                    <Box width="1.25rem!important" height="1.25rem!important" component={Check} />
                                )}
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </FormGroup>}
            <FormGroup>
                <FormLabel>Job (optional)</FormLabel>
                <Autocomplete
                    id="combo-box-demo"
                    options={jobs || []}
                    value={(jobId && jobs.find(x => x._id === jobId)) || ''}
                    getOptionLabel={(option) => option.name || ''}
                    closeIcon={null}
                    popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important" height="1.25rem!important" />}
                    renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                    onChange={(a, value) => { setJobId(value._id) }}
                />
                {jobId && jobs?.find(x => x._id === jobId)?.chargeLine && <Box>
                    <span style={{fontSize: 13}}>Total Hours: {jobs.find(x => x._id === jobId)?.chargeLine?.quantity} hrs</span>
                </Box>}
            </FormGroup>
            <FormGroup>
                <FormLabel>Include salary</FormLabel>
                <label className="custom-toggle">
                    <input defaultChecked type="checkbox" disabled={!isMasterSchedule} />
                    <span className="custom-toggle-slider rounded-circle" />
                </label>
            </FormGroup>
            <FormGroup>
                <FormLabel>Staff</FormLabel>
                <Autocomplete
                    id="combo-box-demo"
                    options={staff || []}
                    getOptionLabel={(option) => `${option.forename} ${option.surname}`}
                    value={staff.filter(x => staffIds?.includes(x._id)) || ''}
                    closeIcon={null}
                    multiple
                    popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important" height="1.25rem!important" />}
                    renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                    onChange={(a, value) => { setStaffIds(value.map(x => x._id)) }}
                />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Box>
                    {isHistoric
                        ? <Button color="primary" variant="contained" onClick={() => openEvents()}>View</Button>
                        : <>
                            <Button color="primary" variant="contained" onClick={() => onSave(transformStateToObject())}>
                                {scheduleId ? "Save" : "Add"} Schedule
                            </Button>
                            <Button onClick={() => openEvents()}>Preview</Button>
                        </>}


                    {scheduleId && <Button onClick={onDelete} color="warning">Delete</Button>}
                </Box>
                <Button onClick={() => onClose()} color="primary">
                    Close
                </Button>
            </Box>
        </DialogActions>
    </Dialog>);
};
