import {gql} from "@apollo/client";

export const GET_ALL_SCHEDULE_LOGS = gql`
    query {
        getAllScheduleLog {
            staffName
            staffId
            scheduleName
            clientName
            timestamp
            schedule
            action
            taskDescription
            taskValue
        }
    }
`;

export const GET_ALL_SCHEDULE_LOGS_BETA = gql`
    query {
        getAllScheduleLogBeta {
            staffName
            staffId
            scheduleName
            clientName
            timestamp
            schedule
            action
            taskDescription
            taskValue
        }
    }
`;