import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {APPROVE_CLIENT_WEEK, GET_CUSTOMER_BY_ID_WITH_AGG, GET_SCHEDULE_CUSTOMERS_WITH_AGG} from "./Scheme"
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import StaffTable from "./Components/StaffTable";
import JobTable from "./Components/JobTable";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {KpiCard} from "./Components/KpiCard";
import SiteTable from "./Components/SiteTable";
import SchedulesTable from "./Components/SchedulesTable";
import Papa from 'papaparse';

const useStyles = makeStyles(componentStyles);

const AuthorizationClient = (props) => {
    const classes = useStyles();

    const id = props.match.params.clientId;
    const date = props.match.params.date;
    const period = "week";

    const { loading, data } = useQuery(GET_CUSTOMER_BY_ID_WITH_AGG, { variables: { week: date, clientId: id } });
    const [approveClientWeek] = useMutation(APPROVE_CLIENT_WEEK);
    const [getSchedules] = useLazyQuery(GET_SCHEDULE_CUSTOMERS_WITH_AGG);

    // The State
    const [aggregation, setAggregation] = useState({});
    const [approveOpen, setApproveOpen] = useState(false);
    const [client, setClient] = useState({});
    const [canApprove, setCanApprove] = useState(false);

    console.log(client);

    useEffect(() => {
        if (loading) return;

        if (data?.getClient) {
            setClient(data.getClient);
        }

        if (data?.getAggregationsByClient) {
            const agg = data.getAggregationsByClient.weeks[0].clients[0];
            console.log(agg);
            setCanApprove(!agg.approved && !moment(date).isSame(moment(), "isoWeek"));
            setAggregation(agg);
        }
    }, [data, loading, date]);

    const approveClientWeekHandler = async () => {
        if (!canApprove) alert("You are unable to approve this week and client.");

        setCanApprove(false);

        const result = await approveClientWeek({
            variables: {
                clientId: id,
                week: date,
            }
        });

        if (result.errors?.length > 0) {
            alert(`There were some errors: ${result.errors.map(y => y.message).join(" and ")}`);
        } else if (result.data.approveAggregationForClient) {
            // Success, show export.
            return;
        } else {
            alert(`Something went wrong, please try again.`);
        }

        setCanApprove(true);
    };

    const exportScheduleLines = async () => {
        let scheduleList = [];

        for (const x of aggregation.groupedSchedules) {
            const schedules = await getSchedules({
                variables: {
                    week: date,
                    clientId: id,
                    scheduleId: x.scheduleId,
                    startWeek: moment(date).startOf("isoWeek").toDate(),
                    endWeek: moment(date).endOf('isoWeek').toDate(),
                }
            });

            if (schedules.data) scheduleList.push(schedules.data.getAggregationsByClient.weeks[0].clients[0].groupedSchedules.filter(y => x.scheduleId === y.scheduleId)[0]);
        }

        console.log("Resulted output", scheduleList);

        const res = Papa.unparse(scheduleList, {});

        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'export.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <AlternativeHeader
                section={`${client.name} Details`}
                showBack
                // customButton="Adjust"
                // customButtonAction={() => setAdjustmentOpen(true)}
                customButton2="Export"
                customButtonAction2={exportScheduleLines}
                disableCustomButton2={canApprove}
                customButton="Approve"
                customButtonAction={() => setApproveOpen(true)}
                disableCustomButton={!canApprove} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Jobs"}
                            toFixed={0}
                            projected={aggregation.groupedSchedules?.length || 0}
                            total={aggregation.groupedSchedules?.filter(x => x.totalHours > 0).length || 0}
                            suffix={"jobs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Job Hours"}
                            projected={aggregation.estimatedHours}
                            total={aggregation.totalHours}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Tasks"}
                            toFixed={0}
                            projected={aggregation.estimatedTasks}
                            total={aggregation.totalTasks}
                            suffix={"tasks"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Travel"}
                            toFixed={2}
                            projected={aggregation?.estimatedTravel / 60 || 0}
                            total={(aggregation?.totalTravel || 0) / 60}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={12}>
                        {aggregation.groupedSchedules && <SchedulesTable data={aggregation.groupedSchedules} clientId={id} date={date} />}
                    </Grid>

                    <Grid item sm={12}>
                        {aggregation.jobs && <JobTable data={aggregation.jobs} clientId={id} date={date} />}
                    </Grid>

                    <Grid item md={12} sm={12}>

                            {aggregation?.sites && aggregation?.sites.length > 0 ? <>
                                <SiteTable title={"Site Breakdown"} data={aggregation.sites} clientId={id} date={date} />
                            </> :<Card classes={{ root: classes.cardRoot }}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Site Breakdown"
                                    titleTypographyProps={{
                                        component: Box,
                                        marginBottom: "0 !important",
                                        variant: "h3",
                                    }}
                                />
                                <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Box component={"p"} textAlign={"center"} marginBottom={0}>
                                            There are no available sites or details to display here.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent></Card>}
                    </Grid>

                    <Grid item sm={12}>
                        {aggregation?.staff && aggregation.staff.length > 0 && <StaffTable title={"Staff Breakdown"} data={aggregation.staff} showSalary />}
                    </Grid>
                </Grid>
            </Container>
            {/*<AdjustDialog open={adjustmentOpen} onClose={() => setAdjustmentOpen(false)} />*/}
            <SimpleDialog
                message={`Approving this will create an equivalent invoice line and disable adjustments to the clients for this ${period}.`}
                title={`Approve ${period} starting ${moment(date).format("DD-MM-YYYY")}?`}
                successCallback={async () => {
                    await approveClientWeekHandler();
                    setApproveOpen(false);
                }}
                cancelCallback={() => setApproveOpen(false)}
                show={approveOpen} />
        </>
    );
};

export default AuthorizationClient;
