import React, { useEffect } from "react";
import clsx from "clsx";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Home from "@material-ui/icons/Home";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import componentStyles from "assets/theme/views/admin/calendar.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SCHEDULE,
  GET_ALL_SCHEDULES,
  GET_SCHEDULE_BASE_DATA,
  UPDATE_SCHEDULE,
  DELETE_SCHEDULE,
  GET_SCHEDULE_BY_ID
} from "./Scheme";
import Moment from "moment";
import componentStylesForms from "../../../assets/theme/components/forms";
import ConfirmationBox from "../../../components/Shared/ConfirmationBox";
import InfoBox from "../../../components/Shared/InfoBox";
import { ScheduleDialog } from './Components/ScheduleDialog';
import {ScheduleHistoryDialog} from './Components/ScheduleHistory';
import {ScheduleEvents} from "./Components/ScheduleEvents";
import rrulePlugin from '@fullcalendar/rrule'

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesForms = makeStyles(componentStylesForms);

let schedule;

const Schedule = (props) => {
  const classes = { ...useStyles(), ...useStylesButtons(), ...useStylesForms() };
  const [alert, setAlert] = React.useState(null);
  const [modalAdd, setModalAdd] = React.useState(false);
  const [modalHistory, setModalHistory] = React.useState(false);
  const [modalEvent, setModalEvent] = React.useState(false);
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [history, setHistory] = React.useState([]);
  const apolloClient = useApolloClient();

  const showId = props?.match?.params?.id;

  useEffect(() => {
    if (showId) {
      apolloClient.query({
        query: GET_SCHEDULE_BY_ID,
        variables: { id: showId }
      }).then(x => {
        setModalHistory(true);
        setHistory(x.data.getAllSchedules);
      });
    }
    // eslint-disable-next-line
  },[]);


  // Base data
  const [clients, setClients] = React.useState([]);
  const [jobs, setJobs] = React.useState([]);
  const [staff, setStaff] = React.useState([]);

  // Form Data
  const [scheduleDialog, setScheduleDialog] = React.useState({});
  const [createSchedule] = useMutation(CREATE_SCHEDULE);

  // Data
  const result = useQuery(GET_SCHEDULE_BASE_DATA);

  const [currentDate, setCurrentDate] = React.useState(null);
  const calendarRef = React.useRef(null);

  useEffect(() => {
    // Set up the calendar.
    createCalendar();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Set clients.
    if (result?.data?.getAllClient) setClients(result.data.getAllClient);

    // Set staff.
    if (result?.data?.getAllStaff) setStaff(result.data.getAllStaff);

    // Set jobs.
    if (result?.data?.getAllJob) setJobs(result.data.getAllJob);
  }, [result]);

  const getEvents = (info, successCallback) => {
    apolloClient
        .query({
          // todo use the variables start or end.
          query: GET_ALL_SCHEDULES,
          fetchPolicy: "no-cache"
        })
        .then(x => {
          const result = x.data.getAllSchedule;

          const mapUnique = (array, duplicates) => {
            return array.map(e => ({
              title: e.shortDescription,
              start: e.dateTimeStart,
              end: e.dateTimeEnd,
              id: e._id,
              extendedProps: {
                ...e,
                hide:
                    duplicates.includes(e._id) && e.recurrence !== "ONE_OFF"
                        ? result.filter(z => duplicates.includes(z._id) && z.recurrence === "ONE_OFF").map(z => z.dateTimeStart)
                        : [],
                isSub: duplicates.includes(e._id) && e.recurrence === "ONE_OFF"
              },
              className: e.colour,
              ...getRecurrence(e)
            }));
          };

          const callbackResult = mapUnique(
              result,
              // Get the duplicate IDs and hide.
              result
                  .map(v => v._id)
                  .filter((v, i, vIds) => vIds.indexOf(v) !== i));

          successCallback(callbackResult);
        });
  }

  const getRecurrence = (schedule) => {
    if (schedule.recurrence && schedule?.recurrence !== "ONE_OFF") {
      if (schedule.recurrence === "FORTNIGHTLY") {
        return {
          // startRecur: schedule.dateTimeStart,
          rrule: {
            freq: 'weekly',
            interval: 2,
            byweekday: [Moment(schedule.dateTimeStart).format("dd")],
            dtstart: schedule.dateTimeStart, // will also accept '20120201T103000'
            //until: '2012-06-01' // will also accept '20120201'
          },
          // daysOfWeek:
          //     (schedule.recurrence === "WEEKLY"
          //         ? [new Date(schedule.dateTimeStart).getDay()]
          //         :  [0, 1, 2, 3, 4, 5, 6]),
          startTime: Moment(schedule.dateTimeStart).format("HH:mm:ss"),
          endTime: Moment(schedule.dateTimeEnd).format("HH:mm:ss")
        };
      }
      // } else {
        return {
          startRecur: schedule.dateTimeStart,
          daysOfWeek:
              (schedule.recurrence === "WEEKLY"
                  ? [new Date(schedule.dateTimeStart).getDay()]
                  :  [0, 1, 2, 3, 4, 5, 6]),
          startTime: Moment(schedule.dateTimeStart).format("HH:mm:ss"),
          endTime: Moment(schedule.dateTimeEnd).format("HH:mm:ss")
        };
      // }

      // return {
      //   startRecur: schedule.dateTimeStart,
      //   daysOfWeek:
      //       (schedule.recurrence === "WEEKLY"
      //           ? [new Date(schedule.dateTimeStart).getDay()]
      //           : schedule.recurrence === "FORTNIGHTLY"
      //               ? [new Date(schedule.dateTimeStart).getDay()]
      //               : [0, 1, 2, 3, 4, 5, 6]),
      //   startTime: Moment(schedule.dateTimeStart).format("HH:mm:ss"),
      //   endTime: Moment(schedule.dateTimeEnd).format("HH:mm:ss")
      // };
    }

    // Either ONE_OFF or an unknown recurrence option.
    return {};
  };

  const handleError = (error) => setAlert(<InfoBox successCallback={setAlert(false)} title="Error" message={error} />);

  const openExistingSchedule = (scheduleEvent) => {
    // If the same, then you are editing the core ("master") schedule.
    let actualStart = scheduleEvent.extendedProps.dateTimeStart;
    let actualEnd = scheduleEvent.extendedProps.dateTimeEnd;
    // check if the event is a "sub document".
    let isMaster = !scheduleEvent.extendedProps.isSub;

    // If our times don't match, we are not editing a master schedule.
    if (!Moment(scheduleEvent.start).isSame(Moment(scheduleEvent.extendedProps.dateTimeStart))) {
      isMaster = false;
      actualStart = scheduleEvent.start;
      // If the event doesn't exist, we need to set the date times from what FullCalendar is telling us.
      actualEnd =
          Moment(scheduleEvent.start)
              .add(
                  Moment(scheduleEvent.extendedProps.dateTimeEnd).diff(Moment(scheduleEvent.extendedProps.dateTimeStart), "milliseconds"),
                  "milliseconds");
    }

    setScheduleDialog({
      ...scheduleEvent.extendedProps,
      startDate: actualStart,
      endDate: actualEnd,
      radios: scheduleEvent.extendedProps.colour,
      isMasterSchedule: isMaster
    });

    setModalAdd(true);
    setCurrentEvent(scheduleEvent);
  };

  const createCalendar = () => {
    schedule = new Calendar(calendarRef.current, {
      plugins: [interaction, dayGridPlugin, rrulePlugin],
      initialView: "dayGridMonth",
      selectable: true,
      editable: true,
      events: (info, successCallback) => getEvents(info, successCallback),
      eventDidMount: (event) => {
        // If event doesn't exist, we don't want to run the below.
        if (!event.event?.extendedProps?.hide) return;

        // Hide element if there is a one_off for it already.
        if (event.event.extendedProps?.hide.filter(x => Moment(x).isSame(event.event.start)).length > 0 || event.event?.extendedProps?.isArchived) {
          event.el.style.display = "none";
        }
      },
      headerToolbar: "",
      // Add new event
      select: (info) => {
        setScheduleDialog({
          startDate: info.start,
          endDate: info.end,
          radios: "bg-info",
          staffIds: [],
          shortDescription: "",
          recurrence: "ONE_OFF",
          isMasterSchedule: true
        });

        setModalAdd(true);
      },
      eventDrop:({ event }) => {
        let updatedEventData = { ...event.extendedProps };

        delete updatedEventData._id;
        delete updatedEventData.__typename;
        delete updatedEventData.hide;
        delete updatedEventData.isSub;

        updatedEventData.dateTimeStart = Moment(event.start).set({hour: Moment(updatedEventData.dateTimeStart).hour, minute: Moment(updatedEventData.dateTimeStart).minute});
        updatedEventData.dateTimeEnd = Moment(event.end).set({hour: Moment(updatedEventData.dateTimeEnd).hour, minute: Moment(updatedEventData.dateTimeEnd).minute});

        // When dragging and drop, it is never the master schedule. todo rethink this.
        apolloClient
            .mutate({
              variables: { id: event.id, data: updatedEventData, isEditingMaster: false },
              mutation: UPDATE_SCHEDULE })
            .then(x => {
              console.log(x);
            })
            .catch(x => handleError(x.toString()));
      },
      // Edit scheduleView event action
      eventClick: ({ event }) => {
        openExistingSchedule(event);
      }
    });
    schedule.render();
    setCurrentDate(schedule.view.title);
  };

  const changeView = (newView) => {
    schedule.changeView(newView);
    setCurrentDate(schedule.view.title);
  };

  const addNewSchedule = (scheduleData) => {
    const data = {
      ...scheduleData,
      dateTimeStart: scheduleData.startDate,
      dateTimeEnd: scheduleData.endDate,
      //shortDescription: scheduleDialog.shortDescription,
      colour: scheduleData.radios,
    };

    const id = data._id;

    // Everything the mutation doesn't care about.
    delete data.isMasterSchedule;
    delete data.radios;
    delete data.startDate;
    delete data.endDate;
    delete data.isSub;
    // We don't want to update with an _id.
    delete data._id;
    delete data.hide;
    delete data.__typename;

    if (id) {
      apolloClient
          .mutate({
            variables: { id: id, data: data, isEditingMaster: scheduleDialog.isMasterSchedule },
            mutation: UPDATE_SCHEDULE })
          .then(() => {
            // Remove any existing so we can recreate it.
            if (currentEvent) currentEvent.remove();

            schedule.addEvent({
              title: data.shortDescription,
              start: data.dateTimeStart,
              end: data.dateTimeEnd,
              id: id,
              extendedProps: {...data, isSub: !scheduleDialog.isMasterSchedule, _id: id },
              className: data.colour
            });

            setCurrentEvent(null);
            setScheduleDialog({});
            setModalAdd(false);
          })
          .catch(x => handleError(x.toString()));
    } else {
      createSchedule({variables: {data}})
          .then(result => {
            data._id = result.data.createSchedule._id;

            schedule.addEvent({
              title: data.shortDescription,
              start: data.dateTimeStart,
              end: data.dateTimeEnd,
              id: data._id,
              extendedProps: data,
              className: data.colour,
              ...getRecurrence(data)
            });

            setScheduleDialog({});
            setModalAdd(false);
          });
    }
  };

  const deleteScheduleAction = () => {
    if (!scheduleDialog._id) return;

    apolloClient
        .mutate({
          variables: { id: scheduleDialog._id, date: scheduleDialog.startDate, isEditingMaster: scheduleDialog.isMasterSchedule },
          mutation: DELETE_SCHEDULE
        })
        .then((x) => {
          if (x.data.deleteSchedule && currentEvent) {
            currentEvent.remove();
          } else if (!currentEvent) {
            handleError("Unable to delete schedule. Schedules before today are not editable.");
          }
        })
        .catch(x => handleError(x.toString()));
  };

  const deleteSchedule = () => {
    // Hide the modal if open.
    setModalAdd(false);

    // Method for deleting schedule.
    const deleteScheduleCallback = () => {
      setAlert(null);

      deleteScheduleAction();
    };

    // Show the alert.
    setAlert(
        <ConfirmationBox
            successCallback={() => deleteScheduleCallback()}
            cancelCallback={() => setAlert(null)}
            message={"Are you sure you want to delete this schedule?"}
            type={'warning'}
            title='Delete schedule' />
    );
  };

  return (
    <>
      {alert}
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={12} lg={6}>
              <Typography
                variant="h2"
                component="h6"
                className={clsx(
                  classes.displayInlineBlock,
                  classes.mb0,
                  classes.textWhite
                )}
              >
                {currentDate}
              </Typography>
              <Breadcrumbs
                separator="-"
                aria-label="breadcrumb"
                classes={{
                  root: classes.breadcrumbRoot,
                  li: classes.breadcrumbLi,
                  ol: classes.breadcrumbOl,
                  separator: classes.breadcrumbSeparator,
                }}
              >
                <Link
                  color="inherit"
                  href="/"
                  onClick={(e) => e.preventDefault()}
                >
                  <Box
                    component={Home}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    position="relative"
                  />
                </Link>
                <Link
                  color="inherit"
                  to="/admin/dashboard"
                  href="/admin/dashboard"
                >
                  Calendar
                </Link>
                <Typography
                  component="span"
                  className={classes.breadcrumbActive}
                >
                  {currentDate}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.textLgRight}>
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={() => {
                  schedule.prev();
                  setCurrentDate(schedule.view.title);
                }}
              >
                <NavigateBefore />
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={() => {
                  schedule.next();
                  setCurrentDate(schedule.view.title);
                }}
              >
                <NavigateNext />
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={() => changeView("dayGridMonth")}
              >
                Month
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={() => changeView("dayGridWeek")}
              >
                Week
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={() => changeView("dayGridDay")}
              >
                Day
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0}>
              <CardHeader
                className={classes.cardHeader}
                title="Schedule"
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent className={classes.p0}>
                <div
                  className="calendar"
                  data-toggle="scheduleView"
                  id="calendar"
                  ref={calendarRef}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ScheduleEvents
          open={modalEvent}
          onClose={() => setModalEvent(false)}
          scheduleDialog={scheduleDialog}
          baseData={{clients, jobs, staff}}
      />
      <ScheduleDialog
          open={modalAdd}
          onClose={() => setModalAdd(false)}
          scheduleDialog={scheduleDialog}
          baseData={{clients, jobs, staff}}
          onSave={addNewSchedule}
          onDelete={deleteSchedule}
          openEvents={() => { setModalAdd(false); setModalEvent(true); }}
      />
      <ScheduleHistoryDialog
          open={modalHistory}
          onClose={() => { setModalHistory(false); console.log("HERE I AM") }}
          baseData={{clients, jobs, staff}}
          data={history}
      />
    </>
  );
};

export default Schedule;
