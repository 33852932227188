import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import Grid from "@material-ui/core/Grid";
import componentStylesButtons from "../../../../assets/theme/components/button";
import componentStylesForms from "assets/theme/components/forms.js";
import {useQuery} from "@apollo/client";
import {GET_ALL_SCHEDULE_LOGS_BETA} from "../Scheme";
import {Table} from "./Components/Table";

const useStylesForms = makeStyles(componentStylesForms);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

export const Logs = () => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesForms()
    };

    // Data
    const scheduleLogResult = useQuery(GET_ALL_SCHEDULE_LOGS_BETA);
    const [scheduleLogHead, setScheduleLogHead] = useState([]);
    const [logs, setLogs] = useState(null);
    useEffect(() => {
        if (!scheduleLogResult.loading && scheduleLogResult?.data?.getAllScheduleLogBeta && scheduleLogResult.data.getAllScheduleLogBeta[0]) {
            setScheduleLogHead(Object.keys(scheduleLogResult.data.getAllScheduleLogBeta[0]));
            setLogs(scheduleLogResult.data.getAllScheduleLogBeta);
        }
    }, [scheduleLogResult]);

    return (
        <>
            <AlternativeHeader section="Monitoring Logs (beta)" showBack />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={12}>
                        {logs
                            && logs.length >= 1
                            && scheduleLogHead.length >= 1
                            ? <Table data={logs} head={scheduleLogHead.filter(x => x !== "__typename" && x !== "staffId" && x !== "schedule")} /> : <></>}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
