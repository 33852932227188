import { gql } from "@apollo/client";

export const CUSTOMER_ADDRESS_FRAGMENT = gql`
    fragment CustomerAddress on Client {
        address {
            name
            buildingNameOrNumber
            streetName
            postalTown
            county
            postalCode
            countryName
            latitude
            longitude
        }
    }
`;

export const CUSTOMER_SITE_ADDRESS_FRAGMENT = gql`
    fragment SiteAddress on Site {
        address {
            name
            buildingNameOrNumber
            streetName
            postalTown
            county
            postalCode
            countryName
            latitude
            longitude
        }
    }
`;

export const GET_CUSTOMER_QUOTE_BASE_DATA = gql`
    query ($id: ObjectId!, $clientId: ObjectId!) {
        getQuote(id: $id) {
            name
            lines {
                jobId
                hours
                siteId
                quantity
            }
        }

        getUserOrganisation {
            fields
        }

        getClient(id: $clientId) {
            name
            jobs

            sites {
                _id
                name
                jobs
            }
        }

        getAllJob {
            _id
            name
            type
            createdAt
            updatedAt
            chargeLine {
                quantity
                chargeId
            }
        }

        getAllCharge {
            _id
            charge
            cost
        }

#        getClient(id: $clientId) {
#            name
#            jobs
#
#            sites {
#                _id
#                name
#                jobs
#            }
#        }
    }
`;

export const CREATE_CUSTOMER_QUOTE = gql`
    mutation($data: CreateQuoteInput!, $clientId: ObjectId!) {
        createQuote(clientId: $clientId, createQuote: $data) {
            _id
        }
    }
`;

export const UPDATE_CUSTOMER_QUOTE = gql`
    mutation($data: UpdateQuoteInput!, $id: ObjectId!, $clientId: ObjectId!) {
        updateQuote(id: $id, updateQuote: $data, clientId: $clientId) {
            _id
        }
    }
`;

export const GET_BASE_DATA = gql`
    query ($id: ObjectId!) {
        getUserOrganisation {
            fields
        }

        getAllJob {
            _id
            name
            type
            createdAt
            updatedAt
            chargeLine {
                quantity
                chargeId
            }
        }
        
        getAllCharge {
            _id
            charge
            cost
        }
        
        getClient(id: $id) {
            name
            jobs
            
            sites {
                _id
                name
                jobs
            }
        }
    }
`;

export const GET_QUOTES = gql`
    query ($id: ObjectId!) {
        getAllQuote(clientId: $id) {
            _id
            name
        }
    }
`;

export const GET_ALL_CUSTOMERS = gql`
    query {
        getAllClient {
            _id
            name
        }
    }
`;
