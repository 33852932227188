import React, {useMemo, useState} from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import {LinearProgress} from "@material-ui/core";
import {Link, useHistory} from 'react-router-dom';
import {convertTimeToReadable} from "../../../../variables/convertTime";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function StaffTable({data, title, showOvertime, showSalary, quotedHours}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    let history = useHistory();

    let tableHead = useMemo(() => ["Staff Member", "Recorded Hours"], []);
    const tableContainerRef = React.useRef(null);

    if (quotedHours) tableHead = [...tableHead, "Quoted Hours"]
    tableHead = [...tableHead, "Weekly Hours", "% Staff Hours", "Total Cost/Charge", "Total Salary"];

    if (showOvertime) tableHead.push("Overtime");
    tableHead.push("");

    React.useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: tableHead.slice(0, tableHead.length - 1),
            listClass: "list"
        });
    }, [tableHead]);

    const [, setSelectedRow] = useState({});

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={title ? title : "Payroll Overview"}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                ></CardHeader>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "forename" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => {
                                console.log("hob", {
                                total: prop.totalHours, est: prop.estimatedHours, display: prop.displayWeek
                                })
                                return (
                                    <TableRow key={key}>
                                        <TableCell classes={{
                                            root:
                                                classes.tableCellRoot +
                                                " " +
                                                classes.tableCellRootBodyHead,
                                        }}
                                                   component="th"
                                                   variant="head"
                                                   scope="row">
                                            <Box display="flex" alignItems="flex-start">
                                                <Box fontSize=".875rem" component="span">
                                                    <Link to={`/admin/staff/${prop.staffId}`}>{prop.staffName}</Link>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            classes={{root: classes.tableCellRoot}}>{convertTimeToReadable(prop.totalHours / 60)}</TableCell>
                                        {quotedHours && <TableCell
                                            classes={{root: classes.tableCellRoot}}>{convertTimeToReadable(quotedHours)}</TableCell>}
                                        <TableCell
                                            classes={{root: classes.tableCellRoot}}>{convertTimeToReadable(prop.displayWeek)}</TableCell>
                                        <TableCell classes={{root: classes.tableCellRoot}}>
                                            <Box display="flex" alignItems="center">
                                                <Box component="span" marginRight=".5rem">
                                                    {(prop.totalHours / (prop.displayWeek * 60) * 100).toFixed(2)}%
                                                </Box>
                                                <Box width="100%">
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={(prop.totalHours / (prop.displayWeek * 60) * 100)}
                                                        classes={{
                                                            root: classes.linearProgressRoot,
                                                            bar: classes[prop.totalHours >= (prop.estimatedHours / 2) ? "bgSuccess" : "bgError"],
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{root: classes.tableCellRoot}}>
                                            <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                <span
                                                    className="status">£{Number(prop.totalCost).toFixed(2)}/£{Number(prop.totalCharge).toFixed()} ({-((prop.totalCost - prop.totalCharge) / prop.totalCost * 100)}%)</span>
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{root: classes.tableCellRoot}}>
                                            <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                £{prop.salary.toFixed(2)} (£{prop.displaySalary.toFixed(2)} per hr)
                                            </Box>
                                        </TableCell>
                                        {showOvertime && <TableCell classes={{root: classes.tableCellRoot}}>
                                            <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                <span
                                                    className="status">£{(showSalary ? prop.salary : prop.weeklySalary || 0).toFixed(2)}</span>
                                            </Box>
                                        </TableCell>}
                                        <TableCell classes={{root: classes.tableCellRoot}} align="right">
                                            <TableDropdown id={key} actions={[
                                                {
                                                    text: "View Details",
                                                    handle: () => history.push(`/admin/staff/${prop.staffId}`)
                                                },
                                                {
                                                    text: "Approve",
                                                    handle: () => setSelectedRow(prop),
                                                    style: {color: "green"}
                                                },
                                                {
                                                    text: "Adjust",
                                                    handle: () => setSelectedRow(prop),
                                                    style: {color: "red"}
                                                }
                                            ]}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
        </>
    );
}
