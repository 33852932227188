import { gql } from "@apollo/client";

export const UPDATE_SETTINGS = gql`
    mutation($data: UpdateUserOrganisationInput!) {
        updateUserOrganisation(updateUserOrganisation: $data) {
            _id
        }
    }
`;

export const RESET_AGGREGATION = gql`
    mutation {
        resetAggregation
    }
`;

export const DELETE_ALL_AGGREGATION = gql`
    mutation {
        deleteAggregationAndScheduleData
    }
`;

export const CREATE_SETTINGS = gql`
    mutation($data: CreateOrganisationInput!) {
        createOrganisation(createOrganisation: $data) {
            _id
        }
    }
`;

export const GET_SETTINGS = gql`
    query {
        getUserOrganisation {
            name
            address {
                name
                buildingNameOrNumber
                streetName
                postalTown
                county
                postalCode
                countryName
                latitude
                longitude
            }
            billingAddress {
                name
                buildingNameOrNumber
                streetName
                postalTown
                county
                postalCode
                countryName
                latitude
                longitude
            }
            contact
            lateThresholdMinutes
            earlyThresholdMinutes
            fields
            propertyTypes
            roomTypes
            staffSkillTypes
            welcomeTemplate
            scheduleTemplate
        }
    }
`;
