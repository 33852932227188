import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOMERS_WITH_AGG } from "./Scheme"
import {Card, CardHeader} from '@material-ui/core';

const useStyles = makeStyles(componentStyles);

const AuthorizationInvoice = () => {
    const classes = useStyles();
    const { loading, data } = useQuery(GET_ALL_CUSTOMERS_WITH_AGG);

    return (
        <>
            <AlternativeHeader section="Invoice Authorization" />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading
                    && data?.getAllClient?.length > 0
                    && data?.getAllClient?.filter(x => x.aggregations.length > 0).length > 0
                    // ? <ClientTable data={data.getAllClient} />
                    // :
                    && <Card classes={{ root: classes.cardRoot }}>
                        <CardHeader
                            className={classes.cardHeader}
                            title="tbc"
                            titleTypographyProps={{
                                component: Box,
                                marginBottom: "0 !important",
                                variant: "h3",
                            }}
                        ></CardHeader>
                    </Card>}
            </Container>
        </>
    );
};

export default AuthorizationInvoice;
