import React, {useEffect, useState} from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import {CardContent} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function LineTable({data, title, showCount = true, useObject = false, showHead = false}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    const [tableHead, setTableHead] = useState({});

    useEffect(() => {
        if (!showCount && useObject && data) {
            setTableHead(Object.keys(data[0]));
        } else if (!showCount && !useObject) {
            setTableHead(["name", "total", "value", "staff"]);
        } else if (showCount) {
            setTableHead(["name", "count", "total", "value"]);
        }
    }, [showCount, data, useObject]);

    // const tableHead = showCount ? ["name", "count", "total", "value"] : useObject ? Object.keys(data[0]) : ["name", "total", "value", "staff"];
    const tableContainerRef = React.useRef(null);

    React.useEffect(() => {
        if (tableHead.length >= 1) {
            new List(tableContainerRef.current, {
                valueNames: tableHead.slice(0, tableHead.length - 1),
                listClass: "list"
            });
        }
    }, [tableHead]);

    // If there is no data, return an empty list.
    let tableData = data && data.length > 0 ? data : null;

    const oldStyleRow = (line, key) => <TableRow key={key}>
        <TableCell classes={{
            root:
                classes.tableCellRoot +
                " " +
                classes.tableCellRootBodyHead,
        }}
                   component="th"
                   variant="head"
                   scope="row">
            <Box display="flex" alignItems="flex-start">
                <Box fontSize=".875rem" component="span">
                    {line.taskName}
                </Box>
            </Box>
        </TableCell>

        {showCount && <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">
                {line.taskCount}
            </Box>
        </TableCell>}

        <TableCell classes={{ root: classes.tableCellRoot }}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">
                {line.taskTotal}
            </Box>
        </TableCell>

        <TableCell classes={{ root: classes.tableCellRoot }}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">
                {line.taskValue}
            </Box>
        </TableCell>

        {!showCount && <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">
                {line.staff}
            </Box>
        </TableCell>}
    </TableRow>;

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={title}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                ></CardHeader>
                {tableData && <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        {showHead && tableHead.length > 1 && <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>}
                        <TableBody className="list">
                            {tableData.map((line, key) => !useObject ? oldStyleRow(line, key) : (
                                <TableRow>
                                    {Object.keys(line).map((key) => <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                                            {line[key]}
                                        </Box>
                                    </TableCell>)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>}
                {!tableData &&
                    <CardContent><Grid container>
                        <Grid item xs={12} md={12}>
                            <Box component={"p"} textAlign={"center"} marginBottom={0}>
                                There is not available data to display here.
                            </Box>
                        </Grid>
                    </Grid></CardContent>}
            </Card>
        </>
    );
}
