import React, {useCallback, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import {useLazyQuery, useQuery} from '@apollo/client';
import EstimationsTable from "./Components/EstimationsTable";
import { GET_ALL_CUSTOMERS, GET_QUOTES } from "./Scheme";
import { Button, Select } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

const Estimations = (props) => {
    const classes = useStyles();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const { loading: customersLoading, data: customers } = useQuery(GET_ALL_CUSTOMERS, { onCompleted: results => filterCustomer(results.getAllClient[0]._id)});
    const [search, { data: quotes, loading: quotesLoading }] = useLazyQuery(GET_QUOTES, { variables: { id: '' }});
    const history = useHistory();

    const navigateToCustomerEstimate = () => history.push(`/admin/estimations/add/${selectedCustomer || customers.getAllClient[0]._id || ""}`);
    const navigateQuoteEdit = (id) => history.push(`/admin/estimations/edit/${selectedCustomer || customers.getAllClient[0]._id || ""}/${id}`);

    const filterCustomer = useCallback(
        (id) => {
            setSelectedCustomer(id);
            search({ variables: { id } })
                .then(x => console.log(x))
                .catch(x => console.log(x));
        },
        [search]
    );

    return (
        <>
            <AlternativeHeader
                section="Estimations"
                customAction={!customersLoading && customers?.getAllClient &&
                <>
                    {customers.getAllClient[0]?._id
                        ? (
                            <>
                                <FormControl variant="outlined" size={"small"}>
                                    <Select IconComponent={KeyboardArrowDown} defaultValue={customers.getAllClient[0]?._id || ""} onChange={x => filterCustomer(x.target.value)}>
                                        {customers.getAllClient.map(c => <MenuItem value={c._id}>{c.name}</MenuItem>)}
                                    </Select>
                                </FormControl>

                                <Button onClick={() => navigateToCustomerEstimate()} variant="outlined" size="medium" className={classes.buttonRoot}>New</Button>
                            </>
                        ) : (
                            <p>
                                A customer is required before creating an estimation.
                            </p>
                        )
                    }
                </>
                } />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!quotesLoading && quotes?.getAllQuote && quotes?.getAllQuote.length > 0 && <EstimationsTable data={quotes.getAllQuote} props={props} handleEdit={(id) => navigateQuoteEdit(id)} />}
            </Container>
        </>
    );
};

export default Estimations;
