import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "../../assets/theme/hex-to-rgb";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Snackbar from "@material-ui/core/Snackbar";
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/views/admin/sortable";
import componentStylesSnackbar from "../../assets/theme/components/snackbar";

const useStyles = makeStyles(componentStyles);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);

export default function SuccessSnackbar({ successText, closeCallback }) {
    const classes = {
        ...useStyles(),
        ...useStylesSnackbar
    };

    const theme = useTheme();

    return <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successText?.length > 0 || false}
        autoHideDuration={2000}
        onClose={closeCallback}
        ContentProps={{
            classes: { root: classes.successSnackbar },
            elevation: 1,
        }}
        action={
            <Box component={IconButton} padding="0!important" onClick={closeCallback}>
                <Box component="span" color={ "rgba(" + hexToRgb(theme.palette.white.main) + ",.6)" }>
                    ×
                </Box>
            </Box>
        }
        message={
            <>
                <Box fontSize="1.25rem" display="flex" marginRight="1.25rem" alignItems="center">
                    <Box component={ThumbUp} width="1.25rem!important" height="1.25rem!important" />
                </Box>
                <Box component="span">
                    <Box component="strong" marginRight=".5rem">Success!</Box>
                    {successText}
                </Box>
            </>
        }
    />;
}