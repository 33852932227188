import Dashboard from "views/admin/Dashboard.js";
import Schedule from "views/admin/Schedule/Schedule";
import Dns from "@material-ui/icons/Dns";
import Event from "@material-ui/icons/Event";
import FlashOn from "@material-ui/icons/FlashOn";
import House from "@material-ui/icons/House";
import BarChart from "@material-ui/icons/BarChart"
import ListAlt from "@material-ui/icons/ListAlt";
import PermMedia from "@material-ui/icons/PermMedia";
import TextFields from "@material-ui/icons/TextFields";
import Staff from "./views/admin/Management/Staff/Staff";
import Customers from "./views/admin/Management/Customers/Customers";
import AddStaff from "./views/admin/Management/Staff/AddStaff";
import AddCustomer from "./views/admin/Management/Customers/AddCustomer";
import Charges from "./views/admin/Management/Charges/Charges";
import AddCharge from "./views/admin/Management/Charges/AddCharge";
import Jobs from "./views/admin/Management/Jobs/Jobs";
import AddJob from "./views/admin/Management/Jobs/AddJob";
import Monitoring from "./views/admin/Monitoring/Monitoring";
import Settings from "./views/admin/Settings/Settings";
import Authorization from "./views/admin/Authorization/Authorization";
import AuthorizationClient from './views/admin/Authorization/AuthorizationClient';
import AuthorizationSite from './views/admin/Authorization/AuthorizationSite';
import AuthorizationPayroll from './views/admin/Authorization/AuthorizationPayroll';
import AuthorizationInvoice from './views/admin/Authorization/AuthorizationInvoice';
import AuthorizationJob from "./views/admin/Authorization/AuthorizationJob";
import Estimations from "./views/admin/Estimations/Estimations";
import AddEstimation from "./views/admin/Estimations/AddEstimation";
import AuthorizationSchedule from "./views/admin/Authorization/AuthorizationSchedule";
import {Logs} from "./views/admin/Monitoring/Logs/Logs";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: House,
    iconColor: "Primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/schedule",
    name: "Schedule",
    icon: Event,
    iconColor: "Error",
    component: Schedule,
    layout: "/admin",
  },
  {
    path: "/schedule/history/:id",
    name: "Schedule History",
    icon: Event,
    iconColor: "Error",
    component: Schedule,
    layout: "/admin",
    hide: true
  },
  {
    collapse: true,
    name: "Management",
    icon: PermMedia,
    iconColor: "Warning",
    state: "examplesCollapse",
    views: [
      {
        path: "/staff",
        name: "Staff",
        miniName: "S",
        component: Staff,
        layout: "/admin",
      },
      {
        path: "/staff/:staffId",
        name: "Add Staff Member",
        component: AddStaff,
        layout: "/admin",
        hide: true
      },
      {
        path: "/charges",
        name: "Charges",
        miniName: "$",
        component: Charges,
        layout: "/admin"
      },
      {
        path: "/charge/:chargeId",
        name: "Add Charge",
        component: AddCharge,
        layout: "/admin",
        hide: true
      },
      {
        path: "/jobs",
        name: "Jobs",
        miniName: "J",
        component: Jobs,
        layout: "/admin",
      },
      {
        path: "/job/:jobId",
        name: "Add Job",
        component: AddJob,
        layout: "/admin",
        hide: true
      },
      {
        path: "/customers",
        name: "Customers",
        miniName: "C",
        component: Customers,
        layout: "/admin",
      },
      {
        path: "/customer/:customerId",
        name: "Add Customer",
        component: AddCustomer,
        layout: "/admin",
        hide: true
      }
    ],
  },
  {
    path: "/estimations",
    name: "Estimations",
    icon: TextFields,
    iconColor: "Error",
    component: Estimations,
    layout: "/admin",
  },
  {
    path: "/estimations/add/:customerId",
    name: "Add Estimation",
    icon: TextFields,
    iconColor: "Error",
    component: AddEstimation,
    layout: "/admin",
    hide: true
  },
  {
    path: "/estimations/edit/:customerId/:id",
    name: "Edit Estimation",
    icon: TextFields,
    iconColor: "Error",
    component: AddEstimation,
    layout: "/admin",
    hide: true
  },
  {
    path: "/monitoring",
    name: "Monitoring",
    icon: Dns,
    iconColor: "Info",
    component: Monitoring,
    layout: "/admin",
  },
  {
    path: "/monitoring/logs",
    name: "Monitoring Logs (beta)",
    icon: Dns,
    iconColor: "Info",
    component: Logs,
    layout: "/admin",
    hide: true
  },
  {
    path: "/authorization",
    name: "Authorization",
    icon: ListAlt,
    iconColor: "ErrorLight",
    component: Authorization,
    layout: "/admin",
  },
  {
    path: "/authorization/:clientId/:date",
    name: "Customer Details",
    icon: ListAlt,
    component: AuthorizationClient,
    layout: "/admin",
    hide: true
  },
  {
    path: "/authorization/:clientId/:jobId/:date",
    name: "Job Details",
    icon: ListAlt,
    component: AuthorizationJob,
    layout: "/admin",
    hide: true
  },
  {
    path: "/authorization/:clientId/:date/schedule/:scheduleId",
    name: "Schedule Details",
    icon: ListAlt,
    component: AuthorizationSchedule,
    layout: "/admin",
    hide: true
  },
  {
    path: "/authorization/:clientId/:date/site/:siteId",
    name: "Site Details",
    icon: ListAlt,
    component: AuthorizationSite,
    layout: "/admin",
    hide: true
  },
  {
    path: "/authorization/invoice",
    name: "Invoices",
    icon: ListAlt,
    component: AuthorizationInvoice,
    layout: "/admin",
  },
  {
    path: "/payroll",
    name: "Payroll",
    icon: BarChart,
    iconColor: "Primary",
    component: AuthorizationPayroll,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: House,
    iconColor: "Primary",
    component: Settings,
    layout: "/admin",
  },
  {
    divider: true,
  },
  {
    title: "Documentation",
  },
  {
    href:
      "https://romasystems.co.uk/",
    name: "Getting started",
    icon: FlashOn,
  }
];
export default routes;
