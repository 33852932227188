import React, {useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextFields from "@material-ui/icons/TextFields";
import Alarm from "@material-ui/icons/Alarm";
import { InputLabel } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import {CREATE_SETTINGS, DELETE_ALL_AGGREGATION, GET_SETTINGS, RESET_AGGREGATION, UPDATE_SETTINGS} from "./Scheme";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "../../../assets/theme/hex-to-rgb";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Snackbar from "@material-ui/core/Snackbar";
import componentStylesSnackbar from "../../../assets/theme/components/snackbar";
import componentStylesButtons from "../../../assets/theme/components/button";
import componentStylesForms from "assets/theme/components/forms.js";
import AddressForm from "../../../components/AddressForm";
import { useLocation } from "react-router-dom";

const useStylesForms = makeStyles(componentStylesForms);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const Settings = () => {
    const classes = {
        ...useStyles(),
        ...useStylesSnackbar(),
        ...useStylesButtons(),
        ...useStylesForms()
    };
    const theme = useTheme();
    const location = useLocation();

    const isSuperAdmin = location.pathname.indexOf("super-admin") > -1;

    const successSnackbarRootClasses = { root: classes.successSnackbar };

    // Form Variables
    const [name, setName] = useState("");
    const [address, setAddress] = useState({});
    const [billingAddress, setBillingAddress] = useState({});
    const [email, setEmail] = useState("");
    const [fields, setFields] = useState([]);
    const [scheduleTemplate, setScheduleTemplate] = useState("");
    const [welcomeTemplate, setWelcomeTemplate] = useState("");
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);
    const [staffSkillTypes, setStaffSkillTypes] = useState([]);

    // Super Admin Variables
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountHolderEmail, setAccountHolderEmail] = useState("");

    // Thresholds
    const [lateThresholdMinutes, setLateThresholdMinutes] = useState(null);
    const [earlyThresholdMinutes, setEarlyThresholdMinutes] = useState(null);

    const [successText, setSuccessText] = useState("");

    const settings = useQuery(GET_SETTINGS);
    const [updateSettings] = useMutation(UPDATE_SETTINGS);
    const [createSettings] = useMutation(CREATE_SETTINGS);
    const [resetAggregation] = useMutation(RESET_AGGREGATION);
    const [deleteAggregations] = useMutation(DELETE_ALL_AGGREGATION);

    const update = (isSuperAdmin, options) => isSuperAdmin ? createSettings(options) : updateSettings(options);

    const handleForm = (e) => {
        e.preventDefault();

        let data = {
            name,
            address: address.postalCode ? { ...address, countryName: "UK" } : null,
            billingAddress: billingAddress.postalCode ? { ...billingAddress, countryName: "UK" } : null,
            contact: email,
            fields,
            earlyThresholdMinutes: Number(earlyThresholdMinutes),
            lateThresholdMinutes: Number(lateThresholdMinutes),
            propertyTypes,
            roomTypes,
            welcomeTemplate,
            scheduleTemplate,
            staffSkillTypes
        };

        if (isSuperAdmin) data = { ...data, accountHolderEmail, accountHolderName };

        if (address.__typename) delete data.address.__typename;
        if (billingAddress.__typename) delete data.billingAddress.__typename;

        update(isSuperAdmin, { variables: { data } })
            .then(() => setSuccessText("Company Saved!"))
            .catch(() => setSuccessText("Error!"));
    };

    useEffect(() => {
        if (settings?.data?.getUserOrganisation && !isSuperAdmin) {
            const result = settings?.data?.getUserOrganisation;

            setName(result.name);
            setEmail(result.contact);
            setLateThresholdMinutes(result.lateThresholdMinutes);
            setEarlyThresholdMinutes(result.earlyThresholdMinutes);
            setFields(result.fields ?? []);
            setAddress(result.address ?? {});
            setBillingAddress(result.billingAddress ?? {});
            setPropertyTypes(result.propertyTypes);
            setRoomTypes(result.roomTypes);
            setStaffSkillTypes(result.staffSkillTypes);
            setWelcomeTemplate(result.welcomeTemplate);
            setScheduleTemplate(result.scheduleTemplate);
        }
    }, [settings, isSuperAdmin]);

    return (
        <>
            <form onSubmit={handleForm}>
            <AlternativeHeader section="Settings" showSave />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Basics"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <InputLabel>Company Name</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="Company Name"
                                                value={name}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setName(x.target.value)}
                                            />
                                            <Box component="p" marginBottom="0" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                The company name is displayed to your staff and external companies.
                                            </Box>
                                        </FormGroup>
                                    </Grid>

                                    {isSuperAdmin &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <FormGroup>
                                                    <InputLabel>Account Holder Name</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        type="text"
                                                        placeholder="e.g., John Smith"
                                                        value={accountHolderName}
                                                        onChange={x => setAccountHolderName(x.target.value)}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                                <Grid item xs={12} md={6}>
                                                <FormGroup>
                                                    <InputLabel>Account Holder Email</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        type="text"
                                                        placeholder="e.g., john@smith.co.uk"
                                                        value={accountHolderEmail}
                                                        onChange={x => setAccountHolderEmail(x.target.value)}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={4} md={4}>
                                        <FormGroup>
                                            <InputLabel>Late Threshold</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., 15"
                                                value={lateThresholdMinutes}
                                                startAdornment={<InputAdornment position="start"><Alarm /></InputAdornment>}
                                                onChange={x => setLateThresholdMinutes(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={4} md={4}>
                                        <FormGroup>
                                            <InputLabel>Early Threshold</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., 15"
                                                value={earlyThresholdMinutes}
                                                startAdornment={<InputAdornment position="start"><Alarm /></InputAdornment>}
                                                onChange={x => setEarlyThresholdMinutes(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={4} md={4}>
                                        <FormGroup>
                                            <InputLabel>Notification Mode</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                disabled
                                                value={"App"}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box component="p" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="0" marginBottom="20px" paddingTop={'0'} textAlign={"left"}>
                                            Thresholds are used for reporting and notifications. The mode is the available options on the account.
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Contact Email</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="Contact Email"
                                                value={email}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setEmail(x.target.value)}
                                            />
                                            <Box component="p" marginBottom="0" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                The contact/email address used for service updates and billing.
                                            </Box>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Custom Fields"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Fields</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., Contact Name,Contact Address"
                                                value={fields.join(",")}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setFields(x.target.value.split(","))}
                                            />
                                            <Box component="p" marginBottom="0" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                Currently only supports text inputs. Each field should be comma separated. For example, field1, field2, field3. Will produce three fields each with a name field 1, 2, and 3.
                                            </Box>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Templates"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Welcome Template</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                multiline
                                                type="text"
                                                value={welcomeTemplate}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setWelcomeTemplate(x.target.value)}
                                            />
                                            <Box component="p" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" marginBottom={"20px"} paddingTop={'10px'} textAlign={"left"}>
                                                Template for welcome message/email. This message/email is used when new staff are invited to the platform.
                                            </Box>

                                            <InputLabel>Schedule Template</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                multiline
                                                type="text"
                                                value={scheduleTemplate}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setScheduleTemplate(x.target.value)}
                                            />
                                            <Box component="p" marginBottom="0" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                Template for schedule reminder message/email. This message/email is used to let staff know about schedules they are assigned to and upcoming schedules.
                                            </Box>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Reset Transient Data"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{marginBottom: 0}}>
                                            <OutlinedInput
                                                fullWidth
                                                type="button"
                                                value="Reset Aggregations"
                                                color="secondary"
                                                onClick={() => resetAggregation().then(() => setSuccessText("Aggregations have been reset."))}
                                            />
                                            <Box component="p" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" marginBottom={"20px"} paddingTop={'10px'} textAlign={"left"}>
                                                Old log data will be re-inputted to the aggregations.
                                            </Box>

                                            <OutlinedInput
                                                fullWidth
                                                type="button"
                                                value="Delete All Logs & Aggregations"
                                                color="secondary"
                                                onClick={() => deleteAggregations().then(() => setSuccessText("Data has been deleted."))}
                                            />
                                            <Box component="p" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" marginBottom={"20px"} paddingTop={'10px'} textAlign={"left"}>
                                                Resets all log data, like schedule logs.
                                            </Box>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Address"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <AddressForm
                                    address={address}
                                    setCallback={(v) => {
                                        setAddress(v)
                                    }}
                                />
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Billing Address"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <AddressForm
                                    address={billingAddress}
                                    setCallback={(v) => {
                                        setBillingAddress(v)
                                    }}
                                />
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Types"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Property Types</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., House, Cottage"
                                                value={propertyTypes.join(",")}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setPropertyTypes(x.target.value.split(","))}
                                            />
                                            <Box component="p" marginBottom="20px" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                Define property types to help breakdown jobs.
                                            </Box>
                                        </FormGroup>

                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Room Types</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., Bedroom, Bathroom"
                                                value={roomTypes.join(",")}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setRoomTypes(x.target.value.split(","))}
                                            />
                                            <Box component="p" marginBottom="20px" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                Define room types to help breakdown jobs.
                                            </Box>
                                        </FormGroup>

                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Staff Skill Types</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., Flying, Eye Lasers"
                                                value={staffSkillTypes.join(",")}
                                                startAdornment={<InputAdornment position="start"><TextFields /></InputAdornment>}
                                                onChange={x => setStaffSkillTypes(x.target.value.split(","))}
                                            />
                                            <Box component="p" marginBottom="20px" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                Define staff types to categorise staff and understand resource constraints.
                                            </Box>
                                        </FormGroup>

                                        <FormGroup style={{marginBottom: 0}}>
                                            <InputLabel>Area Unit</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                disabled
                                                placeholder="e.g., Metre"
                                                value={"Metre"}
                                            />
                                            <Box component="p" marginBottom="0" fontWeight="300" lineHeight="1.5" fontSize="0.8rem" marginTop="10px" paddingTop={'10px'} textAlign={"left"}>
                                                The unit used for calculations and presentation.
                                            </Box>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            </form>
            {/* Notification */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={successText.length > 0}
                autoHideDuration={2000}
                onClose={() => setSuccessText("")}
                ContentProps={{
                    classes: successSnackbarRootClasses,
                    elevation: 1,
                }}
                action={
                    <Box component={IconButton} padding="0!important" onClick={() => setSuccessText("")}>
                        <Box component="span" color={ "rgba(" + hexToRgb(theme.palette.white.main) + ",.6)" }>
                            ×
                        </Box>
                    </Box>
                }
                message={
                    <>
                        <Box
                            fontSize="1.25rem"
                            display="flex"
                            marginRight="1.25rem"
                            alignItems="center"
                        >
                            <Box component={ThumbUp} width="1.25rem!important" height="1.25rem!important" />
                        </Box>
                        <Box component="span">
                            <Box component="strong" marginRight=".5rem">Success!</Box>
                            {successText}
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default Settings;
