import React, {useEffect, useState} from "react";
import { FormControl } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export const CustomFieldsAggregator = (customFields, fieldsFromOrganisation) => {
    if (!customFields || customFields.length < 1) {
        console.log(fieldsFromOrganisation.map((f) => ({property: f, value: "", type: "string"})));
        return fieldsFromOrganisation.map((f) => ({property: f, value: "", type: "string"}));
    }

    return [...customFields, ...fieldsFromOrganisation.filter(f => !customFields.find(z => z.property === f)).map(f => ({
        property: f,
        value: "",
        type: "string"
    }))];
};

export default function CustomFields({initialValue = [], valueCallback}) {
    const [fieldValues, setFieldValues] = useState(initialValue);
    const handleCustomFields = (property, value) => {
        const values = [
            ...fieldValues.filter(f => f.property !== property),
            {property, value, type: "string"}
        ];

        setFieldValues(values);
        valueCallback(values);

        return value;
    };

    useEffect(() => setFieldValues(initialValue), [initialValue]);

    return (
            <Grid container spacing={3}>
                {fieldValues && fieldValues.length >= 1
                    && fieldValues.map(field => (
                        <Grid item sm={12}>
                            <FormControl key={field.property} fullWidth>
                                <TextField
                                    label={field.property}
                                    value={field.value}
                                    variant="outlined"
                                    onChange={event => handleCustomFields(field.property, event.target.value)} />
                            </FormControl>
                        </Grid>
                    ))}
                <Grid item sm={12}>
                    <Box
                        component="p"
                        marginBottom="0"
                        fontWeight="300"
                        lineHeight="1.5"
                        fontSize="0.8rem"
                        textAlign={"center"}
                    >
                        Use Custom Fields to easily organise and store information!
                    </Box>
                </Grid>
            </Grid>
    );
}
