import { gql } from "@apollo/client";

export const CUSTOMER_ADDRESS_FRAGMENT = gql`
    fragment CustomerAddress on Client {
        address {
            name
            buildingNameOrNumber
            streetName
            postalTown
            county
            postalCode
            countryName
            latitude
            longitude
            travelTime
        }
    }
`;

export const CUSTOMER_SITE_ADDRESS_FRAGMENT = gql`
    fragment SiteAddress on Site {
        address {
            name
            buildingNameOrNumber
            streetName
            postalTown
            county
            postalCode
            countryName
            latitude
            longitude
            travelTime
        }
    }
`;

export const CREATE_CUSTOMER = gql`
    mutation($data: CreateClientInput!) {
        createClient(createClient: $data) {
            _id
        }
    }
`;

export const DELETE_CUSTOMER = gql`
    mutation ($id: ObjectId!) {
        deleteClient(id: $id)
    }
`;

export const CREATE_CUSTOMER_SITE = gql`
    mutation($data: CreateSiteInput!, $clientId: ObjectId!) {
        createSite(createSite: $data, clientId: $clientId) {
            _id
            name
            notes
            ...SiteAddress
        }
    }
    
    ${CUSTOMER_SITE_ADDRESS_FRAGMENT}
`;

export const UPDATE_CUSTOMER_SITE = gql`
    mutation($data: UpdateSiteInput!, $clientId: ObjectId!, $id: ObjectId!) {
        updateSite(updateSite: $data, clientId: $clientId, id: $id) {
            _id
            name
            notes
            updatedAt
            ...SiteAddress
        }
    }
    ${CUSTOMER_SITE_ADDRESS_FRAGMENT}
`;

export const UPDATE_CUSTOMER = gql`
    mutation($id: ObjectId!, $data: UpdateClientInput!) {
        updateClient(id: $id, updateClient: $data) {
            _id
            name
            siteCount
            updatedAt
        }
    }
`;

export const GET_BASE_DATA = gql`
    query {
        getUserOrganisation {
            fields
        }

        getAllJob {
            _id
            name
            type
            createdAt
            updatedAt
        }
    }
`

export const GET_CUSTOMER_BY_ID = gql`
    query ($id: ObjectId!) {
        getUserOrganisation {
            fields
        }

        getAllJob {
            _id
            name
            type
            createdAt
            updatedAt
        }
        
        getClient(id: $id) {
            _id
            name
            email
            phone
            reference
            notes
            jobs
            sites {
                _id
                name
                notes
                jobs
                ...SiteAddress
            }
            customFields {
                property
                value
                type
            }
            
            ...CustomerAddress
        }
    }
    ${CUSTOMER_ADDRESS_FRAGMENT}
    ${CUSTOMER_SITE_ADDRESS_FRAGMENT}
`;

export const GET_ALL_CUSTOMERS = gql`
    query {
        getAllClient {
            _id
            name
            siteCount
            
            address {
                latitude
                longitude
            }
            
            quotes {
                name
            }
        }
    }
`;

export const GET_ALL_CUSTOMERS_WITH_SITES = gql`
    query {
        getAllClient {
            _id
            name
            siteCount
            sites {
                _id
                name
            }
        }
    }
`;
