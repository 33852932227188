import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOMERS_WITH_AGG } from "./Scheme"
import {Card, CardHeader} from '@material-ui/core';
import moment from "moment/moment";
import CardContent from "@material-ui/core/CardContent";
import StaffTable from "./Components/StaffTable";

const useStyles = makeStyles(componentStyles);

const AuthorizationPayroll = () => {
    const week = moment().startOf("isoWeek").format("YYYY-MM-DD");
    const classes = useStyles();
    const { loading, data } = useQuery(GET_ALL_CUSTOMERS_WITH_AGG, {
        variables: { week: week }
    });

    return (
        <>
            <AlternativeHeader section={`Payroll Authorization (starting ${week})`} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading
                    && data?.getAggregations?.weeks[0]?.staff?.length > 0
                    ? <StaffTable data={data.getAggregations.weeks[0].staff} showOvertime />
                    : <Card classes={{ root: classes.cardRoot }}>
                        <CardHeader
                            className={classes.cardHeader}
                            title="Payroll Overview"
                            titleTypographyProps={{
                                component: Box,
                                marginBottom: "0 !important",
                                variant: "h3",
                            }}
                        ></CardHeader>
                        <CardContent>
                            <Box component={"p"} marginBottom={0}>
                                Details will appear as schedule data is aggregated from check-ins and events.
                            </Box>
                        </CardContent>
                    </Card>}
            </Container>
        </>
    );
};

export default AuthorizationPayroll;
