import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import {useMutation, useQuery} from '@apollo/client';
import StaffTable from "./Components/StaffTable";
import { DELETE_STAFF, GET_ALL_STAFF } from "./Scheme"

const useStyles = makeStyles(componentStyles);

const Staff = (props) => {
    const classes = useStyles();
    const { loading, data } = useQuery(GET_ALL_STAFF);
    const [deleteStaff] = useMutation(DELETE_STAFF, { refetchQueries: [{ query: DELETE_STAFF }] });

    const deleteEntity = (id) => {
        deleteStaff({ variables: { id }})
            .then(() => console.log("Success"))
            .catch(x => console.log(x));
    };

    return (
        <>
            <AlternativeHeader section="Staff" showNew showNewAction={() => props.history.push("/admin/staff/add")} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading && data.getAllStaff.length > 0 && <StaffTable data={data.getAllStaff} props={props} deleteCallback={i => deleteEntity(i)} />}
            </Container>
        </>
    );
};

export default Staff;
