import React, {useEffect, useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_JOB_BY_ID_WITH_AGG } from "./Scheme"
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Doughnut } from 'react-chartjs-2';
import themeColors from '../../../assets/theme/colors';
import moment from 'moment';
import ScheduleTable from './Components/ScheduleTable';
import StaffTable from "./Components/StaffTable";
import {AdjustDialog} from "./Components/AdjustDialog";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import JobStaffChart from "./Components/JobStaffChart";
import {KpiCard} from "./Components/KpiCard";
import LineTable from "./Components/LineTable";
import {convertTimeToReadable} from "../../../variables/convertTime";

const useStyles = makeStyles(componentStyles);

const AuthorizationJob = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const id = props.match.params.clientId;
    const jobId = props.match.params.jobId;
    const date = props.match.params.date;
    const period = "week";

    const { loading, data } = useQuery(GET_CUSTOMER_JOB_BY_ID_WITH_AGG, { variables: { week: date, clientId: id, jobId: jobId } });

    // The State
    const [deliverables, setDeliverables] = useState({});
    const [tasks, setTasks] = useState({});
    const [aggregation, setAggregation] = useState({});
    const [schedules, setSchedules] = useState([]);
    const [adjustmentOpen, setAdjustmentOpen] = useState(false);
    const [approveOpen, setApproveOpen] = useState(false);

    const chartOptions = {
        cutoutPercentage:0,
        legend: {
            display: true,
            position: "top"
        },
        animation: {
            animateScale: true,
            animateRotate: true
        },
        responsive: true
    };

    useEffect(() => {
        if (loading) return;

        if (data?.getActiveMasters) {
            setSchedules(data.getActiveMasters);
        }

        if (data?.getAggregationsByClient) {
            const agg = data.getAggregationsByClient.weeks[0].clients[0].jobs.filter(x => x.jobId === jobId)[0]; // todo check for null anywhere in here!
            const totalHours = Number(agg.totalHours / 60).toFixed(2);
            const estimatedHours = Number((agg.estimatedHours - (agg.totalHours / 60))).toFixed(2);
            setAggregation(agg);

            console.log("result of agg", agg);

            setDeliverables({
                labels: ["Delivered", "Estimated"],
                datasets: [
                    {
                        data: [
                            totalHours,
                            estimatedHours
                        ],
                        backgroundColor: [
                            themeColors.success.main,
                            themeColors.error.main
                        ],
                        label: "Hours",
                    },
                ]
            });

            setTasks({
                labels: ["Delivered", "Estimated"],
                datasets: [
                    {
                        data: [
                            agg?.tasks?.length || 0,
                            agg?.estimatedTasks || 0
                        ],
                        backgroundColor: [
                            themeColors.success.main,
                            themeColors.error.main
                        ],
                        label: "Tasks",
                    },
                ]
            });
        }
    }, [data, loading, date, jobId]);

    return (
        <>
            <AlternativeHeader
                section={`${aggregation.jobName} Details`}
                showBack />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    {/*<Grid item sm={12} md={3}>*/}
                    {/*    <KpiCard*/}
                    {/*        title={"Jobs"}*/}
                    {/*        toFixed={0}*/}
                    {/*        projected={aggregation?.jobs?.length || 0}*/}
                    {/*        total={0}*/}
                    {/*        suffix={"jobs"}*/}
                    {/*        expandable*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    <Grid item sm={12} md={4}>
                        <KpiCard
                            title={"Job Hours"}
                            projected={aggregation.estimatedHours}
                            total={aggregation.totalHours}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={4}>
                        <KpiCard
                            title={"Tasks"}
                            toFixed={0}
                            projected={aggregation?.tasks?.length || 0}
                            total={aggregation?.tasks?.length || 0}
                            suffix={"tasks"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={4}>
                        <KpiCard
                            title={"Travel"}
                            toFixed={2}
                            projected={aggregation?.tasks?.filter(x => x.taskName === "Travel Time").map(x => x.taskTotal).reduce((a, b) => a + b, 0) || 0}
                            total={(aggregation?.tasks?.filter(x => x.taskName === "Travel Time").map(x => x.taskTotal).reduce((a, b) => a + b, 0) || 0) / 60}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={12} md={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Delivered vs Estimated Hours"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            />
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Box position="relative" height="150px">
                                            {deliverables?.labels && <Doughnut
                                                data={deliverables}
                                                options={chartOptions}
                                                height={150}
                                            />}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box
                                            component="p"
                                            color={theme.palette.black.light}
                                            fontWeight="300"
                                            lineHeight="1.7"
                                            fontSize="1.25rem"
                                            marginBottom="1rem"
                                            marginTop="1.3rem"
                                        >
                                            <strong>{convertTimeToReadable(aggregation.totalHours)}</strong> delivered when
                                            you've estimated <strong>{convertTimeToReadable(aggregation.estimatedHours)} </strong>
                                            for {period} starting the <strong>{moment(date).add(-1, "day").startOf("isoWeek").format('DD/MM/yyyy')}</strong>.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Delivered vs Estimated Tasks"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            />
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Box position="relative" height="150px">
                                            {deliverables?.labels && <Doughnut
                                                data={tasks}
                                                options={chartOptions}
                                                height={150}
                                            />}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box
                                            component="p"
                                            color={theme.palette.black.light}
                                            fontWeight="300"
                                            lineHeight="1.7"
                                            fontSize="1.25rem"
                                            marginBottom="1rem"
                                            marginTop="1.3rem"
                                        >
                                            <strong>{aggregation?.tasks?.length || 0} tasks</strong> delivered when
                                            you've estimated <strong>{aggregation.estimatedTasks || 0} tasks </strong>
                                            for {period} starting the <strong>{moment(date).add(-1, "day").startOf("isoWeek").format('DD/MM/yyyy')}</strong>.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <JobStaffChart data={aggregation} />
                    </Grid>
                    <Grid item sm={12}>
                        {aggregation?.staff && aggregation.staff.length > 0 && <StaffTable title={"Staff Breakdown"} data={aggregation.staff} quotedHours={aggregation.estimatedHours / aggregation.staff.length} showSalary />}
                    </Grid>

                    <Grid item md={6} sm={12}>
                        {aggregation?.tasks?.length > 0 ?
                            <LineTable data={aggregation?.tasks} title={"Line Items"} />
                        : <Card classes={{ root: classes.cardRoot }}><CardHeader
                                className={classes.cardHeader}
                                title="Line Items"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            />
                            <CardContent><Grid container>
                                <Grid item xs={12} md={12}>
                                    <Box component={"p"} textAlign={"center"} marginBottom={0}>
                                        There are no available line items or details to display here.
                                    </Box>
                                </Grid>
                            </Grid></CardContent></Card>}
                    </Grid>

                    <Grid item md={6} sm={12}>
                        {schedules && schedules.length > 0 && <ScheduleTable title={"Related Schedules"} data={schedules} />}
                    </Grid>

                    {/*<Grid item sm={12}>*/}
                    {/*    */}
                    {/*</Grid>*/}
                </Grid>
            </Container>
            <AdjustDialog open={adjustmentOpen} onClose={() => setAdjustmentOpen(false)} />
            <SimpleDialog
                message={`Approving this will create an equivalent invoice line and disable adjustments to the clients for this ${period}.`}
                title={`Approve ${period} starting ${moment(date).format("DD-MM-YYYY")}?`}
                successCallback={() => {}}
                cancelCallback={() => setApproveOpen(false)}
                show={approveOpen} />
        </>
    );
};

export default AuthorizationJob;
