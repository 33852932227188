import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "assets/theme/theme.js";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";
import AdminLayout from "layouts/Admin.js";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider, HttpLink
} from "@apollo/client";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { setContext } from "@apollo/client/link/context";
import 'dotenv/config';
import SuperAdminLayout from "./layouts/SuperAdmin";

const ApolloProviderWithAuth0 = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();

    console.log(process.env);

    let httpLink;

    httpLink = new HttpLink({
        uri:
            process.env.NODE_ENV === "development"
                ? "http://localhost:5000/graphql"
                //? "https://app.roma.systems/roma-server/graphql"
                : process.env.API_URI || `${window.location.origin}/roma-server/graphql`
    });

    const authLink = setContext(async (_, { headers, ...rest }) => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log(error);
        }

        if (!token) return { headers, ...rest };

        return {
            ...rest,
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    });

    const client = React.useRef();

    if (!client || !client.current) {
        client.current = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache()
        });
    }

    return (
        <ApolloProvider client={client.current}>
            {children}
        </ApolloProvider>
    );
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.push((appState && appState.returnTo) || window.location.pathname);
    };

    return (
        <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
            {children}
        </Auth0Provider>
    );
};

ReactDOM.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>
            <BrowserRouter>
                <Auth0ProviderWithRedirectCallback
                    domain="roma-test.eu.auth0.com"
                    clientId="7s58MQVekNpQ20BtEGxbcPTRebNLiTls"
                    redirectUri={window.location.origin}
                    cacheLocation="localstorage"
                    audience="https://api.roma.systems"
                >
                    <ApolloProviderWithAuth0>
                        <Switch>
                            <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                            <Route path="/super-admin" render={(props) => <SuperAdminLayout {...props} />}/>
                            {/*<Redirect to="/admin/dashboard" from="/" exact />*/}
                        </Switch>
                    </ApolloProviderWithAuth0>
                </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>
    </ThemeProvider>,
    document.querySelector("#root")
);
