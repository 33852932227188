import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FilledInput from '@material-ui/core/FilledInput';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
// import {makeStyles} from '@material-ui/core/styles';
// import componentStyles from '../../../../assets/theme/views/admin/calendar';
// import componentStylesButtons from '../../../../assets/theme/components/button';
// import componentStylesForms from '../../../../assets/theme/components/forms';

// const useStyles = makeStyles(componentStyles);
// const useStylesButtons = makeStyles(componentStylesButtons);
// const useStylesForms = makeStyles(componentStylesForms);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const AdjustDialog = ({ open, onSave, onClose, travelTime, workingTime }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [travel, setTravel] = useState(travelTime);
    const [working, setWorking] = useState(workingTime);

    useEffect(() => { setIsOpen(open); }, [open]);

    return (<Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent>
            <Box
                component="h2"
                marginBottom="25px"
                marginTop="0"
            >
                Correct/Adjust Figures
            </Box>
            <FormGroup>
                <FormLabel>Travel Time (Minutes)</FormLabel>
                <FilledInput
                    placeholder="Travel Time (Minutes)"
                    type="number"
                    value={travel}
                    onChange={(e) => setTravel(Number(e.target.value))}
                />
            </FormGroup>

            <FormGroup>
                <FormLabel>Working Time (Minutes)</FormLabel>
                <FilledInput
                    placeholder="Working Time (Minutes)"
                    type="number"
                    value={working}
                    onChange={(e) => setWorking(Number(e.target.value))}
                />
            </FormGroup>

            <FormGroup style={{marginBottom: 0}}>
                <Box
                    component="p"
                    marginBottom="0"
                    fontWeight="300"
                    lineHeight="1.5"
                    fontSize="0.85rem"
                    color="crimson"
                >
                    Adjusting these figures will override what is currently held and cause figures to be recalculated.
                </Box>
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Box>
                    <Button color="primary" variant="contained" onClick={() => onSave()}>
                        Adjust
                    </Button>
                </Box>
                <Button onClick={() => onClose()} color="primary">
                    Cancel
                </Button>
            </Box>
        </DialogActions>
    </Dialog>);
};
