import {gql} from "@apollo/client";

export const CREATE_SCHEDULE = gql`
    mutation($data: CreateScheduleInput!) {
        createSchedule(createSchedule: $data) {
            _id
        }
    }
`;

export const GET_SCHEDULE_BASE_DATA = gql`
    query {
        getAllClient {
            _id
            name
            siteCount
            sites {
                _id
                name
            }
        }

        getAllStaff {
            _id,
            forename,
            surname,
            position,
            email
        }

        getAllJob {
            _id
            name
            type
            createdAt
            updatedAt
            chargeLine {
                chargeId
                quantity
            }
        }
    }
`;

export const UPDATE_SCHEDULE = gql`
    mutation($id: ObjectId!, $isEditingMaster: Boolean!, $data: UpdateScheduleInput!) {
        updateSchedule(id: $id, isEditingMaster: $isEditingMaster, updateSchedule: $data) {
            _id
        }
    }
`;

export const DELETE_SCHEDULE = gql`
    mutation($id: ObjectId!, $isEditingMaster: Boolean!, $date: DateTime!) {
        deleteSchedule(id: $id, isEditingMaster: $isEditingMaster, date: $date)
    }
`;

export const GET_ALL_SCHEDULES = gql`
    query {
        getAllSchedule {
            _id
            clientId
            jobId
            shortDescription
            dateTimeStart
            dateTimeEnd
            colour
            staffIds
            recurrence
            siteId
            isArchived
        }
    }
`;

export const GET_SCHEDULE_DETAILS = gql`
    query($id: ObjectId!, $start: DateTime!, $end: DateTime!) {
        getScheduleDetail(id: $id, start: $start, end: $end) {
            _id
            clientId
            jobId
            shortDescription
            dateTimeStart
            dateTimeEnd
            colour
            staffIds
            recurrence
            siteId
            isArchived
            staffEvents {
                checkInDateTime
                checkInDifference
                checkOutDateTime
                checkOutDifference
                totalTime
                staffId
            }
            taskEvents {
                datetime
                taskName
                taskType
                taskValue
                staffId
            }
        }
    }
`;

export const GET_SCHEDULE_BY_ID = gql`
    query ($id: ObjectId!) {
        getAllSchedules(id: $id) {
            _id
            clientId
            jobId
            shortDescription
            dateTimeStart
            dateTimeEnd
            colour
            staffIds
            recurrence
            siteId
            isArchived
        }
    }
`;
