import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import PersonPin from "@material-ui/icons/PersonPin";
import componentStylesButtons from "../../../assets/theme/components/button";
import componentStylesForms from "assets/theme/components/forms.js";
import GoogleMap from "google-map-react";
import EventTable from "./Components/EventTable";
import {useQuery} from "@apollo/client";
import {GET_ALL_SCHEDULE_LOGS} from "./Scheme";
import {useHistory} from "react-router-dom";

const useStylesForms = makeStyles(componentStylesForms);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const Monitoring = () => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesForms()
    };

    let history = useHistory();

    // Data
    const scheduleLogResult = useQuery(GET_ALL_SCHEDULE_LOGS, { pollInterval: 3000 });
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        // Set clients.
        console.log("Log result", scheduleLogResult?.data)
        if (scheduleLogResult?.data?.getAllScheduleLog) setLogs(scheduleLogResult.data.getAllScheduleLog);
    }, [scheduleLogResult]);

    return (
        <>
            <AlternativeHeader
                section="Monitoring"
                customButton="View Logs"
                customButtonAction={() => history.push("/admin/monitoring/logs")}
            />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Realtime"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12} style={{ height:"300px" }}>
                                        <GoogleMap
                                            defaultCenter={{lat: 0, lng: 0}}
                                            center={{lat: 53.931810, lng: -0.784920}}
                                            bootstrapURLKeys={{key: "AIzaSyCawaNc5ExsVd9JRJ5iEbz70lNy0bpwKA4"}}
                                            defaultZoom={17}
                                            hoverDistance={1}
                                        >
                                            <Box
                                                component={PersonPin}
                                                width="1.25rem!important"
                                                height="1.25rem!important"
                                                lat={53.931810}
                                                lng={-0.784920}
                                                text="James Tognola"
                                            />
                                        </GoogleMap>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {logs > 0 && <EventTable title="Log Stream" showClientName data={logs} showEventTypes />}

                        <Card classes={{root: classes.cardRoot}}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Unmatched Schedules"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            />
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} lg={12} alignItems={"center"} alignContent={"center"}>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card classes={{root: classes.cardRoot}}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Average Job Missed by Staff"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            />
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} lg={12} alignItems={"center"} alignContent={"center"}>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {logs.filter(x => x.action === "EventLate").length > 0 && <EventTable hideTimestamp showClientName hideSchedule title="Late Staff" data={logs.filter(x => x.action === "EventLate")} />}
                        {logs.filter(x => x.action === "EventLate").length > 0 && <EventTable hideStaffName hideTimestamp hideSchedule showScheduleName title="Missed Schedules" data={logs.filter((a, i) => logs.findIndex((s) => a.scheduleName === s.scheduleName) === i)} />}

                        {logs.filter(x => x.action === "EventEarly").length > 0 && <EventTable hideTimestamp showClientName hideSchedule title="Early Staff" data={logs.filter(x => x.action === "EventLate")} />}

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Time Spent at Client"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Monitoring;
