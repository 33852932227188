import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import { chartOptions, parseOptions } from "../../../../variables/charts";
import Chart from "chart.js";
import CardContent from "@material-ui/core/CardContent";
import {Bar} from "react-chartjs-2";
const themeColors = require("assets/theme/colors.js").default;

const useStyles = makeStyles(componentStyles);

export default function JobStaffChart({data}) {
    const classes = useStyles();
    const theme = useTheme();
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    if (!data || !data.staff) return <></>;

    const staffChart = {
        data: {
            labels: data.staff.map(x => x.staffName),
            datasets: [
                {
                    label: "Hours within Job",
                    backgroundColor: themeColors.success.main,
                    data: data.staff.map(x => x.totalHours / 60 > data.estimatedHours ? data.estimatedHours : x.totalHours / 60),
                    maxBarThickness: 15,
                },
                {
                    label: "Hours outside of Job",
                    backgroundColor: themeColors.error.main,
                    data: data.staff.map(x => x.totalHours / 60 > data.estimatedHours ? (x.totalHours / 60) - data.estimatedHours : 0),
                    maxBarThickness: 15,
                }
            ],
        },
        options: {
            tooltips: {
                mode: "point",
                intersect: true,
                yAlign: "center",
                xAlign: "center"
            },
            responsive: true,
            scales: {
                xAxes: [ { stacked: true } ],
                yAxes: [ { stacked: true } ],
            },
        },
    };

    console.log(data);

    return (
        <>
            <Card
                classes={{
                    root: classes.cardRoot,
                }}
            >
                <CardHeader
                    title={
                        <Box component="span" color={theme.palette.gray[600]}>
                            Overview
                        </Box>
                    }
                    subheader="Staff Charges and Deviations"
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: "h6",
                        letterSpacing: "2px",
                        marginBottom: "0!important",
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: "h3",
                        marginBottom: "0!important",
                        color: "initial",
                    }}
                ></CardHeader>
                <CardContent>
                    <Box position="relative" height="430px">
                        <Bar data={staffChart.data} options={staffChart.options} />
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}
