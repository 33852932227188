import React, {useState} from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import componentStylesDialog from "../../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../../assets/theme/components/button";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const tableHead = ["name", ""];

export default function SiteTable({data, editHandler, newSiteHandler, deleteHandler}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };
    const tableContainerRef = React.useRef(null);
    React.useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: ["name"],
            listClass: "list"
        });
    }, []);

    const [selectedRow, setSelectedRow] = useState({});

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title="Sites"
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                    action={
                        <Button
                            onClick={() => newSiteHandler()}
                            style={{marginTop: "5px"}}
                            variant="outlined"
                            size="small"
                            classes={{root: classes.buttonContainedSuccess}}>
                            New
                        </Button>}/>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "name" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }} align="right">
                                        <TableDropdown id={key} actions={[
                                            { text: "Edit", handle: () => editHandler(prop) },
                                            { text: "Delete", handle: () => setSelectedRow(prop), style: {color: "red"} }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
            {/* Delete Dialog */}
            <Dialog
                open={selectedRow?._id || false}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSelectedRow({})}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className={classes.dialogHeader}>
                    <Typography variant="h5" component="h5" className={classes.dialogTitle}>
                        Delete {selectedRow?.name}?
                    </Typography>
                    <IconButton onClick={() => setSelectedRow({})}>
                        <Clear />
                    </IconButton>
                </div>
                <DialogContent>
                    <Typography variant="body2" component="p">
                        Are you sure you would like to delete the site {selectedRow?.name}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            deleteHandler();
                            setSelectedRow({});
                        }
                        }
                        classes={{ root: classes.buttonContainedWarning }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button
                        component={Box}
                        onClick={() => setSelectedRow({})}
                        color="primary"
                        marginLeft="auto!important"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
