import React, {useEffect, useState} from "react";
// @material-ui/core components
import GoogleMap from 'google-map-react';
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";

import { geocodeByAddress } from 'react-places-autocomplete';

export default function AddressForm({address, setCallback, showTravel}) {
    let [storedAddress, setStoredAddress] = useState(address);
    let [storedCenter, setStoredCenter] = useState({lat: address.latitude || 0, lng: address.longitude || 0});
    let [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (!isEditing) {
            setStoredAddress(address);
            setStoredCenter({lat: address.latitude || 0, lng: address.longitude || 0});
        }
    }, [address, isEditing]);

    const callback = (a) => {
        setStoredAddress(a);
        setIsEditing(true);
    }

    const searchAddress = () => {
        geocodeByAddress(`${storedAddress.postalCode}`)
            .then(results => {
                const newAddress = {
                    ...storedAddress,
                    latitude: results[0].geometry.location.lat() || 0,
                    longitude: results[0].geometry.location.lng() || 0
                };
                setStoredCenter({lat: newAddress.latitude, lng: newAddress.longitude});
                setStoredAddress(newAddress)
                setCallback(newAddress);
            })
            .catch(error => console.error(error));
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} style={{height: "200px", marginBottom: "15px"}}>
                    <GoogleMap
                        defaultCenter={{lat: 0, lng: 0}}
                        center={storedCenter}
                        bootstrapURLKeys={{key: "AIzaSyCawaNc5ExsVd9JRJ5iEbz70lNy0bpwKA4"}}
                        defaultZoom={17}
                     >
                        {storedCenter?.lat && <svg lat={storedCenter?.lat} lng={storedCenter?.lng} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 48 48"><title>pin-3</title><g><path fill="#E86C60" d="M24,1.11224c-9.38879,0-17,7.61115-17,17 c0,10.1424,12.87262,23.22955,16.2149,26.4566c0.44031,0.42517,1.12988,0.42517,1.57025,0C28.12744,41.3418,41,28.25464,41,18.11224 C41,8.72339,33.38879,1.11224,24,1.11224z"></path> <circle fill="#FFFFFF" cx="24" cy="18" r="6"></circle></g></svg>}
                    </GoogleMap>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={showTravel ? 4 : 6}>
                    <FormGroup>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Building Name/Number"
                            value={storedAddress?.buildingNameOrNumber || ""}
                            onChange={e => callback({...storedAddress, buildingNameOrNumber: e.target.value})}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={showTravel ? 4 : 6}>
                    <FormGroup>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Street Name"
                            value={storedAddress?.streetName || ""}
                            onChange={e => callback({...storedAddress, streetName: e.target.value})}
                        />
                    </FormGroup>
                </Grid>

                {showTravel &&
                    <Grid item xs={12} md={4}>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Travel Time (hrs)"
                            startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}
                            value={storedAddress?.travelTime || ""}
                            onChange={e => callback({...storedAddress, travelTime: Number(e.target.value)})}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container>
                <Grid item xs={6} md={4}>
                    <FormGroup>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Postal Town"
                            value={storedAddress?.postalTown || ""}
                            onChange={e => callback({...storedAddress, postalTown: e.target.value})}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4}>
                    <OutlinedInput
                        fullWidth
                        type="text"
                        placeholder="County"
                        value={storedAddress?.county || ""}
                        onChange={e => callback({...storedAddress, county: e.target.value})}
                    />
                </Grid>

                <Grid item xs={6} md={4}>
                    <OutlinedInput
                        fullWidth
                        required
                        type="text"
                        placeholder="Postal Code"
                        startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}
                        value={storedAddress?.postalCode || ""}
                        onChange={e => callback({...storedAddress, postalCode: e.target.value})}
                    />
                </Grid>

                {/*{showTravel && (*/}
                {/*    <>*/}
                {/*    <Grid item xs={6} md={6}>*/}
                {/*        <OutlinedInput*/}
                {/*            fullWidth*/}
                {/*            type="text"*/}
                {/*            placeholder="Travel Time"*/}
                {/*            startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}*/}
                {/*            value={storedAddress?.travelTime || ""}*/}
                {/*            onChange={e => callback({...storedAddress, travelTime: Number(e.target.value)})}*/}
                {/*        />*/}
                {/*    </Grid>*/}

                {/*    /!*<Grid item xs={6} md={6}>*!/*/}
                {/*    /!*<OutlinedInput*!/*/}
                {/*    /!*    fullWidth*!/*/}
                {/*    /!*    type="text"*!/*/}
                {/*    /!*    placeholder="Travel Mileage"*!/*/}
                {/*    /!*    startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}*!/*/}
                {/*    /!*    value={storedAddress?.travelMiles || ""}*!/*/}
                {/*    /!*    onChange={e => callback({...storedAddress, travelMiles: Number(e.target.value)})}*!/*/}
                {/*    /!*    />*!/*/}
                {/*    /!*</Grid>*!/*/}
                {/*    </>*/}
                {/*)}*/}

                <Grid item xs={12} md={12}>
                    <OutlinedInput
                        fullWidth
                        type="button"
                        value="Lookup"
                        onClick={searchAddress}
                        />
                </Grid>
            </Grid>
        </>
    );
}
