import React, {useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Description from "@material-ui/icons/Description";
import Money from '@material-ui/icons/Money';
import { useMutation, useQuery } from "@apollo/client";
import {CREATE_CHARGE, GET_ALL_CHARGE, GET_CHARGE_BY_ID, UPDATE_CHARGE} from "./Scheme";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "../../../../assets/theme/hex-to-rgb";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Snackbar from "@material-ui/core/Snackbar";
import componentStylesSnackbar from "../../../../assets/theme/components/snackbar";
import {Link} from "react-router-dom";
import {Divider, InputLabel} from "@material-ui/core";
import {CodeOutlined} from "@material-ui/icons";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import {Doughnut} from "react-chartjs-2";
import themeColors from "../../../../assets/theme/colors";
import JobTable from "../Jobs/Components/JobTable";

const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStyles = makeStyles(componentStyles);

const AddCharge = (props) => {
    const classes = {
        ...useStyles(),
        ...useStylesSnackbar()
    };
    const theme = useTheme();

    const profitChartOptions = {
        cutoutPercentage:80,
        legend: {
            display:false,
            position: "top"
        },
        animation: {
            animateScale: true,
            animateRotate: true
        },
        responsive: true
    };

    const id = props.match.params.chargeId;
    const updating = id !== "add";
    const successSnackbarRootClasses = { root: classes.successSnackbar };

    // Form Variables
    const [shortDescription, setShortDescription] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [charge, setCharge] = useState(0.0);
    const [cost, setCost] = useState(0.0);
    const [successText, setSuccessText] = useState("");
    const [arCode, setArCode] = useState("");
    const [apCode, setApCode] = useState("");
    const [profitData, setProfitData] = useState({
        labels: ["Charge", "Cost"],
        datasets: [
            {
                data: [
                    1,
                    1
                ],
                backgroundColor: [
                    themeColors.success.main,
                    themeColors.error.main
                ],
                label: "Profit",
            },
        ]
    });

    const [createCharge] = useMutation(CREATE_CHARGE, { refetchQueries: [{ query: GET_ALL_CHARGE }] });
    const [updateCharge] = useMutation(UPDATE_CHARGE);
    const chargeResult = useQuery(GET_CHARGE_BY_ID, { variables: { id } });

    const handleForm = (e) => {
        e.preventDefault();
        const data = {
            shortDescription,
            longDescription,
            charge,
            cost,
            arCode,
            apCode
        };

        if (!updating) {
            createCharge({variables: {data}})
                .then(x => {
                    props.history.push(`/admin/charge/${x.data.createCharge._id}`);
                    setSuccessText("Charge created!");
                });
        } else {
            updateCharge({ variables: {data, id } })
                .then(() => setSuccessText("Charge saved!"))
                .catch(() => {/* todo */})
        }
    };

    useEffect(() => {
        if (updating && chargeResult?.data?.getCharge) {
            const result = chargeResult.data.getCharge;
            setShortDescription(result?.shortDescription || "");
            setLongDescription(result?.longDescription || "");
            setCharge(result?.charge || 0.0);
            setCost(result?.cost || 0.0);
            setArCode(result?.arCode || "");
            setApCode(result?.apCode || "");
        }
    }, [chargeResult, updating]);

    useEffect(() => {
        setProfitData({
            labels: ["Charge", "Cost"],
            datasets: [
                {
                    data: [
                        charge,
                        cost
                    ],
                    backgroundColor: [
                        themeColors.success.main,
                        themeColors.error.main
                    ],
                    label: "Profit",
                },
            ]
        });
    }, [cost, charge])

    return (
        <>
            <form onSubmit={handleForm}>
            <AlternativeHeader section="Add Charge" subsection="Charges" subsectionLink={`/admin/charges`} showSave />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Basics"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <FormLabel>Charge Type</FormLabel>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select defaultValue={'service'} IconComponent={KeyboardArrowDown}>
                                                    <MenuItem value={'service'}>Service</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <InputLabel>Short Description</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                required
                                                type="text"
                                                placeholder="Short Description"
                                                value={shortDescription}
                                                startAdornment={<InputAdornment position="start"><Description /></InputAdornment>}
                                                onChange={x => setShortDescription(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <InputLabel>Long Description</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                multiline
                                                rows="4"
                                                placeholder="Long Description"
                                                value={longDescription}
                                                onChange={x => setLongDescription(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel>Charge</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="number"
                                                placeholder="Charge"
                                                value={charge}
                                                startAdornment={<InputAdornment position="start"><Money /></InputAdornment>}
                                                onChange={x => setCharge(parseFloat(x.target.value))}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Cost</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            type="number"
                                            placeholder="Cost"
                                            value={cost}
                                            startAdornment={<InputAdornment position="start"><Money /></InputAdornment>}
                                            onChange={x => setCost(parseFloat(x.target.value))}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider classes={{ root: classes.divider }} style={{marginBottom: '25px'}} />
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel>AR Ledger Code</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                value={arCode}
                                                placeholder="AR Ledger Code"
                                                startAdornment={<InputAdornment position="start"><CodeOutlined /></InputAdornment>}
                                                onChange={x => setArCode(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>AP Ledger Code</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            type="text"
                                            value={apCode}
                                            placeholder="AP Ledger Code"
                                            startAdornment={<InputAdornment position="start"><CodeOutlined /></InputAdornment>}
                                            onChange={x => setApCode(x.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card
                            classes={{
                                root: classes.cardRoot,
                            }}
                        >
                            <CardHeader
                                title={
                                    <Box component="span" color={theme.palette.gray[600]}>
                                        Charge
                                    </Box>
                                }
                                subheader="Profit"
                                classes={{ root: classes.cardHeaderRoot }}
                                titleTypographyProps={{
                                    component: Box,
                                    variant: "h6",
                                    letterSpacing: "2px",
                                    marginBottom: "0!important",
                                    classes: {
                                        root: classes.textUppercase,
                                    },
                                }}
                                subheaderTypographyProps={{
                                    component: Box,
                                    variant: "h3",
                                    marginBottom: "0!important",
                                    color: "initial",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid>
                                    <Box position="relative" height="150px">
                                        <Doughnut
                                            data={profitData}
                                            options={profitChartOptions}
                                            height={105}
                                        />
                                    </Box>
                                </Grid>

                            </CardContent>
                        </Card>

                        {/*<Card classes={{ root: classes.cardRoot }}>*/}
                        {/*    <CardHeader*/}
                        {/*        className={classes.cardHeader}*/}
                        {/*        title="Related Customers"*/}
                        {/*        titleTypographyProps={{*/}
                        {/*            component: Box,*/}
                        {/*            marginBottom: "0 !important",*/}
                        {/*            variant: "h3",*/}
                        {/*        }}*/}
                        {/*    ></CardHeader>*/}
                        {/*    <CardContent>*/}
                        {/*        <Grid container>*/}
                        {/*            <Grid item xs={12} md={12}>*/}
                        {/*                <p align={'center'}>*/}
                        {/*                    No related customers, create one <Link to={"/admin/customers"}>here</Link>.*/}
                        {/*                </p>*/}
                        {/*            </Grid>*/}
                        {/*        </Grid>*/}
                        {/*    </CardContent>*/}
                        {/*</Card>*/}

                        {updating && chargeResult?.data?.getJobsByCharge?.length > 0
                            ? <JobTable data={chargeResult?.data?.getJobsByCharge} props={props} title="Related Jobs" />
                            : <Card classes={{ root: classes.cardRoot }}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Related Jobs"
                                    titleTypographyProps={{
                                        component: Box,
                                        marginBottom: "0 !important",
                                        variant: "h3",
                                    }}
                                ></CardHeader>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <p align={'center'}>
                                                No related jobs, create one <Link to={"/admin/jobs"}>here</Link>.
                                            </p>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>}
                    </Grid>
                </Grid>
            </Container>
            </form>
            {/* Notification */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={successText.length > 0}
                autoHideDuration={2000}
                onClose={() => setSuccessText("")}
                ContentProps={{
                    classes: successSnackbarRootClasses,
                    elevation: 1,
                }}
                action={
                    <Box component={IconButton} padding="0!important" onClick={() => setSuccessText("")}>
                        <Box component="span" color={ "rgba(" + hexToRgb(theme.palette.white.main) + ",.6)" }>
                            ×
                        </Box>
                    </Box>
                }
                message={
                    <>
                        <Box
                            fontSize="1.25rem"
                            display="flex"
                            marginRight="1.25rem"
                            alignItems="center"
                        >
                            <Box component={ThumbUp} width="1.25rem!important" height="1.25rem!important" />
                        </Box>
                        <Box component="span">
                            <Box component="strong" marginRight=".5rem">Success!</Box>
                            {successText}
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default AddCharge;
