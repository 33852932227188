import React from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import TableHead from "@material-ui/core/TableHead";
import {convertTimeToReadable} from "../../../../variables/convertTime";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function CompareTable({jobData, estimationData, actualData}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    const tableHead= ["", "Travel", "Hours", "Charge", "Cost", "Revenue", "Profit"];

    const tableContainerRef = React.useRef(null);

    new List(tableContainerRef.current, {
        valueNames: tableHead,
        listClass: "list"
    });

    const oldStyleRow = (title, data) => <TableRow key={title}>
        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead }}
                   component="th"
                   variant="head"
                   scope="row">
            <Box display="flex" alignItems="flex-start">
                <Box fontSize=".875rem" component="span">
                    {title}
                </Box>
            </Box>
        </TableCell>

        <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">{convertTimeToReadable(data.travel)} (£{(data.travel * data.cost).toFixed(2)})</Box>
        </TableCell>

        <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">{convertTimeToReadable(data.hours)}</Box>
        </TableCell>

        <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">£{(data.hours * data.charge).toFixed(2)} (£{data.charge.toFixed(2)} per hr)</Box>
        </TableCell>

        <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">£{(data.hours * data.cost).toFixed(2)} (£{data.cost.toFixed(2)} per hr)</Box>
        </TableCell>

        <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">£{((data.hours * data.charge) + (data.travel * data.charge)).toFixed(2)}</Box>
        </TableCell>

        <TableCell classes={{root: classes.tableCellRoot}}>
            <Box paddingTop=".35rem" paddingBottom=".35rem">£{(((data.hours + data.travel) * data.charge) - ((data.hours + data.travel) * data.cost)).toFixed(2)} ({( ((data.hours + data.travel) * data.cost) / ((data.hours + data.travel) * data.charge) * 100).toFixed(2)}%)</Box>
        </TableCell>

        {/*{data && <TableCell classes={{root: classes.tableCellRoot}}>*/}
        {/*    <Box paddingTop=".35rem" paddingBottom=".35rem">*/}
        {/*        {data}*/}
        {/*    </Box>*/}
        {/*</TableCell>}*/}
    </TableRow>;

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={"Comparison"}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                ></CardHeader>
                {<TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "forename" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {oldStyleRow("Estimated", jobData)}
                            {oldStyleRow("Projected", estimationData)}
                            {oldStyleRow("Reported", actualData)}
                        </TableBody>
                    </Box>
                </TableContainer>}
            </Card>
        </>
    );
}
