import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AdminNavbarAlternative from "components/Navbars/AdminNavbarAlternative.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import routes from "routes.js";
import componentStyles from "assets/theme/layouts/admin.js";
import { useMutation, useQuery } from "@apollo/client";
import { GET_LAST_SEEN_UPDATE_STAFF, SET_LAST_UPDATE_SEEN_STAFF } from "../views/admin/Management/Staff/Scheme";
import moment from "moment";
import InfoBox from "../components/Shared/InfoBox";

const useStyles = makeStyles(componentStyles);

const Admin = (props) => {
  const classes = useStyles();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false);

  const { data: seenResult, loading: seenLoading } = useQuery(GET_LAST_SEEN_UPDATE_STAFF);
  const [setLastUpdateSeen] = useMutation(SET_LAST_UPDATE_SEEN_STAFF, { refetchQueries: [{ query: GET_LAST_SEEN_UPDATE_STAFF }]});
  const currentUpdate = moment("2023/02/22");
  const [showUpdate, setShowUpdate] = useState(false);
  const handleRead = () => setLastUpdateSeen().then(() => setShowUpdate(false));
  const updateMessage =
      <Box>
        <Box>
          <strong>Update version: {currentUpdate.format("DD/MM/yyyy")}</strong>
        </Box>
        <br />
        <Box>
          This update brings general bugfixes and improvements. Please see the below list for more details.
        </Box>
        <br />
        <ul style={{textAlign:"left"}}>
          <li>Client name now added to schedule logs.</li>
          <li>Schedule logs are now sorted by their timestamp asc.</li>
          <li>Implemented update method on estimations.</li>
          <li>Bug where jobs hang on the app now resolved (data error).</li>
          <li>AP/AR codes now included in the schema.</li>
          <li>New date selection and better filtering of aggregations inside the authorization screen.</li>
          <li>Re-fetch queries updated on the customer and site.</li>
        </ul>
      </Box>;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (seenLoading) return;

    const result = seenResult?.getCurrentStaffMember;
    if (!result || !result.lastUpdateView) {
      setShowUpdate(true);
      return;
    }

    // If the staff member update view is
    // before the current update, set to true.
    setShowUpdate(moment(result.lastUpdateView).isBefore(currentUpdate, "date"));
  }, [seenResult, seenLoading, currentUpdate]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (<Route path={prop.layout + prop.path} component={withAuthenticationRequired(prop.component)} key={key} exact={true} />);
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Box display="flex">
        <Sidebar
          routes={routes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/index",
            imgSrc: require("../assets/img/brand/roma_logo_2.png").default,
            imgAlt: "...",
          }}
        />
        <Box position="relative" flex="1" className={classes.mainContent}>
          <AdminNavbarAlternative openSidebarResponsive={() => setSidebarOpenResponsive(true)} props={props} />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          {showUpdate && (
              <InfoBox
                  title={"What we've been up to!"}
                  successCallback={() => handleRead()}
                  buttonText={"OK"}
                  message={updateMessage}
                  open={showUpdate}
              />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Admin;
