export const convertTimeToReadable = (time) =>
{
    const isNegative = time < 0;

    time = Number(time < 0 ? -time : time).toFixed(2);

    time = time * 60;

    // Calculate the number of hours by dividing num by 60 and rounding down
    let hours = Math.floor(time / 60);

    // Calculate the remaining minutes by taking the remainder when dividing num by 60
    let minutes = time % 60;

    // Return the result as a string in the format "hours:minutes"
    return `${isNegative ? "-" : ""}${hours} hrs ${minutes.toFixed(0)} mins`;
};