import React, {useState} from "react";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../../assets/theme/components/button";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FilledInput from "@material-ui/core/FilledInput";
import Box from "@material-ui/core/Box";
// import Check from "@material-ui/icons/Check";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AddressForm from "../../../../../components/AddressForm";
import {Divider} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Note from '@material-ui/icons/Note';

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddSite({data, showModal, closeEvent, saveEvent, availableJobs, customerSelectedJobs}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    const handleSave = () => {
        saveEvent({
            _id: id,
            name,
            notes,
            address: {...address, countryName: "UK"},
            jobs
        });

        closeEvent();
    };

    const [id, setId] = useState(null);
    const [name, setName] = useState("");
    const [notes, setNotes] = useState("");
    const [jobs, setJobs] = useState([]);
    const [address, setAddress] = useState({});

    React.useEffect(() => {
        if (data) {
            console.log(data);
            setName(data.name || "");
            setNotes(data.notes || "");
            setJobs(data.jobs || []);
            setAddress(data.address || {});
            setId(data._id || null);

            console.log(data.jobs);
        }
    }, [data])

    return (
        <>
            <Dialog
                open={showModal && showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => closeEvent()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Box
                        component="h2"
                        marginBottom="25px"
                        marginTop="0"
                    >
                        Add Site
                    </Box>
                    <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <FilledInput
                            placeholder="e.g., Site 1"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Notes</FormLabel>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            multiline
                            placeholder="E.g., keycode is 1234"
                            value={notes}
                            startAdornment={<InputAdornment position="start"><Note /></InputAdornment>}
                            onChange={x => setNotes(x.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Jobs (optional)</FormLabel>
                        <Autocomplete
                            id="combo-box-demo"
                            options={customerSelectedJobs && customerSelectedJobs.length > 0
                                ? availableJobs.filter(a => customerSelectedJobs.map(x => x._id).includes(a._id))
                                : availableJobs}
                            multiple
                            value={jobs && availableJobs.filter(x => jobs.includes(x._id))}
                            getOptionLabel={(option) => option.name}
                            closeIcon={null}
                            popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important" height="1.25rem!important" />}
                            renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                            onChange={(a, value) => { setJobs(value) }}
                        />
                    </FormGroup>
                    <Divider classes={{ root: classes.divider }} style={{marginBottom: '25px'}} />
                    <FormGroup>
                        <AddressForm address={address} setCallback={(a) => setAddress(a)} showTravel={true} />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" justifyContent="space-between">
                        <Box>
                            <Button color="primary" variant="contained" onClick={() => handleSave()}>
                                {id ? "Save" : "Add"} site
                            </Button>
                        </Box>
                        <Button color="primary" onClick={() => closeEvent()}>
                            Close
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}
