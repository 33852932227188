import React, { useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import FilledInput from "@material-ui/core/FilledInput";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddJobCharge({data, showModal, closeEvent}) {
    const [initialCharge, setInitialCharge] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [selectedCharge, setSelectedCharge] = useState('');
    const [charges, setCharges] = useState([]);

    const closeEventHandler = () => {
        closeEvent({
            ...charges.find(x => x._id === selectedCharge),
            selectedCharge,
            quantity
        });
    };

    React.useEffect(() => {
        if (data) {
            setQuantity(data.quantity ?? 1);
            setCharges(data.charges ?? []);
            setSelectedCharge(data.selectedCharge ?? '');
            setInitialCharge(data.selectedCharge ?? '')
        }
    }, [data]);

    return (
        <>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => closeEvent()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Box
                        component="h2"
                        marginBottom="25px"
                        marginTop="0"
                    >
                        Add Charge
                    </Box>
                    <FormGroup>
                        <FormLabel>Charge</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                defaultValue={initialCharge}
                                IconComponent={KeyboardArrowDown}
                                onChange={(e) => setSelectedCharge(e.target.value)}>
                                {charges.map(c => (<MenuItem key={c._id} value={c._id}>{c.shortDescription} (<NumberFormat
                                    value={c.charge}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={'£'} />)</MenuItem>))}
                            </Select>
                        </FormControl>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Hours/Quantity</FormLabel>
                        <FilledInput
                            placeholder="e.g., 2"
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" justifyContent="space-between">
                        <Box>
                            <Button color="primary" variant="contained" onClick={closeEventHandler}>Add charge</Button>
                        </Box>
                        <Button color="primary" onClick={() => closeEvent()}>Close</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}
