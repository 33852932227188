import React, { useMemo } from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function ScheduleTable({data, title}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    let history = useHistory();

    const tableHead = useMemo(() => ["description", "date start", "job", "charge per hr", "hours deviated (-/+)", ""], []);
    const tableContainerRef = React.useRef(null);

    React.useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: tableHead.slice(0, tableHead.length - 1),
            listClass: "list"
        });
    }, [tableHead]);

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={title}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                ></CardHeader>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableBody className="list">
                            {tableData.map((schedule, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{
                                        root:
                                            classes.tableCellRoot +
                                            " " +
                                            classes.tableCellRootBodyHead,
                                    }}
                                               component="th"
                                               variant="head"
                                               scope="row">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box fontSize=".875rem" component="span">
                                                <Link to={`/admin/schedule/history/${schedule._id}`}>
                                                    {schedule.shortDescription}
                                                </Link>
                                            </Box>
                                        </Box>
                                    </TableCell>

                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                                            <span className="status">{moment(schedule.dateTimeStart).format("DD/MM/yyyy HH:mm:ss")}</span>
                                        </Box>
                                    </TableCell>

                                    {/*<TableCell classes={{ root: classes.tableCellRoot }}>*/}
                                    {/*    <Box paddingTop=".35rem" paddingBottom=".35rem">*/}
                                    {/*        <span className="status">{schedule.jobId}</span>*/}
                                    {/*    </Box>*/}
                                    {/*</TableCell>*/}

                                    <TableCell classes={{ root: classes.tableCellRoot }} align="right">
                                        <TableDropdown id={key} actions={[
                                            { text: "View", handle: () => history.push(`/admin/schedule/history/${schedule._id}`)}
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
        </>
    );
}
