import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMERS_WITH_AGG = gql`
    query ($week: DateTime!) {
        getAggregations(weekStarting: $week) {
            weeks {
                week
                
                totalHours
                estimatedHours
                
                totalTravel
                estimatedTravel
                
                groupedSchedules {
                    estimatedHours
                    name
                    totalHours
                }
                
                clients {
                    estimatedHours
                    totalHours
                    
                    estimatedTasks
                    totalTasks
                    
                    clientId
                    clientName

                    totalCost
                    totalCharge

                    estimatedCharge
                    estimatedCost
                    
                    estimatedTravel
                    totalTravel
                    
                    groupedSchedules {
                        estimatedHours
                        name
                        totalHours
                        scheduleId
                    }
                    
                    jobs {
                        jobId
                        totalHours
                        
                        tasks {
                            taskName
                            taskTotal
                        }
                    }
                }

                staff {
                    staffName
                    staffId

                    estimatedCost
                    totalCost

                    totalCharge
                    estimatedCharge

                    totalHours
                    estimatedHours

                    salary
                    weeklySalary
                    displaySalary
                    displayWeek
                }
            }
        }
    }
`;

export const GET_SCHEDULE_CUSTOMERS_WITH_AGG = gql`
    query ($clientId: ObjectId!, $week: DateTime!, $scheduleId: ObjectId!, $startWeek: DateTime!, $endWeek: DateTime!) {
        getAggregationsByClient(weekStarting: $week, clientId: $clientId) {
            weeks {
                week
                
                clients {
                    clientId
                    clientName
                    staff {
                        staffId
                        staffName
                    }
                    
                    groupedSchedules {
                        estimatedHours
                        name
                        totalHours
                        scheduleId
                        estimatedTasks
                        estimatedTravel
                    }
                }
            }
        }
        
        getSchedulesDetail(id: $scheduleId, start: $startWeek, end: $endWeek) {
            _id
            clientId
            jobId
            shortDescription
            dateTimeStart
            dateTimeEnd
            colour
            staffIds
            recurrence
            siteId
            isArchived
            staffEvents {
                checkInDateTime
                checkInDifference
                checkOutDateTime
                checkOutDifference
                totalTime
                staffId
            }
            taskEvents {
                datetime
                taskName
                taskType
                taskValue
                staffId
            }
        }
    }
`;

export const GET_CUSTOMER_JOB_BY_ID_WITH_AGG = gql`
    query ($clientId: ObjectId!, $week: DateTime!, $jobId: ObjectId!) {
        getAggregationsByClient(weekStarting: $week, clientId: $clientId) {
            weeks {
                week
                clients {
                    clientId
                    clientName

                    jobs {
                        jobName
                        jobId
                        
                        estimatedHours
                        displayJobCharge
                        totalCharge
                        
                        estimatedHours
                        totalHours
                        
                        tasks {
                            taskTotal
                            taskCount
                            taskName
                            taskValue
                        }
                        
                        staff {
                            staffName
                            staffId

                            estimatedCost
                            totalCost

                            totalCharge
                            estimatedCharge

                            totalHours
                            estimatedHours

                            salary
                            weeklySalary
                            displaySalary
                            displayWeek
                        }
                    }
                }
            }
        }

        getActiveMasters(clientId: $clientId, jobId: $jobId) {
            _id
            shortDescription
            jobId
            clientId
        }
    }
`;

export const APPROVE_CLIENT_WEEK = gql`
    mutation ($clientId: ObjectId!, $week: DateTime!) {
        approveAggregationForClient(clientId: $clientId, weekStarting: $week)
    }
`

export const GET_CUSTOMER_BY_ID_WITH_AGG = gql`
    query ($clientId: ObjectId!, $week: DateTime!) {
        getAggregationsByClient(weekStarting: $week, clientId: $clientId) {
            weeks {
                week
                
                clients {
                    approved
                    
                    estimatedHours
                    totalHours

                    estimatedTasks
                    totalTasks

                    clientId
                    clientName

                    totalCost
                    totalCharge
                    
                    estimatedTravel
                    totalTravel
                    
                    groupedSchedules {
                        estimatedHours
                        name
                        totalHours
                        scheduleId
                    }
                    
                    sites {
                        estimatedHours
                        totalHours
    
                        estimatedTasks
                        totalTasks
                        
                        siteName
                        
                        estimatedCharge
                        totalCharge
                        
                        siteId
                    }
                    
                    jobs {
                        jobName
                        jobId
                        totalHours
                        estimatedHours
                        displayJobCharge
                        totalCharge
                        
                        tasks {
                            taskName
                            taskTotal
                        }
                    }
                    
                    staff {
                        staffName
                        staffId
                        
                        estimatedCost
                        totalCost
                        
                        totalCharge
                        estimatedCharge
                        
                        totalHours
                        estimatedHours

                        salary
                        weeklySalary
                        displaySalary
                        displayWeek
                    }
                }
            }
        }
        
        getClient(id: $clientId) {
            _id
            name
            siteCount
            jobs
        }
        
        getActiveMasters(clientId: $clientId) {
            _id
            shortDescription
            jobId
            clientId
            dateTimeStart
        }
    }
`;

export const GET_CUSTOMER_SITE_BY_ID_WITH_AGG = gql`
    query ($clientId: ObjectId!, $week: DateTime!) {
        getAggregationsByClient(weekStarting: $week, clientId: $clientId) {
            weeks {
                week

                clients {
                    clientId
                    clientName

                    sites {
                        estimatedHours
                        totalHours

                        estimatedTasks
                        totalTasks

                        siteName

                        estimatedCharge
                        totalCost
                        totalCharge

                        estimatedTravel
                        totalTravel

                        siteId

                        groupedSchedules {
                            estimatedHours
                            name
                            totalHours
                            scheduleId
                        }

                        jobs {
                            jobName
                            jobId
                            totalHours
                            estimatedHours
                            displayJobCharge
                            totalCharge

                            tasks {
                                taskName
                                taskTotal
                            }
                        }

                        staff {
                            staffName
                            staffId

                            estimatedCost
                            totalCost

                            totalCharge
                            estimatedCharge

                            totalHours
                            estimatedHours

                            salary
                            weeklySalary
                            displaySalary
                            displayWeek
                        }
                    }
                }
            }
        }

        getClient(id: $clientId) {
            _id
            name
            siteCount
            jobs
        }

        getActiveMasters(clientId: $clientId) {
            _id
            shortDescription
            jobId
            clientId
            dateTimeStart
        }
    }
`;
