import React, {useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Work from "@material-ui/icons/Work";
import Note from "@material-ui/icons/Note";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {
    CREATE_ADVANCED_JOB,
    CREATE_SIMPLE_JOB, GET_ADVANCED_JOB_BY_ID,
    GET_ALL_JOB,
    GET_BASE_DATA,
    GET_SIMPLE_JOB_BY_ID, UPDATE_ADVANCED_JOB,
    UPDATE_JOB
} from "./Scheme";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "../../../../assets/theme/hex-to-rgb";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Snackbar from "@material-ui/core/Snackbar";
import componentStylesSnackbar from "../../../../assets/theme/components/snackbar";
import { Link } from "react-router-dom";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import JobTaskTable from "./Components/JobTaskTable";
import { Button, InputLabel } from "@material-ui/core";
import componentStylesButtons from "../../../../assets/theme/components/button";
import AddJobCharge from "./Components/AddJobCharge";
import JobChargeTable from "./Components/JobChargeTable";
import AddJobTask from "./Components/AddJobTask";
import JobRoomConfiguration from "./Components/JobRoomConfiguration";
import CustomerTable from "../Customers/Components/CustomerTable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import componentStylesForms from "../../../../assets/theme/components/forms";

const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesForms = makeStyles(componentStylesForms);

const AddJob = (props) => {
    const classes = {
        ...useStyles(),
        ...useStylesSnackbar(),
        ...useStylesButtons(),
        ...useStylesForms()
    };
    const theme = useTheme();

    const id = props.match.params.jobId;
    const updating = id !== "add";
    const successSnackbarRootClasses = { root: classes.successSnackbar };

    // Form Variables
    const [name, setName] = useState("");
    const [type, setType] = useState("SIMPLE");
    const [successText, setSuccessText] = useState("");
    const [jobTasks, setJobTasks] = useState([]);
    const [selectedJobTask, setSelectedJobTask] = useState(null);
    const [selectedJobTaskIndex, setSelectedJobTaskIndex] = useState(null);
    const [jobCharges, setJobCharges] = useState([]);
    const [jobRooms, setJobRooms] = useState([]);
    const [notes, setNotes] = useState("")
    const [property, setProperty] = useState("");
    const [properties, setProperties] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [enableTravel, setEnableTravel] = useState(false);

    // Query data and mutations
    const [createSimpleJob] = useMutation(CREATE_SIMPLE_JOB, { refetchQueries: [{ query: GET_ALL_JOB }, { query: GET_SIMPLE_JOB_BY_ID }, { query: GET_ADVANCED_JOB_BY_ID }] });
    const [createAdvanceJob] = useMutation(CREATE_ADVANCED_JOB);
    const [updateJob] = useMutation(UPDATE_JOB);
    const [updateAdvancedJob] = useMutation(UPDATE_ADVANCED_JOB);
    const graphClient = useApolloClient();

    let jobQuery = updating
        ? GET_SIMPLE_JOB_BY_ID
        : GET_BASE_DATA;

    const jobResult = useQuery(jobQuery, { variables: { id } });

    // Base data
    const [charges, setCharges] = useState([]);
    const [showChargeModal, setShowChargeModal] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);

    const handleForm = (e, callback) => {
        e.preventDefault();

        const doCallback = () => {
            if (callback) callback();
        };

        let data = {
            name,
            type,
            notes,
            enableTravelTracking: enableTravel,
            tasks: jobTasks?.map(t => ({...t, __typename: undefined})),
        };

        if (type === "SIMPLE") {
            data = {
                ...data,
                charge: jobCharges.map(c => ({chargeId: c._id, quantity: Number(c.quantity)}))[0] ?? null
            };

            if (!updating) {
                createSimpleJob({ variables: { data } })
                    .then(x => {
                        props.history.push(`/admin/job/${x.data.createSimpleJob._id}`);
                        setSuccessText("Job created!");
                        doCallback();
                    });
            } else {
                delete data.type;
                updateJob({ variables: { data, id } })
                    .then(() => {
                        setSuccessText("Job updated!");
                        doCallback();
                    });
            }
        } else if (type === "ADVANCED") {
            data = {
                ...data,
                charge: jobCharges.map(c => ({chargeId: c._id, quantity: Number(c.quantity)}))[0] ?? null,
                rooms: jobRooms.map(r => ({...r, __typename: undefined})),
                propertyType: property
            };

            if (!updating) {
                createAdvanceJob({ variables: { data } })
                    .then(x => {
                        props.history.push(`/admin/job/${x.data.createAdvancedJob._id}`);
                        setJobRooms(x.data.createAdvancedJob.rooms || []);
                        setSuccessText("Job created!");
                        doCallback();
                    });
            } else {
                delete data.type;
                updateAdvancedJob({variables: { data, id }})
                    .then(x => {
                        setJobRooms(x.data.updateAdvancedJob.rooms || []);
                        setSuccessText("Job saved!");
                        doCallback();
                    });
            }
        }
    };

    const handleChargeModal = (e) => {
        setShowChargeModal(false);
        if (!e) return;

        setJobCharges(type === "SIMPLE" ? [e] : [...jobCharges, e]); // todo this line, no idea.
    };

    const handleTaskModal = (e, deleteTask = false, deleteIndex = null) => {
        setShowTaskModal(false);

        if (!e) return;

        if (selectedJobTaskIndex !== null || (deleteTask && deleteIndex)) {
            const tasks = jobTasks;

            if (!deleteTask) tasks[selectedJobTaskIndex] = e;
            else delete tasks[deleteIndex];

            setJobTasks(tasks);
        } else {
            setJobTasks([...jobTasks, e])
        }
    };

    const handleTask = (task = null, index = null, modal = true) => {
        setSelectedJobTask(task);
        setSelectedJobTaskIndex(index);
        setShowTaskModal(modal);
    }

    const handleCreateTask = () => handleTask();

    const handleEditTask = (task, index) => handleTask(task, index);

    const handleDeleteTask = (task, index) => {
        handleTaskModal(task, true, index);
    };

    useEffect(() => {
        const orgSettings = jobResult?.data?.getUserOrganisation;
        const charges = jobResult?.data?.getAllCharge;
        const job = jobResult?.data?.getSimpleJob;

        if (charges) setCharges(charges);

        if (orgSettings) {
            setProperties(orgSettings.propertyTypes);
            setRooms(orgSettings.roomTypes);
        }

        if (!updating) return;

        if (job) {
            setName(job?.name || "");
            setNotes(job?.notes || "");
            setType(job?.type || "");
            setJobTasks(job?.tasks || []);
            setEnableTravel(job?.enableTravelTracking || false);

            if (job.type === "ADVANCED") {
                graphClient
                    .query({ query: GET_ADVANCED_JOB_BY_ID, variables: { id } })
                    .then(aj => {
                        const jobAdvanced = aj.data.getAdvancedJob;
                        setJobRooms(jobAdvanced?.rooms || []);
                        setProperty(jobAdvanced.propertyType);
                    });
            }
        }

        if (charges && job.type === "SIMPLE" && job.chargeLine) { // simple job. todo advanced job.
            const charge = charges.find(x => x._id === job.chargeLine.chargeId);
            if (charge) setJobCharges([{...charge, ...job.chargeLine}]);
        } else if (charges && job.type === "ADVANCED" && job.chargeLines) {
            const charge = charges.find(x => x._id === job.chargeLine.chargeId);
            if (charge) setJobCharges([{...charge, ...job.chargeLine}]);
        }
    }, [jobResult, graphClient, id, updating]);

    return (
        <>
            <form onSubmit={handleForm}>
            <AlternativeHeader section="Add Job" subsection="Jobs" subsectionLink={`/admin/jobs`} showSave />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Basics"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                required
                                                placeholder="Name"
                                                value={name}
                                                startAdornment={<InputAdornment position="start"><Work /></InputAdornment>}
                                                onChange={x => setName(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select
                                                    defaultValue={type || "SIMPLE"}
                                                    value={type}
                                                    disabled={updating}
                                                    IconComponent={KeyboardArrowDown}
                                                    onChange={(e) => setType(e.target.value)}>
                                                    <MenuItem value={'SIMPLE'}>Simple</MenuItem>
                                                    <MenuItem value={'ADVANCED'} disabled>Advanced</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {/* Basic */}
                        {type === "SIMPLE" && (
                            <Card classes={{root: classes.cardRoot}}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Basic Setup"
                                    titleTypographyProps={{
                                        component: Box,
                                        marginBottom: "0 !important",
                                        variant: "h3",
                                    }}
                                ></CardHeader>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <FormGroup>
                                                <OutlinedInput
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Notes"
                                                    multiline
                                                    value={notes}
                                                    startAdornment={<InputAdornment position="start"><Note /></InputAdornment>}
                                                    onChange={x => setNotes(x.target.value)}
                                                />
                                            </FormGroup>

                                            <FormGroup style={{marginBottom: 0}}>
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox
                                                        color="primary"
                                                        onChange={(e) => setEnableTravel(e.target.checked)}
                                                        checked={enableTravel} />}
                                                    label="Enable travel tracking"
                                                    labelPlacement="end"
                                                    classes={{
                                                        root: classes.formControlCheckboxLabelRoot,
                                                        label: classes.formControlCheckboxLabelLabel,
                                                    }}
                                                />

                                                <Box component={"p"} fontSize="0.85rem">This will enable a questionnaire upon startup and present the figures in the authorization screens. Once enabled, it cannot be disabled for the job.</Box>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>)
                        }

                        {/* Advanced */}
                        {type === "ADVANCED" && (
                            <Card classes={{root: classes.cardRoot}}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Advanced Setup"
                                    titleTypographyProps={{
                                        component: Box,
                                        marginBottom: "0 !important",
                                        variant: "h3",
                                    }}
                                ></CardHeader>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <FormGroup>
                                                <OutlinedInput
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Notes"
                                                    multiline
                                                    value={notes}
                                                    startAdornment={<InputAdornment position="start"><Note /></InputAdornment>}
                                                    onChange={x => setNotes(x.target.value)}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <InputLabel>Property</InputLabel>
                                                <Select
                                                    defaultValue={""}
                                                    value={property}
                                                    IconComponent={KeyboardArrowDown}
                                                    onChange={(e) => setProperty(e.target.value)}>
                                                    <MenuItem key={"none"} value={null}>None</MenuItem>
                                                    {properties.map(p => (<MenuItem key={p} value={p}>{p}</MenuItem>))}
                                                </Select>
                                            </FormGroup>

                                            <FormGroup style={{marginBottom: 0}}>
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox
                                                        color="primary"
                                                        onChange={(e) => setEnableTravel(e.target.checked)}
                                                        checked={enableTravel} />}
                                                    label="Enable travel tracking"
                                                    labelPlacement="end"
                                                    classes={{
                                                        root: classes.formControlCheckboxLabelRoot,
                                                        label: classes.formControlCheckboxLabelLabel,
                                                    }}
                                                />

                                                <Box component={"p"} fontSize="0.85rem">This will enable a questionnaire upon startup and present the figures in the authorization screens. Once enabled, it cannot be disabled for the job.</Box>
                                            </FormGroup>

                                            <FormGroup>
                                                <InputLabel>Room Configuration</InputLabel>
                                                <JobRoomConfiguration rooms={jobRooms} types={rooms} updateCallback={(r => setJobRooms(r))} />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>)
                        }

                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Custom Fields"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={6}>

                        {jobCharges.length > 0
                            ? (<JobChargeTable data={jobCharges} header={type === "Charge"} newEvent={() => setShowChargeModal(true)} allowAdd={false} deleteEvent={() => setJobCharges([])} />)
                            : (<Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title={"Charge"}
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container alignItems={"center"} alignContent={"center"}>
                                    <Grid item xs={12} lg={12}>
                                        <Button
                                            variant="contained"
                                            classes={{ root: classes.buttonContainedSuccess }}
                                            fullWidth
                                            onClick={() => setShowChargeModal(true)}
                                        >
                                            New Charge
                                        </Button>
                                        <Box
                                            component="p"
                                            marginBottom="0"
                                            fontWeight="300"
                                            lineHeight="1.5"
                                            fontSize="0.8rem"
                                            marginTop="10px"
                                            paddingTop={'10px'}
                                            textAlign={"center"}
                                        >
                                            Charges allow you to better track work.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>)}

                        {jobTasks.length > 0
                            ? (<JobTaskTable data={jobTasks} newEvent={handleCreateTask} showRoom={type !== "SIMPLE"} editEvent={handleEditTask} deleteEvent={handleDeleteTask} />)
                            : (<Card classes={{ root: classes.cardRoot }}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Tasks"
                                    titleTypographyProps={{
                                        component: Box,
                                        marginBottom: "0 !important",
                                        variant: "h3",
                                    }}
                                ></CardHeader>
                                <CardContent>
                                    <Grid container alignItems={"center"} alignContent={"center"}>
                                        <Grid item xs={12} lg={12}>
                                            <Button
                                                variant="contained"
                                                classes={{
                                                    root: classes.buttonContainedSuccess,
                                                }}
                                                fullWidth
                                                onClick={() => handleCreateTask()}
                                            >
                                                New Task
                                            </Button>
                                            <Box
                                                component="p"
                                                marginBottom="0"
                                                fontWeight="300"
                                                lineHeight="1.5"
                                                fontSize="0.8rem"
                                                marginTop="10px"
                                                paddingTop={'10px'}
                                                textAlign={"center"}
                                            >
                                                Use tasks to easily break down jobs into trackable metrics and information.
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>)}

                        {updating && jobResult?.data?.getClientsByJob?.length > 0
                            ? (<CustomerTable name="Related Customers" data={jobResult?.data?.getClientsByJob} props={props} deleteCallback={() => alert("Use the customers page under Manage > Customers to delete.")} />)
                            : (<p align={'center'}>No related customers, create one <Link to={"/admin/customers"}>here</Link>.</p>)}
                    </Grid>
                </Grid>
            </Container>
            </form>
            {/* Charge Modal */}
            <AddJobCharge showModal={showChargeModal} data={{charges, selectedCharge: null, quantity: null}} closeEvent={handleChargeModal} />
            {/* Task Modal */}
            <AddJobTask showModal={showTaskModal} data={selectedJobTask} closeEvent={handleTaskModal} rooms={jobRooms} />
            {/* Notification */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={successText.length > 0}
                autoHideDuration={2000}
                onClose={() => setSuccessText("")}
                ContentProps={{
                    classes: successSnackbarRootClasses,
                    elevation: 1,
                }}
                action={
                    <Box component={IconButton} padding="0!important" onClick={() => setSuccessText("")}>
                        <Box component="span" color={ "rgba(" + hexToRgb(theme.palette.white.main) + ",.6)" }>
                            ×
                        </Box>
                    </Box>
                }
                message={
                    <>
                        <Box
                            fontSize="1.25rem"
                            display="flex"
                            marginRight="1.25rem"
                            alignItems="center"
                        >
                            <Box component={ThumbUp} width="1.25rem!important" height="1.25rem!important" />
                        </Box>
                        <Box component="span">
                            <Box component="strong" marginRight=".5rem">Success!</Box>
                            {successText}
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default AddJob;
