import React, {useState} from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../../assets/theme/components/button";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

const tableHead = ["forename", "surname", "position", "Number of Lates", "Contracted Hours", "Chargeable Utilization", ""];

export default function StaffTable({data, props, deleteCallback}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };
    const tableContainerRef = React.useRef(null);
    React.useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: tableHead.slice(0, tableHead.length - 1),
            listClass: "list"
        });
    }, []);

    const [selectedRow, setSelectedRow] = useState({});

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title="Manage Staff"
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                ></CardHeader>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "forename" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell
                                        classes={{
                                            root:
                                                classes.tableCellRoot +
                                                " " +
                                                classes.tableCellRootBodyHead,
                                        }}
                                        component="th"
                                        variant="head"
                                        scope="row"
                                    >
                                        <Box alignItems="center" display="flex">
                                            <Box
                                                component={Avatar}
                                                marginRight="1rem"
                                                alt="..."
                                                src={require("assets/img/theme/bootstrap.jpg").default}
                                            />
                                            <Box display="flex" alignItems="flex-start">
                                                <Box fontSize=".875rem" component="span" className="name">
                                                    {prop.forename}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.surname}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                                            <span className="status">{prop.position}</span>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>0</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.hoursPerWeek || 'N/A'} {prop.hoursPerWeek && 'hrs'}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>0%</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }} align="right">
                                        <TableDropdown id={key} actions={[
                                            { text: "Edit", handle: () => props.history.push(`/admin/staff/${prop._id}`) },
                                            { text: "Delete", handle: () => setSelectedRow(prop), style: {color: "red"} }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
            {/* Delete Dialog */}
            <SimpleDialog
                message={`Are you sure you would like to delete the staff member ${selectedRow?.forename} ${selectedRow?.surname}?`}
                title={` Delete ${selectedRow?.forename}?`}
                successCallback={() => deleteCallback(selectedRow?._id)}
                cancelCallback={() => setSelectedRow({})}
                show={selectedRow?._id} />
        </>
    );
}
