import React, {forwardRef, useMemo, useState} from "react";
import List from "list.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableDropdown } from "../../../../components/Dropdowns/TableDropdown";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesDialog from "../../../../assets/theme/components/dialog";
import componentStylesButtons from "../../../../assets/theme/components/button";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import {LinearProgress} from "@material-ui/core";
import {Link, useHistory} from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function ClientTable({data, deleteCallback, week, showWeekChange, onWeekChange, title}) {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesDialog(),
    };

    let history = useHistory();

    const tableHead = useMemo(() => ["Customer Name", "Jobs", "Hours", "Tasks", "Missed", "Revenue", "Profit", "Margin", ""], []);
    const tableContainerRef = React.useRef(null);

    React.useEffect(() => {
        new List(tableContainerRef.current, {
            valueNames: tableHead.slice(0, tableHead.length - 1),
            listClass: "list"
        });
    }, [tableHead]);

    const [selectedRow, setSelectedRow] = useState({});

    // If there is no data, return an empty list.
    let tableData = data ? data : [];

    const CustomInput = forwardRef(({ onClick }, ref) => (
        <Button onClick={onClick} ref={ref}>
            Change Date
        </Button>
    ));

    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className={classes.cardHeader}
                    title={title}
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                    action={
                        showWeekChange &&
                        <DatePicker
                            onChange={(date) => onWeekChange(date)}
                            openToDate={moment(week).startOf("week").add(-1, "week").add(1, "day").toDate() || new Date()}
                            withPortal
                            locale="en-GB"
                            filterDate={(date) => date.getDay() === 1 && moment(date).isBefore(moment().add(1, "day"))}
                            customInput={<CustomInput />}
                        />
                    }
                ></CardHeader>
                <TableContainer ref={tableContainerRef}>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        {...{
                                            "data-sort":
                                                prop !== ""
                                                    ? (prop === "Project" ? "forename" : prop).toLowerCase()
                                                    : undefined,
                                        }}
                                        scope="col"
                                        classes={{
                                            root: classes.tableCellRoot + " sort " + classes.tableCellRootHead
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="list">
                            {tableData.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{
                                        root:
                                            classes.tableCellRoot +
                                            " " +
                                            classes.tableCellRootBodyHead,
                                    }}
                                               component="th"
                                               variant="head"
                                               scope="row">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box fontSize=".875rem" component="span">
                                                <Link to={`/admin/authorization/${prop.clientId}/${week}`}>{prop.clientName}</Link>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.groupedSchedules?.filter(x => x.totalHours > 0)?.length || 0}/{prop.groupedSchedules?.length || 0}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box display="flex" alignItems="center">
                                            <Box component="span" marginRight=".5rem">
                                                {Number(prop.totalHours).toFixed(1)}/{Number(prop.estimatedHours).toFixed(1)}
                                            </Box>
                                            <Box width="100%">
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={prop.totalHours / prop.estimatedHours * 100}
                                                    classes={{
                                                        root: classes.linearProgressRoot,
                                                        bar: classes[prop.totalHours > (prop.estimatedHours / 2) ? "bgSuccess" : "bgError"],
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box display="flex" alignItems="center">
                                            <Box component="span" marginRight=".5rem">
                                                {prop.totalTasks} / {prop.estimatedTasks}
                                            </Box>
                                            {/*<Box width="100%">*/}
                                            {/*    <LinearProgress*/}
                                            {/*        variant="determinate"*/}
                                            {/*        value={prop.totalTasks / prop.estimatedTasks * 100}*/}
                                            {/*        classes={{*/}
                                            {/*            root: classes.linearProgressRoot,*/}
                                            {/*            bar: classes[prop.totalTasks > (prop.estimatedTasks / 2) ? "bgSuccess" : "bgError"],*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</Box>*/}
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                                            <Box
                                                marginRight="10px"
                                                component="i"
                                                width=".375rem"
                                                height=".375rem"
                                                borderRadius="50%"
                                                display="inline-block"
                                                className={
                                                    classes.verticalAlignMiddle +
                                                    " " +
                                                    classes[prop.actualLates > 1 ? "bgError" : "bgSuccess"]
                                                }
                                            ></Box>
                                            {prop.actualLates || 0}
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>£{Number(prop.totalCharge).toFixed(2)}/£{Number(prop.estimatedCharge).toFixed(2)}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>£{Number(prop.totalCharge - prop.totalCost).toFixed(2)}/£{Number(prop.estimatedCharge - prop.estimatedCost).toFixed(2)}</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>{isNaN(Number((prop.totalCost / prop.totalCharge) * 100).toFixed(2)) ? 0 : Number((prop.totalCost / prop.totalCharge) * 100).toFixed(2)}%/{Number(prop.estimatedCost / prop.estimatedCharge * 100).toFixed(2)}%</TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }} align="right">
                                        <TableDropdown id={key} actions={[
                                            { text: "View Details", handle: () => history.push(`/admin/authorization/${prop.clientId}/${week}`)},
                                            { text: "Approve", handle: () => setSelectedRow(prop), style: {color: "green"}},
                                            { text: "Adjust", handle: () => setSelectedRow(prop), style: {color: "red"} }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            </Card>
            {/* Delete Dialog */}
            <SimpleDialog
                message={`Are you sure you would like to delete the staff member ${selectedRow?.forename} ${selectedRow?.surname}?`}
                title={` Delete ${selectedRow?.forename}?`}
                successCallback={() => deleteCallback(selectedRow?._id)}
                cancelCallback={() => setSelectedRow({})}
                show={selectedRow?._id} />
        </>
    );
}
