import {useTheme} from "@material-ui/core/styles";
import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const TableDropdown = ({ id, actions }) => { // todo, create shared code
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Box
                aria-controls={"simple-menu-" + id}
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                component={Button}
                width="2rem!important"
                height="2rem!important"
                minWidth="2rem!important"
                minHeight="2rem!important"
            >
                <Box
                    component={MoreVert}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    position="relative"
                    top="2px"
                    color={theme.palette.gray[500]}
                />
            </Box>
            <Menu
                id={"simple-menu-" + id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {actions.map(x => {
                    return (<MenuItem onClick={x.handle} style={x.style} key={x.handle}>{x.text}</MenuItem>)
                })}
            </Menu>
        </>
    );
};
