import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AdminNavbarAlternative from "components/Navbars/AdminNavbarAlternative.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import componentStyles from "assets/theme/layouts/admin.js";
import House from "@material-ui/icons/House";
import Organisations from "../views/super-admin/Organisations/Organisations";
import Settings from "../views/admin/Settings/Settings";

const useStyles = makeStyles(componentStyles);

const routes = [
  {
    collapse: true,
    name: "Organisations",
    icon: House,
    iconColor: "Warning",
    state: "examplesCollapse",
    views: [
      {
        path: "/organisations",
        name: "Organisations",
        miniName: "S",
        component: Organisations,
        layout: "/super-admin",
      },
      {
        path: "/organisations/add",
        name: "Add Organisation",
        component: Settings,
        layout: "/super-admin",
        hide: true
      }
    ]
  }
];

const SuperAdmin = (props) => {
  const classes = useStyles();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/super-admin") {
        return (<Route path={prop.layout + prop.path} component={withAuthenticationRequired(prop.component)} key={key} exact={true} />);
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Box display="flex">
        <Sidebar
          routes={routes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/index",
            imgSrc: require("../assets/img/brand/roma_logo_2.png").default,
            imgAlt: "...",
          }}
        />
        <Box position="relative" flex="1" className={classes.mainContent}>
          <AdminNavbarAlternative openSidebarResponsive={() => setSidebarOpenResponsive(true)} props={props} />
          <Switch>
            {getRoutes(routes)}
            {/*<Redirect from="*" to="/super-admin/organisations" />*/}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default SuperAdmin;
