import {Card, CardContent, CardHeader, ListItemAvatar, ListItemText} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {Doughnut} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import themeColors from "../../../../assets/theme/colors";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import componentStyles from "../../../../assets/theme/views/admin/sortable";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import {KeyboardArrowUp} from "@material-ui/icons";

const chartOptions = {
    cutoutPercentage:0,
    legend: {
        display: false,
        position: "top"
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    responsive: true,
    showLines: false,
    spanGaps: false,
    aspectRatio: 1,
};

const useStyles = makeStyles(componentStyles);

export const KpiCard = ({total, projected, suffix, title, toFixed = 2, expandable = false}) => {
    const [deliverables, setDeliverables] = useState({});
    const [expanded, setExpanded] = React.useState(false);

    const classes = useStyles();
    const theme = useTheme();

    const convertTime = (time) =>
    {
        time = Number(time).toFixed(toFixed);

        // If we are not dealing with a time, just return.
        if (suffix !== "hrs") return `${time} ${suffix}`;

        time = time * 60;

        // Calculate the number of hours by dividing num by 60 and rounding down
        let hours = Math.floor(time / 60);

        // Calculate the remaining minutes by taking the remainder when dividing num by 60
        let minutes = time % 60;

        // Return the result as a string in the format "hours:minutes"
        return `${hours} hrs ${minutes.toFixed(0)} mins`;
    };

    useEffect(() => {
        setDeliverables({
            labels: ["Delivered", "Projected"],
            datasets: [
                {
                    data: [
                        Number(total).toFixed(toFixed),
                        Number((projected - total)).toFixed(toFixed)
                    ],
                    backgroundColor: [
                        themeColors.success.main,
                        themeColors.error.main
                    ],
                    label: suffix,
                },
            ]
        });
        // eslint-disable-next-line
    }, [total, projected, suffix]);

    return <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
            className={classes.cardHeader}
            title={title}
            titleTypographyProps={{
                component: Box,
                marginBottom: "0 !important",
                variant: "h3",
            }}
        />
        <CardContent>
            <div style={{flex: 1, flexDirection: "row", display: "flex"}}>
                <div style={{flexGrow: 1}}>
                    <Box
                        component="p"
                        color={theme.palette.black.light}
                        fontWeight="300"
                        lineHeight="1.7"
                        fontSize="1rem"
                        marginBottom="0rem"
                        marginTop="0rem"
                    >
                        <strong>{convertTime(total)}</strong> out of <strong>{convertTime(projected)} projected</strong>.
                    </Box>
                </div>
                <div style={{width: 90}}>
                    <Doughnut data={deliverables} options={chartOptions} />
                </div>
            </div>
            {expandable && <div>
                <Box style={{cursor: "pointer"}} onClick={() => setExpanded(x => !x)} color={"#5e72e4"} component={"p"} fontSize={"0.85rem"} marginTop={"5px"} marginBottom={"0"}>Expand {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</Box>
                {expanded && <List disablePadding>
                    <ListItem classes={{root: classes.listItemRoot}}>
                        <ListItemAvatar>
                            <Avatar variant={"rounded"}>
                                <WorkIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${convertTime(total - projected)}`} secondary={`Total Variance`} classes={{primary: classes.listItemTitlePrimaryRoot, secondary: classes.listItemTitleSecondaryRoot}} />
                    </ListItem>
                    <ListItem classes={{root: classes.listItemRoot}}>
                        <ListItemAvatar>
                            <Avatar variant={"rounded"}>
                                <WorkIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${convertTime(projected)}`} secondary={`Total ${title} Estimated`} classes={{primary: classes.listItemTitlePrimaryRoot, secondary: classes.listItemTitleSecondaryRoot}} />
                    </ListItem>
                    <ListItem classes={{root: classes.listItemRoot}}>
                        <ListItemAvatar>
                            <Avatar variant={"rounded"}>
                                <BeachAccessIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${convertTime(total)}`} secondary={`Total ${title}`} classes={{primary: classes.listItemTitlePrimaryRoot, secondary: classes.listItemTitleSecondaryRoot}} />
                    </ListItem>
                </List>}
            </div>}
        </CardContent>
    </Card>
}