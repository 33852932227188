import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import JobTable from "./Components/JobTable";
import { GET_ALL_JOB } from "./Scheme"

const useStyles = makeStyles(componentStyles);

const Job = (props) => {
    const classes = useStyles();
    const { loading, data } = useQuery(GET_ALL_JOB);

    return (
        <>
            <AlternativeHeader section="Jobs" showNew showNewAction={() => props.history.push("/admin/job/add")} subsectionLink={'/admin/jobs/'} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading && data.getAllJob?.length > 0  && <JobTable data={data.getAllJob} props={props} />}
            </Container>
        </>
    );
};

export default Job;
