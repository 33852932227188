import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import Grid from "@material-ui/core/Grid";
import {useMutation, useQuery} from "@apollo/client";
import {
    CREATE_CUSTOMER_QUOTE,
    GET_BASE_DATA,
    GET_CUSTOMER_QUOTE_BASE_DATA,
    UPDATE_CUSTOMER_QUOTE
} from "./Scheme";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "../../../assets/theme/hex-to-rgb";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Snackbar from "@material-ui/core/Snackbar";
import componentStylesSnackbar from "../../../assets/theme/components/snackbar";
import componentStylesButtons from "../../../assets/theme/components/button";
import componentStylesForms from "assets/theme/components/forms.js";
import EstimationTable from "./Components/EstimationTable";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CardInfoBg from "../../../components/Cards/Alternative/CardInfoBg";
import InfoBox from "../../../components/Shared/InfoBox";

const useStylesForms = makeStyles(componentStylesForms);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const AddEstimation = (props) => {
    const classes = {
        ...useStyles(),
        ...useStylesSnackbar(),
        ...useStylesButtons(),
        ...useStylesForms()
    };

    const theme = useTheme();

    const id = props.match.params.customerId;
    const quoteId = props.match.params.id;
    const updating = props.match.path.indexOf("edit") > -1;
    const successSnackbarRootClasses = {root: classes.successSnackbar};

    // Other State Variables

    // Form Variables

    const [successText, setSuccessText] = useState("");
    const [data, setData] = useState([{}]);
    const [sites, setSites] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [charges, setCharges] = useState([]);
    const [name, setName] = useState("My First Quote");
    const [showEditName, setShowEditName] = useState(false);

    const baseData = useQuery(updating ? GET_CUSTOMER_QUOTE_BASE_DATA : GET_BASE_DATA, { variables: updating ? { id: quoteId, clientId: id } : { id } });

    const [createCustomerQuote] = useMutation(CREATE_CUSTOMER_QUOTE);
    const [updateCustomerQuote] = useMutation(UPDATE_CUSTOMER_QUOTE);

    const handleForm = (e) => {
        e.preventDefault();

        const lines = data.map(row => ({
            siteId: row["siteId"],
            jobId: row["jobId"],
            hours: Number(row["hours"]),
            quantity: Number(row["quantity"])
        }));

        if (updating) {
            updateCustomerQuote({ variables: { data: { name, lines }, id: quoteId, clientId: id } })
                .then(() => setSuccessText("Quote Successfully Updated!"))
                .catch(x => alert(JSON.stringify(x)));
        } else {
            createCustomerQuote({variables: {data: {name, lines}, clientId: id}})
                .then(x => {
                    props.history.push(`/admin/estimations/edit/${id}/${x.data.createQuote._id}`);
                    setSuccessText("Quote Successfully Created!");
                })
                .catch((x) => {
                    alert(JSON.stringify(x));
                });
        }
    };

    const handleChange = (key, name, value) => {
        let copy = data;
        const row = copy[key];
        let job;
        let charge;

        row[name] = value;

        if (row["jobId"]) {
            job = jobs.find(x => x._id === row["jobId"]);
            row["job"] = job;
            if (!row["hours"]) row["hours"] = job.chargeLine.quantity;
            if (!row["quantity"]) row["quantity"] = 1;

            charge = charges.find(x => x._id === job.chargeLine.chargeId);
            row["chargeLine"] = charge;
        }

        if (job && row["hours"]) {
            row["cost"] = Number((charge.cost * row["hours"]) * (row["quantity"] || 1));
            row["charge"] = Number((charge.charge * row["hours"]) * (row["quantity"] || 1));
            row["profit"] = Number(row["cost"] - row["charge"]);
            row["profitPercentage"] = Number((row["profit"] / row["cost"] * 100)).toFixed() || 0;
        }

        copy[key] = row;
        setData([...copy]);
    };


    useEffect(() => {
        if (baseData.data) {
            console.log(baseData.data);

            if (baseData?.data?.getClient) {
                setSites(baseData.data.getClient.sites || []);

                setJobs(
                    baseData.data.getClient.jobs.length > 0
                        ? baseData.data.getAllJob.filter(x => baseData.data.getClient.jobs.includes(x._id))
                        : baseData.data.getAllJob);
            }

            setCharges(baseData.data.getAllCharge);

            if (baseData.data.getQuote) {
                setName(baseData.data.getQuote.name);
                setData(baseData.data.getQuote.lines.map(line => ({
                    ...line, // todo get charge and cost etc.
                })));
            }
        }
    }, [baseData]);

    return (
        <>
            <form onSubmit={handleForm}>
                <AlternativeHeader section="Add Estimation" subsection="Estimations"
                                   subsectionLink={`/admin/estimations`} showSave />
                {/* Page content */}
                <Container
                    maxWidth={false}
                    component={Box}
                    marginTop="-4.5rem"
                    classes={{root: classes.containerRoot}}
                >
                    <Grid container>
                        <Grid item xs={6} md={2} xl={2}>
                            <CardInfoBg
                                color={"default"}
                                subtitle={"Total Jobs"}
                                title={data.map(x => Number(x.quantity || 0)).reduce((a, b) => a + b, 0).toString() || "0"}
                                progressValue={100}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} xl={2}>
                            <CardInfoBg
                                color={"default"}
                                subtitle={"Total Hours"}
                                title={data.map(x => Number(x.hours || 0) * Number(x.quantity || 0)).reduce((a, b) => a + b, 0).toString() || "0"}
                                progressValue={100}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} xl={2}>
                            <CardInfoBg
                                color={"default"}
                                subtitle={"Total Travel"}
                                title={`${data.map(x => Number(x.travel || 0)).reduce((a, b) => a + b, 0)}`}
                                progressValue={data[0].profitPercentage || 0}
                                progressColor={"success"}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} xl={2}>
                            <CardInfoBg
                                color={"default"}
                                subtitle={"Total Revenue"}
                                title={`£${data.map(x => Number(x.charge || 0)).reduce((a, b) => a + b, 0)}`}
                                progressValue={100}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} xl={2}>
                            <CardInfoBg
                                color={"default"}
                                subtitle={"Total Profit"}
                                title={`£${data.map(x => Number(x.profit || 0)).reduce((a, b) => a + b, 0)} (${data[0].profitPercentage || 0}%)`}
                                progressValue={data[0].profitPercentage || 0}
                                progressColor={"success"}
                            />
                        </Grid>
                    </Grid>
                    {data && jobs.length > 0 &&
                        <EstimationTable
                            title={name}
                            sites={sites}
                            jobs={jobs}
                            data={data}
                            deleteHandler={x => { let a = data; a.splice(x, 1); console.log(a); setData([...a]); }}
                            editTitleHandler={() => setShowEditName(true)}
                            newHandler={() => setData([...data, {}])}
                            updateRowHandler={handleChange} />}
                </Container>
            </form>
            {/* Edit Name */}
            {showEditName
                && <InfoBox
                    hideIcon
                    message={<OutlinedInput fullWidth className="form-control-lg" type="text" value={name} onChange={e => setName(e.target.value)} />}
                    title={"Rename"}
                    buttonText={"Save"}
                    successCallback={() => {setShowEditName(false)}} />}
            {/* Notification */}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={successText?.length > 0 || false}
                autoHideDuration={2000}
                onClose={() => setSuccessText("")}
                ContentProps={{
                    classes: successSnackbarRootClasses,
                    elevation: 1,
                }}
                action={
                    <Box component={IconButton} padding="0!important" onClick={() => setSuccessText("")}>
                        <Box component="span" color={"rgba(" + hexToRgb(theme.palette.white.main) + ",.6)"}>
                            ×
                        </Box>
                    </Box>
                }
                message={
                    <>
                        <Box
                            fontSize="1.25rem"
                            display="flex"
                            marginRight="1.25rem"
                            alignItems="center"
                        >
                            <Box component={ThumbUp} width="1.25rem!important" height="1.25rem!important"/>
                        </Box>
                        <Box component="span">
                            <Box component="strong" marginRight=".5rem">Success!</Box>
                            {successText}
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default AddEstimation;
