import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import {useMutation, useQuery} from '@apollo/client';
import {DELETE_CUSTOMER, GET_ALL_CUSTOMERS} from "./Scheme";
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import componentStylesButtons from "../../../../assets/theme/components/button";
import GoogleMap from "google-map-react";
import CustomerTable from "./Components/CustomerTable";
const useStylesButtons = makeStyles(componentStylesButtons);

const useStyles = makeStyles(componentStyles);

const Customers = (props) => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons()
    };
    const { loading, data } = useQuery(GET_ALL_CUSTOMERS);

    const [deleteCustomer] = useMutation(DELETE_CUSTOMER, { refetchQueries: [{ query: GET_ALL_CUSTOMERS }] });

    const deleteEntity = (id) => {
        deleteCustomer({ variables: { id }})
            .then(() => console.log("Success"))
            .catch(x => console.log(x));
    };

    return (
        <>
            <AlternativeHeader section="Customers" showNew showNewAction={() => props.history.push("/admin/customer/add")} />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                {!loading && false && data.getAllClient.length > 0 && <CustomerTable data={data.getAllClient} props={props} deleteCallback={i => deleteEntity(i)} />}
                <Grid container>
                    {!loading && data.getAllClient.map(customer => {
                        return (
                            <Grid xs={6} md={4} item>
                                <Card className={classes.cardRoot}>
                                    <Box style={{height: "200px"}}>
                                        <GoogleMap
                                            defaultCenter={{lat: customer.address.latitude || 0, lng: customer.address.longitude || 0}}
                                            bootstrapURLKeys={{key: "AIzaSyCawaNc5ExsVd9JRJ5iEbz70lNy0bpwKA4"}}
                                            defaultZoom={12}
                                            onClick={() => props.history.push(`/admin/customer/${customer._id}`)}
                                            defaultOptions={({
                                                panControl: false,
                                                mapTypeControl: false,
                                                scrollwheel: false,
                                                fullscreenControl: false,
                                                zoomControl: false
                                            })}

                                            options={({
                                                panControl: false,
                                                mapTypeControl: false,
                                                scrollwheel: false,
                                                fullscreenControl: false,
                                                zoomControl: false
                                            })}
                                        />
                                    </Box>
                                    <CardContent>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            className={classes.cardImgOverlay}
                                        >
                                            <div style={{width:"100%"}}>
                                                <Typography
                                                    variant="h2"
                                                    className={classes.typographyH2}
                                                    style={{marginBottom:"0.25rem", textAlign: "center"}}
                                                    onClick={() => props.history.push(`/admin/customer/${customer._id}`)}>
                                                    {customer.name}
                                                </Typography>
                                                <Box
                                                    component="p"
                                                    marginBottom="0"
                                                    fontWeight="600"
                                                    lineHeight="1.7"
                                                    fontSize=".875rem"
                                                    marginTop="0"
                                                    textAlign="center"
                                                >
                                                    {customer.siteCount || 0} Sites | {customer.quotes ? customer.quotes.length : 0} Estimates
                                                </Box>
                                                <Box>
                                                    <div className="col">
                                                        <div className="card-profile-stats d-flex justify-content-center">
                                                            <div>
                                                                <span className="heading">0/10</span>
                                                                <span className="description">Jobs</span>
                                                            </div>
                                                            <div>
                                                                <span className="heading">0/60</span>
                                                                <span className="description">Hours</span>
                                                            </div>
                                                            <div>
                                                                <span className="heading">19/27</span>
                                                                <span className="description">Margin (%)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Box>
                                                <Box textAlign="center">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color={'default'}
                                                        className={classes.buttonContainedDefault}
                                                        onClick={() => props.history.push(`/admin/customer/${customer._id}`)}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        className={classes.buttonContainedError}
                                                        style={{marginLeft:10}}
                                                        onClick={() => deleteEntity(customer._id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </div>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </>
    );
};

export default Customers;
