import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default function InfoBox({message, successCallback, type, title, buttonText, hideIcon}) {
    if (!type) type = "info";
    if (!title) title = "Info"

    return (
        <ReactBSAlert
            info={!hideIcon}
            show
            style={{ display: "block", marginTop: "-100px" }}
            title={title}
            onConfirm={() => successCallback(null)}
            confirmBtnBsStyle={type}
            confirmBtnText={buttonText || "Yes"}
            btnSize=""
            confirmBtnStyle={{
                marginRight: undefined,
                borderColor: undefined,
                boxShadow: undefined,
            }}
        >
            {message}
        </ReactBSAlert>
    );
}
