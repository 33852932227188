import { gql } from "@apollo/client";

export const CREATE_STAFF = gql`
    mutation($data: CreateStaffInput!) {
        createStaff(createStaff: $data) {
            _id
        }
    }
`;

export const UPDATE_STAFF = gql`
    mutation($id: ObjectId!, $data: UpdateStaffInput!) {
        updateStaff(id: $id, updateStaff: $data) {
            _id
            email
            forename
            surname
            position
        }
    }
`;

export const DELETE_STAFF = gql`
    mutation ($id: ObjectId!) {
        deleteStaff(id: $id)
    }
`;

export const GET_STAFF_BASE_DATA = gql`
    query {
        getUserOrganisation {
            fields
            staffSkillTypes
        }
    }
`;

export const GET_STAFF_BY_ID = gql`
    query ($id: ObjectId!) {
        getUserOrganisation {
            fields
            staffSkillTypes
        }
        
        getStaff(id: $id) {
            email
            position
            forename
            surname
            _id
            type
            staffSkills
            hoursPerWeek
            salaryPerHour

            address {
                name
                buildingNameOrNumber
                streetName
                postalTown
                county
                postalCode
                countryName
                latitude
                longitude
            }

            customFields {
                property
                value
                type
            }
            
            reference
            phone
        }
    }
`;

export const GET_LAST_SEEN_UPDATE_STAFF = gql`
    query {
        getCurrentStaffMember {
            lastUpdateView
        }
    }
`;

export const SET_LAST_UPDATE_SEEN_STAFF = gql`
    mutation {
        hasSeenAppUpdate
    }
`

export const GET_ALL_STAFF = gql`
    query {
        getAllStaff {
            _id,
            forename
            surname
            position
            email
            hoursPerWeek
        }
    }
`;
