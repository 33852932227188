import React, {useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Person from "@material-ui/icons/Person";
import Email from "@material-ui/icons/Email";
import Visibility from "@material-ui/icons/Visibility";
import Call from "@material-ui/icons/Call";
import {Work} from "@material-ui/icons";
import {Divider, InputLabel} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import {CREATE_STAFF, GET_ALL_STAFF, GET_STAFF_BASE_DATA, GET_STAFF_BY_ID, UPDATE_STAFF} from "./Scheme";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "../../../../assets/theme/hex-to-rgb";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Snackbar from "@material-ui/core/Snackbar";
import componentStylesSnackbar from "../../../../assets/theme/components/snackbar";
import AddressForm from "../../../../components/AddressForm";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import CustomFields, {CustomFieldsAggregator} from "../../../../components/Shared/CustomFields";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStyles = makeStyles(componentStyles);

const AddStaff = (props) => {
    const classes = {
        ...useStyles(),
        ...useStylesSnackbar()
    };
    const theme = useTheme();

    const id = props.match.params.staffId;
    const updating = id !== "add";
    const successSnackbarRootClasses = { root: classes.successSnackbar };

    // Form Variables
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState("");
    const [successText, setSuccessText] = useState("");
    const [address, setAddress] = useState({});
    const [reference, setReference] = useState("");
    const [phone, setPhone] = useState("");
    const [type, setType] = useState("");
    const [fieldValues, setFieldValues] = useState([]);
    const [staffSkillTypes, setStaffSkillTypes] = useState([]);
    const [staffSkills, setStaffSkills] = useState([]);
    const [salaryPerHour, setSalaryPerHour] = useState(0);
    const [hoursPerWeek, setHoursPerWeek] = useState(0);

    const [createStaff] = useMutation(CREATE_STAFF, { refetchQueries: [{ query: GET_ALL_STAFF }, { query: GET_STAFF_BY_ID }] });
    const [updateStaff] = useMutation(UPDATE_STAFF);
    const staffResult = useQuery(updating ? GET_STAFF_BY_ID : GET_STAFF_BASE_DATA, { variables: { id } });

    const handleForm = (e) => {
        e.preventDefault();

        const data = {
            name,
            position,
            email,
            address: { ...address, countryName: "UK" },
            phone,
            reference,
            type,
            staffSkills,
            customFields: fieldValues.map(x => ({...x, __typename: undefined})),
            salaryPerHour: Number(salaryPerHour),
            hoursPerWeek
        };

        if (data.address.__typename) delete data.address.__typename;

        if (!updating) {
            createStaff({variables: {data}})
                .then(x => {
                    props.history.push(`/admin/staff/${x.data.createStaff._id}`);
                    setSuccessText("Staff member created!");
                });
        } else {
            updateStaff({ variables: {data, id } })
                .then(() => setSuccessText("Staff member saved!"))
                .catch(() => {/* todo */})
        }
    };

    useEffect(() => {
        const result = staffResult.data?.getStaff;
        const baseData = staffResult.data?.getUserOrganisation;

        if (updating && result) {
            setPosition(result?.position || "");
            setName(`${result.forename} ${result.surname}`);
            setEmail(result?.email || "");
            setAddress(result?.address ?? {});
            setReference(result?.reference || "");
            setPhone(result?.phone || "");
            setType(result?.type || "Basic");
            setStaffSkills(result.staffSkills || []);
            setSalaryPerHour(result.salaryPerHour || 0);
            setHoursPerWeek(result.hoursPerWeek || 0);

            setFieldValues(CustomFieldsAggregator(result?.customFields, baseData?.fields));
            setStaffSkillTypes(baseData?.staffSkillTypes);
        } else if (baseData) {
            setFieldValues(CustomFieldsAggregator(null, baseData?.fields));
            setStaffSkillTypes(baseData?.staffSkillTypes);
        }

    }, [staffResult, updating]);

    return (
        <>
            <form onSubmit={handleForm}>
            <AlternativeHeader section="Add Staff Member" subsection="Staff" subsectionLink={`/admin/staff`} showSave />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid
                    container
                    component={Box}
                    marginBottom="39px"
                    justifyContent="center"
                >
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Basics"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel htmlFor="name" required>Full Name</InputLabel>
                                            <OutlinedInput
                                                required
                                                fullWidth
                                                type="text"
                                                id="text"
                                                placeholder="e.g., John Smith"
                                                value={name}
                                                startAdornment={<InputAdornment position="start"><Person /></InputAdornment>}
                                                onChange={x => setName(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel>Position</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., Team Lead"
                                                value={position}
                                                startAdornment={<InputAdornment position="start"><Work /></InputAdornment>}
                                                onChange={x => setPosition(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel htmlFor="email" required>Email</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="email"
                                                id="email"
                                                placeholder="E.g., john@smith.com"
                                                value={email}
                                                startAdornment={<InputAdornment position="start"><Email /></InputAdornment>}
                                                onChange={x => setEmail(x.target.value)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Phone Number</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            type="text"
                                            placeholder="e.g., 12345678901"
                                            value={phone}
                                            onChange={x => setPhone(x.target.value)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Call />
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Divider classes={{ root: classes.divider }} style={{marginBottom: '25px'}} />
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel>Reference</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., XY32"
                                                value={reference}
                                                onChange={x => setReference(x.target.value)}
                                                startAdornment={<InputAdornment position="start"><Visibility /></InputAdornment>}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel htmlFor="type" required>User Type</InputLabel>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select
                                                    defaultValue={''}
                                                    value={type}
                                                    id="type"
                                                    required
                                                    IconComponent={KeyboardArrowDown}
                                                    onChange={(e) => setType(e.target.value)}>
                                                    <MenuItem value={''}>Select...</MenuItem>
                                                    <MenuItem value={'Basic'}>Basic</MenuItem>
                                                    <MenuItem value={'Admin'}>Admin</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth style={{maxWidth: "100%", marginBottom: "1.5rem"}} variant="outlined">
                                            <Autocomplete
                                                options={staffSkillTypes}
                                                multiple
                                                value={staffSkills}
                                                popupIcon={<Box component={KeyboardArrowDown} width="1.25rem!important" height="1.25rem!important" />}
                                                renderInput={(params) => (<TextField {...params} label="Skills" variant="outlined" />)}
                                                onChange={(a, value) => { setStaffSkills(value) }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Divider classes={{ root: classes.divider }} style={{marginBottom: '25px'}} />

                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel>Salary Per Hour</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., 11"
                                                value={salaryPerHour}
                                                onChange={x => setSalaryPerHour(x.target.value)}
                                                startAdornment={<InputAdornment position="start"><Visibility /></InputAdornment>}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormGroup>
                                            <InputLabel>Hours Per Week</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="text"
                                                placeholder="e.g., 11"
                                                value={hoursPerWeek}
                                                onChange={x => setHoursPerWeek(Number(x.target.value))}
                                                startAdornment={<InputAdornment position="start"><Visibility /></InputAdornment>}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Custom Fields"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <CustomFields
                                            initialValue={fieldValues}
                                            valueCallback={values => setFieldValues(values)}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Address"
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: "0 !important",
                                    variant: "h3",
                                }}
                            ></CardHeader>
                            <CardContent>
                                <AddressForm
                                    address={address}
                                    setCallback={(v) => {
                                        setAddress(v)
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            </form>
            {/* Notification */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={successText?.length > 0 || false}
                autoHideDuration={2000}
                onClose={() => setSuccessText("")}
                ContentProps={{
                    classes: successSnackbarRootClasses,
                    elevation: 1,
                }}
                action={
                    <Box component={IconButton} padding="0!important" onClick={() => setSuccessText("")}>
                        <Box component="span" color={ "rgba(" + hexToRgb(theme.palette.white.main) + ",.6)" }>
                            ×
                        </Box>
                    </Box>
                }
                message={
                    <>
                        <Box
                            fontSize="1.25rem"
                            display="flex"
                            marginRight="1.25rem"
                            alignItems="center"
                        >
                            <Box component={ThumbUp} width="1.25rem!important" height="1.25rem!important" />
                        </Box>
                        <Box component="span">
                            <Box component="strong" marginRight=".5rem">Success!</Box>
                            {successText}
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default AddStaff;
