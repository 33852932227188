import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import componentStyles from "assets/theme/views/admin/sortable.js";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader";
import { useQuery } from '@apollo/client';
import {GET_CUSTOMER_SITE_BY_ID_WITH_AGG} from "./Scheme"
import Grid from '@material-ui/core/Grid';
import StaffTable from "./Components/StaffTable";
import JobTable from "./Components/JobTable";
import {KpiCard} from "./Components/KpiCard";
import SchedulesTable from "./Components/SchedulesTable";

const useStyles = makeStyles(componentStyles);

const AuthorizationSite = (props) => {
    const classes = useStyles();

    const id = props.match.params.clientId;
    const siteId = props.match.params.siteId;
    const date = props.match.params.date;

    const { loading, data } = useQuery(GET_CUSTOMER_SITE_BY_ID_WITH_AGG, { variables: { week: date, clientId: id } });

    // The State
    const [aggregation, setAggregation] = useState({});
    const [client, setClient] = useState({});

    console.log(client);

    useEffect(() => {
        if (loading) return;

        if (data?.getClient) {
            setClient(data.getClient);
        }

        if (data?.getAggregationsByClient) {
            console.log("Here we gho", data.getAggregationsByClient.weeks[0].clients[0]);
            const agg = data.getAggregationsByClient.weeks[0].clients[0].sites.filter(x => x.siteId === siteId)[0];
            console.log(agg);
            console.log(agg);
            setAggregation(agg);
        }
    }, [data, loading, date, siteId]);

    return (
        <>
            <AlternativeHeader
                section={`${client.name}/${aggregation.siteName} Details`}
                showBack
                />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-4.5rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Jobs"}
                            toFixed={0}
                            projected={aggregation.groupedSchedules?.length || 0}
                            total={aggregation.groupedSchedules?.filter(x => x.totalHours > 0).length || 0}
                            suffix={"jobs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Job Hours"}
                            projected={aggregation.estimatedHours}
                            total={aggregation.totalHours}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Tasks"}
                            toFixed={0}
                            projected={aggregation.estimatedTasks}
                            total={aggregation.totalTasks}
                            suffix={"tasks"}
                            expandable
                        />
                    </Grid>

                    <Grid item sm={12} md={3}>
                        <KpiCard
                            title={"Travel"}
                            toFixed={2}
                            projected={(aggregation?.estimatedTravel || 0) / 60}
                            total={(aggregation?.totalTravel || 0) / 60}
                            suffix={"hrs"}
                            expandable
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={12}>
                        {aggregation.jobs && <SchedulesTable data={aggregation.groupedSchedules} clientId={id} date={date} />}
                    </Grid>

                    <Grid item sm={12}>
                        {aggregation.jobs && <JobTable data={aggregation.jobs} clientId={id} date={date} />}
                    </Grid>

                    <Grid item sm={12}>
                        {aggregation?.staff && aggregation.staff.length > 0 && <StaffTable title={"Staff Breakdown"} data={aggregation.staff} showSalary />}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default AuthorizationSite;
